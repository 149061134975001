<template>
  <div>
    <Panel>
      <template #header>
        <div class="panel-header">General</div>
        <div class="horizontal-divider"><div /></div>
      </template>

      <div class="form-content">
        <div class="input-title">Email</div>
        <div class="input-action">
          <InputText
            :pt="{
              root: { size: user.profile.Email.length + 1 || 0 },
            }"
            size="500"
            disabled
            v-model="user.profile.Email"
            :placeholder="user.profile.Email"
          />
        </div>
      </div>

      <div class="form-content">
        <div class="input-title">Email Filtering</div>
        <div class="input-action">
          <InputSwitch v-model="emailFilteringToggle" />
        </div>
      </div>
    </Panel>
    <!-- <Panel>
      <template #header
        ><div class="panel-header">Notifications</div>
        <div class="horizontal-divider"><div /></div
      ></template>

      <div class="form-content">
        <div class="input-title">Product Updates</div>
        <div class="input-action">
          <InputSwitch v-model="productUpdateSwitch" />
        </div>
      </div>

      <div class="form-content">
        <div class="input-title">Email Productivity Tips</div>
        <div class="input-action">
          <InputSwitch v-model="emailProductivityTipsSwitch" />
        </div>
      </div>
    </Panel> -->

    <Panel>
      <template #header
        ><div class="panel-header">Subscription & Billing</div>
        <div class="horizontal-divider"><div /></div
      ></template>

      <div class="form-content">
        <div class="input-title">Subscription Tier</div>
        <div class="input-action">
          {{ subscriptionTypeDisplayText }}
        </div>
      </div>

      <div class="form-content danger-buttons">
        <div class="input-title"></div>
        <div class="input-action">
          <Button label="Manage Subscription" @click="navigateToCustomerPortal" class="manage-subscription-link" />
          <Button severity="danger" label="Delete Account" text @click="deleteAccount" />
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import store from '../../store';
import { USER_REQUEST } from '@/store/actions/user';
import { PROVIDER_REQUEST } from '@/store/actions/provider';
import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';
import Panel from 'primevue/panel';
import Button from 'primevue/button';

export default {
  name: 'Settings',
  components: {
    InputText,
    InputSwitch,
    Panel,
    Button,
  },
  data() {
    const emailFilteringToggle = this.$store.state.user.profile.IsFilteringOn;
    return {
      // referralLinkVisible: false,
      emailFilteringToggle,
    };
  },
  watch: {
    emailFilteringToggle: function (newVal) {
      this.toggleEmailFiltering(newVal);
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },

    hasAnySubscription() {
      return this.$store.state.user.attributes.hasSubscription;
    },

    subscriptionTypeDisplayText() {
      const sub = this.user.attributes.subscriptionTier;
      return sub.toLowerCase();
    },
    // referralLink() {
    //   return 'https://app.inmoat.com/?type=' + this.user.profile.Type + '&referred=' + this.user.profile.Email;
    // },
  },
  mounted() {
    store.dispatch(USER_REQUEST);
    store.dispatch(PROVIDER_REQUEST);
  },
  methods: {
    async refreshUser() {
      await this.$store.dispatch(USER_REQUEST);
      window.location.reload();
    },

    async toggleEmailFiltering(emailFilteringToggleVal) {
      const pauseFiltering = !emailFilteringToggleVal;

      this.axios
        .patch('/dashboard/user-filtering', {
          pauseFiltering,
        })
        .then((response) => {
          if (response.status !== 200) {
            // TODO: handle error
            return;
          }

          store.dispatch(USER_REQUEST);
        });
    },

    // showReferralLink() {
    //   this.referralLinkVisible = true;
    // },

    async navigateToCustomerPortal() {
      this.axios
        .get('/dashboard/user/subscription/customer-portal-url')
        .then((response) => {
          if (response.status !== 200) {
            // TODO: handle error
          }

          window.location.href = response.data;
        })
        .catch((e) => {
          console.log(e);
          // TODO: handle error
        });
    },

    async deleteAccount() {
      if (
        window.confirm('This action will PERMANENTLY delete your InMoat account. Are you sure you want to continue?')
      ) {
        this.axios.delete('/dashboard/delete-user').then((response) => {
          if (response.status !== 200) {
            // TODO: handle error
          }

          localStorage.clear();
          window.location.href = 'https://www.inmoat.com';
        });
      }
    },
  },
};
</script>

<style scoped>
.p-panel :deep(.p-panel-header),
.p-panel :deep(.p-panel-content) {
  border: none !important;
  background-color: #fff;
  padding-left: 0;
  padding-right: 0;
}

.p-panel :deep(.p-panel-header) {
  width: 100%;
  padding-bottom: 0.5rem;
}

.p-panel :deep(.p-panel-content) {
  padding-top: 0;
  padding-bottom: 0;
}

.horizontal-divider {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 1px;
}

.horizontal-divider > div {
  width: 100%;
  border-bottom: 1px solid #10b981;
}

.panel-header {
  font-weight: bold;
  margin-right: 1rem;
  text-wrap: nowrap;
  font-size: 1.25rem;
}

.form-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
}

.form-content > .input-action {
  text-transform: capitalize;
}

.p-button {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-left: 0.5rem;
}

.manage-subscription-link .p-button :deep(.p-button-label) {
  transition-duration: 0s;
  color: #fff;
}

.danger-buttons {
  margin-top: 3rem;
}

.p-panel:not(:first-of-type) {
  margin-top: 0.25rem;
}
</style>

<template>
  <div>
    <template v-if="!this.provider">
      <div class="container mx-auto px-4 h-full">
        <div class="flex content-center items-center justify-center">
          <div class="w-full lg:w-5/12 px-4">
            <div class="relative flex flex-col min-w-0 break-words w-full mb-3 shadow-lg rounded-lg bg-white border-0">
              <div class="rounded-t mb-0 px-20 py-6">
                <div class="text-center mb-3">
                  <h6
                    class="inline-block p-4 px-0 my-auto mr-0 text-4xl font-bold text-center text-brand_logo md:block md:pb-2 whitespace-nowrap"
                  >
                    <img :src="logo" alt="logo" class="inline-block w-12" />
                    InMoat
                  </h6>
                </div>
                <div class="text-center mb-3">
                  <h6 class="text-blueGray-500 text-sm font-bold">
                    Register below to create your InMoat account to get started
                  </h6>
                </div>
                <div class="btn text-center">
                  <button
                    class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-3 rounded outline-none focus:outline-none mr-4 my-4 uppercase shadow-md hover:shadow-lg inline-flex items-center font-bold text-s ease-linear transition-all duration-150"
                    type="button"
                    @click="clickGoogle"
                  >
                    <img alt="..." class="w-5 mr-2" :src="google" />
                    Sign in with Google
                  </button>
                  <button
                    class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-3 rounded outline-none focus:outline-none mr-4 my-4 uppercase shadow-md hover:shadow-lg inline-flex items-center font-bold text-s ease-linear transition-all duration-150"
                    type="button"
                    @click="clickOutlook"
                  >
                    <img alt="..." class="w-5 mr-2" :src="outlook" />
                    Sign in with Outlook
                  </button>
                </div>
                <div class="text-center mt-3 mb-8">
                  <h6 class="text-blueGray-500 text-xs font-bold">Start for free. Cancel anytime.</h6>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap mt-3 relative">
              <div class="text-center text-xs text-blueGray-300 mx-auto">
                *InMoat will never store, save or delete your data without your direct consent.
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="this.provider">
      <div class="container mx-auto px-4 h-full">
        <div class="flex content-center items-center justify-center h-full">
          <div class="w-full lg:w-5/12 px-4">
            <div class="relative flex flex-col min-w-0 break-words w-full mb-3 shadow-lg rounded-lg bg-white border-0">
              <div class="rounded-t mb-0 px-10 py-6">
                <div class="text-center mb-3">
                  <h6
                    class="inline-block p-4 px-0 my-auto mr-0 text-2xl font-bold text-center text-black md:block md:pb-2"
                  >
                    Your Privacy is Important to us
                  </h6>
                </div>
                <div class="text-center mb-3">
                  <h6 class="text-black text-md font-semibold">What kind of data we collect</h6>
                  <p class="text-black text-sm text-justify">
                    InMoat only scans the content of emails as they are received. Your emails are never accessible,
                    stored or deleted by us without your direct consent.
                  </p>
                </div>
                <div class="text-center mb-3">
                  <h6 class="text-black text-md font-semibold">How we use your data</h6>
                  <p class="text-black text-sm text-justify">
                    By scanning your emails, InMoat is able to categorize and filter unimportant emails to improve your
                    inbox.
                  </p>
                </div>
                <div class="text-center mb-3">
                  <h6 class="text-black text-md font-semibold">Let's get started</h6>
                  <p class="text-black text-sm text-justify">
                    By clicking continue you agree to InMoat's
                    <a
                      class="text-black text-md font-semibold"
                      href="https://www.inmoat.com/terms-of-use"
                      target="_blank"
                      >Terms of Service</a
                    >
                    and
                    <a
                      class="text-black text-md font-semibold"
                      href="https://www.inmoat.com/privacy-policy"
                      target="_blank"
                      >Privacy Policy</a
                    >
                  </p>
                </div>
                <div class="btn text-center">
                  <button
                    ref="btnRef"
                    @click="login"
                    class="w-full md:w-auto min-w-48 h-16 mb-4 mr-4 bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import outlook from '@/assets/img/outlook.svg';
import google from '@/assets/img/google.svg';
import { AUTH_REQUEST } from '@/store/actions/auth';
import { v4 as uuid4 } from 'uuid';
import logo from '@/assets/img/logo_blue.svg';

export default {
  name: 'login',
  data() {
    return {
      provider: null,
      google,
      outlook,
      logo,
    };
  },
  methods: {
    clickGoogle() {
      this.provider = 'GOOGLE';
    },
    clickOutlook() {
      this.provider = 'MICROSOFT';
    },
    login() {
      const sessionId = uuid4();
      sessionStorage.setItem('id', sessionId);

      this.params.set('session', sessionId);
      this.params.set('provider', this.provider);
      this.$store.dispatch(AUTH_REQUEST, this.params);
    },
  },
  beforeMount() {
    this.params = new URLSearchParams(window.location.search);
  },
};
</script>

<template>
  <div class="relative flex flex-col w-full min-w-0 mb-6 break-words bg-white rounded shadow-lg">
    <div class="px-4 py-3 mb-0 bg-transparent rounded-t">
      <div class="flex flex-wrap items-center">
        <div class="relative flex-1 flex-grow w-full max-w-full">
          <h6 class="mb-1 text-xs font-semibold uppercase text-blueGray-400">Overview</h6>
          <h2 class="text-xl font-semibold text-blueGray-700">Emails you've received</h2>
        </div>
      </div>
    </div>
    <div class="flex-auto p-4">
      <!-- Chart -->
      <div class="relative h-350-px">
        <canvas id="line-chart"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js';

export default {
  data() {
    return {
      chart: null,
    };
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    labels() {
      if (!this.data) {
        return null;
      }

      return this.data.map((d) => d.Date);
    },
    inboxData() {
      if (!this.data) {
        return null;
      }

      return this.data.map((d) => d.InboxTotal);
    },
    inmoatData() {
      if (!this.data) {
        return null;
      }

      return this.data.map((d) => d.InmoatTotal);
    },
  },
  watch: {
    data() {
      this.refreshChartData();
    },
  },
  methods: {
    refreshChartData() {
      (this.chart.data = {
        labels: this.labels,
        datasets: [
          {
            label: 'Emails left in inbox',
            backgroundColor: '#5ACEA9',
            borderColor: '#5ACEA9',
            fill: false,
            data: this.inboxData,
          },
          {
            label: 'Emails filtered by InMoat',
            backgroundColor: '#E1341D',
            borderColor: '#E1341D',
            fill: false,
            data: this.inmoatData,
          },
        ],
      }),
        this.chart.update();
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      let config = {
        type: 'line',
        options: {
          maintainAspectRatio: false,
          responsive: true,
          title: {
            display: false,
            text: 'InMoat Overview',
            fontColor: 'white',
          },
          legend: {
            labels: {
              fontColor: 'rgba(0,0,0,.4)',
            },
            align: 'end',
            position: 'bottom',
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          hover: {
            mode: 'nearest',
            intersect: true,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: 'rgba(0,0,0,.4)',
                },
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: 'Month',
                  fontColor: 'white',
                },
                gridLines: {
                  display: false,
                  borderDash: [2],
                  borderDashOffset: [2],
                  color: 'rgba(33, 37, 41, 0.3)',
                  zeroLineColor: 'rgba(33, 37, 41, 0.3)',
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: 'rgba(0,0,0,.4)',
                  precision: 0,
                },
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: 'Value',
                  fontColor: 'white',
                },
                gridLines: {
                  borderDash: [3],
                  borderDashOffset: [3],
                  drawBorder: false,
                  color: 'rgba(33, 37, 41, 0.2)',
                  zeroLineColor: 'rgba(33, 37, 41, 0.15)',
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                },
              },
            ],
          },
        },
      };
      var ctx = document.getElementById('line-chart').getContext('2d');
      this.chart = new Chart(ctx, config);
    });
  },
};
</script>

<template>
  <div style="flex-direction: column">
    <div class="flex flex-wrap">
      <div class="w-full px-4 mb-4 xl:mb-0">
        <insights-time-interval-dropdown v-model="selectedTimeInterval" />
      </div>
    </div>
    <div class="flex flex-wrap mt-4">
      <div class="w-full px-4 mb-12 xl:mb-0">
        <insights-line-chart :data="graphData" />
      </div>
    </div>
    <div class="flex flex-wrap mt-4">
      <div class="w-full px-4 mb-12 xl:w-3/5 xl:mb-0">
        <insights-pie-chart :data="pieAndTallyData" />
      </div>

      <div class="flex flex-wrap xl:w-2/5">
        <div class="w-full px-4 md:w-1/2 xl:w-1/2">
          <insights-dashboard-feature>
            <template v-slot:header>Emails Received</template>
            <template v-slot:description>{{ emailsReceived }}</template>
          </insights-dashboard-feature>
        </div>
        <div class="w-full px-4 md:w-1/2 xl:w-1/2">
          <insights-dashboard-feature>
            <template v-slot:header>Time saved</template>
            <template v-slot:description>{{ timeSaved }}</template>
          </insights-dashboard-feature>
        </div>
        <div class="w-full px-4 md:w-1/2 xl:w-1/2">
          <insights-dashboard-feature>
            <template v-slot:header>Emails to Inbox</template>
            <template v-slot:description>{{ emailsToInbox }}</template>
          </insights-dashboard-feature>
        </div>
        <div class="w-full px-4 md:w-1/2 xl:w-1/2">
          <insights-dashboard-feature>
            <template v-slot:header>Emails to @InMoat</template>
            <template v-slot:description>{{ emailsFiltered }}</template>
          </insights-dashboard-feature>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InsightsTimeIntervalDropdown from '@/components/Dropdowns/InsightsTimeIntervalDropdown.vue';
import InsightsLineChart from '@/components/Insights/InsightsLineChart.vue';
import InsightsPieChart from '@/components/Insights/InsightsPieChart.vue';
import InsightsDashboardFeature from '@/components/Insights/InsightsDashboardFeature.vue';

export default {
  name: 'insights-page',
  components: {
    InsightsTimeIntervalDropdown,
    InsightsLineChart,
    InsightsPieChart,
    InsightsDashboardFeature,
  },
  data() {
    return {
      selectedTimeInterval: '',
      graphData: [],
      pieAndTallyData: {},
    };
  },
  computed: {
    emailsReceived() {
      if (!this.pieAndTallyData.InboxTotal) {
        return 0;
      }

      return this.pieAndTallyData.InboxTotal + this.pieAndTallyData.InmoatTotal;
    },
    emailsFiltered() {
      if (!this.pieAndTallyData.InmoatTotal) {
        return 0;
      }

      return this.pieAndTallyData.InmoatTotal;
    },
    emailsToInbox() {
      if (!this.pieAndTallyData.InboxTotal) {
        return 0;
      }

      return this.pieAndTallyData.InboxTotal;
    },
    timeSaved() {
      if (!this.pieAndTallyData.InmoatTotal) {
        return 0;
      }

      let number = this.pieAndTallyData.InmoatTotal * 1.17;

      if (number >= 60 * 24) {
        number = number / (60 * 24);
        let days = Math.floor(number);
        let remainder = number - days;
        let hours = Math.floor(remainder * 24);
        return days + 'd, ' + hours + 'h';
      } else if (number >= 60) {
        number = number / 60;
        let hours = Math.floor(number);
        let remainder = number - hours;
        let minutes = Math.floor(remainder * 60);
        return hours + 'h, ' + minutes + 'm';
      } else {
        let minutes = Math.floor(number);
        return minutes + 'm';
      }
    },
  },
  watch: {
    selectedTimeInterval(newInterval) {
      this.getData(newInterval);
    },
  },
  methods: {
    getData: async function (interval) {
      this.axios
        .all([
          this.axios.post('/dashboard/user-events-date', interval),
          this.axios.post('/dashboard/user-events-count', interval),
        ])
        .then(
          this.axios.spread((graphData, pieAndTallyData) => {
            (this.graphData = graphData.data), (this.pieAndTallyData = pieAndTallyData.data);
          }),
        );
    },
  },
};
</script>

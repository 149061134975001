<template>
  <DataTable
    :value="contacts"
    v-model:selection="selectedTrustedContacts"
    selectionMode="multiple"
    scrollHeight="flex"
    sortMode="multiple"
    :metaKeySelection="false"
    scrollable
    removableSort
    tableStyle="min-width: 50rem"
    class="trusted-contacts-table border-round p-datatable-sm shadow-lg h-full"
  >
    <template #header>
      <div class="trusted-contacts-table-header flex flex-wrap align-items-center justify-content-between">
        <div class="left">
          <span class="table-title">Trusted Contacts</span>
          <AisRefreshButton />
        </div>
        <div class="right">
          <div class="delete-selected-contacts-button-container" v-if="selectedTrustedContacts.length > 1">
            <Button
              icon="fas fa-trash"
              label="Delete Selected Contacts"
              class="action danger danger-button delete-selected-contacts-button"
              severity="danger"
              @click="handleDeleteSelectedContactsClick()"
            />
          </div>
          <AisPaginator />
        </div>
      </div>
    </template>
    <template #empty>
      <GenericMessage
        class="empty-search-message"
        icon="fas fa-search"
        title="No results found"
        subtitle="Try adjusting your search to find what you're looking for"
      />
    </template>
    <Column
      selectionMode="multiple"
      headerStyle="width: 1rem"
      :pt="{
        checkboxWrapper: {
          onClick(e) {
            e.stopPropagation();
          },
        },
      }"
    ></Column>
    <Column header="Name" sortable>
      <template #body="{ data: { name, email_address } }">
        {{ name || email_address }}
      </template>
    </Column>
    <Column field="email_address" header="Email" sortable></Column>
    <!-- <Column>
      <template #header>Action <HelpTooltip message="hi" class="header-tooltip" /></template>
      <template #body="{ contact }">
        <TrustedContactActionDropdown :contact="contact" />
      </template>
    </Column> -->

    <Column headerStyle="width:4rem">
      <template #body="{ data }">
        <div class="button-list">
          <Button icon="fas fa-user-edit" class="action info standard-button" @click="showEditContactModal(data)" />
          <Button
            icon="fas fa-trash"
            class="action danger danger-button"
            severity="danger"
            @click="handleContactDeleteClick(data)"
          />
          <!-- <Button icon="fas fa-trash" class="action danger danger-button" severity="danger" outlined /> -->
        </div>
      </template>
    </Column>
  </DataTable>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useDialog } from 'primevue/usedialog';
import Button from 'primevue/button';
import AisPaginator from '@/components/Ais/AisPaginator.vue';
import AisRefreshButton from '@/components/Ais/AisRefreshButton.vue';
import ModalEditContact from '@/components/Modals/ModalEditContact';
import GenericMessage from '@/components/Messages/GenericMessage';
import { createWidgetMixin } from 'vue-instantsearch/vue3/es';
import { REFRESHING_AIS } from '@/store/actions/search';
import { SET_TRUSTED_CONTACTS_AIS_REFRESH_FN } from '@/store/actions/trustedContacts';
// import TrustedContactActionDropdown from '@/components/TrustedContacts/TrustedContactActionDropdown';
// import HelpTooltip from '@/components/Icons/HelpTooltip.vue';

const connectRefresh = (renderFn, unmountFn) => () => ({
  init() {
    renderFn({ refresh: {} }, true);
  },

  render({ instantSearchInstance }) {
    const refresh = instantSearchInstance.refresh.bind(instantSearchInstance);
    renderFn({ refresh }, false);
  },

  dispose() {
    unmountFn();
  },
});

export default {
  name: 'TrustedContactsTable',
  components: {
    DataTable,
    Column,
    Button,
    AisPaginator,
    AisRefreshButton,
    GenericMessage,
    // HelpTooltip,
    // TrustedContactActionDropdown,
  },
  mixins: [createWidgetMixin({ connector: connectRefresh })],
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const dialog = useDialog();
    return { dialog };
  },
  data() {
    return {
      selectedTrustedContacts: [],
      deletedTrustedContacts: [],
    };
  },
  computed: {
    isAisRefreshing() {
      return this.$store.state.search.refreshingAis;
    },
  },
  created() {
    this.$store.commit(SET_TRUSTED_CONTACTS_AIS_REFRESH_FN, () => this.state.refresh());
  },
  methods: {
    refreshAis() {
      this.$store.dispatch(REFRESHING_AIS, true);
      setTimeout(() => {
        this.state.refresh();
      }, 2500);
    },

    async handleContactDeleteClick(contact) {
      await this.deleteContact(contact);
      this.refreshAis();
    },

    async handleEditContactSubmit(contact) {
      await this.editContact(contact);
      this.refreshAis();
    },

    async handleDeleteSelectedContactsClick() {
      await this.deleteSelectedContacts();
      this.refreshAis();
    },

    async editContact(contact) {
      const transformedContact = {
        ...contact,
        id: contact.contact_id,
      };

      return this.axios.patch('/dashboard/v2/user-trusted-sources/single', JSON.stringify(transformedContact));
    },

    async deleteContact(contact) {
      this.deletedTrustedContacts.push(contact.contact_id);
      const transformedContact = {
        ...contact,
        id: contact.contact_id,
      };

      return this.axios.delete('/dashboard/v2/user-trusted-sources/single', {
        data: JSON.stringify(transformedContact),
      });
    },

    updateContactAction(contactId, action) {
      this.$emit('update-contact-action', contactId, action);
    },

    async deleteSelectedContacts() {
      this.deletedTrustedContacts = this.deletedTrustedContacts.concat(
        this.selectedTrustedContacts.map(({ contact_id }) => contact_id),
      );

      const transformedContacts = this.selectedTrustedContacts.map((c) => ({
        ...c,
        id: c.contact_id,
      }));

      return this.axios.delete('/dashboard/v2/user-trusted-sources/multiple', {
        data: JSON.stringify(transformedContacts),
      });
    },

    showEditContactModal(contact) {
      this.dialog.open(ModalEditContact, {
        data: { contact },
        props: {
          dismissableMask: true,
          modal: true,
          draggable: false,
          header: 'Edit Trusted Contact',
          style: {
            width: '45vw',
            // height: '70vh',
          },
          breakpoints: {
            '960px': '75vw',
            '640px': '90vw',
          },
        },
        onClose: ({ data }) => {
          if (data) {
            this.handleEditContactSubmit(data);
          }
        },
      });
    },
  },
};
</script>

<style scoped>
.trusted-contacts-table tr.p-highlight > td {
  /* color: #12b981; */
  color: #0a7350;
}

/* :not(.p-highlight) */
:deep(.trusted-contacts-table tr > td) {
  /* color: #666666; */
  color: #495057;
  color: red;
}

.trusted-contacts-table th.p-sortable-column.p-highlight .p-sortable-column-badge {
  display: none;
}

.trusted-contacts-table.p-datatable .p-datatable-tbody > tr:focus {
  outline: none;
}

.trusted-contacts-table th {
  background: #c3dfd8;
}

.trusted-contacts-table .subject-column {
  max-width: 45rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* gross selectors to override primevue below */
.trusted-contacts-table tr:not(.p-highlight) > td {
  /* color: #666666; */
  color: #495057;
}

:deep(.p-datatable-wrapper) {
  background-color: #fff;
}

.trusted-contacts-table-header {
  display: flex;
  height: 36px;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  color: #495057;
}

.trusted-contacts-table-header .table-title {
  color: #343a40;
}

.center .upsell-link {
  font-weight: bold;
}

.trusted-contacts-table-header .right,
.trusted-contacts-table-header .right .action-status-container {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.trusted-contacts-table-header .right .action-status-container {
  gap: 1rem;
}

:deep(.p-datatable-header) {
  padding-left: 0.75rem !important;
}

/* TODO: create component for new buttons */
.p-button.standard-button,
.p-button.danger-button,
.p-button.primary-button {
  background-color: #f8fafc;
  border: 1px solid #dee2e6;
  color: #475569;
}

.p-button.add-contact-button {
  transition-duration: 0s;
}

.p-button.delete-selected-contacts-button {
  transition-duration: 0s;
}

.p-button.delete-selected-contacts-button {
  padding: 0.5rem 1rem;
}

.p-button.standard-button:hover {
  background-color: #f8fafc;
  color: #475569;
  border: 1px solid #475569;
}

.p-button.danger-button:hover {
  color: #ef4444 !important;
  border-color: #ef4444;
  background-color: #f8fafc;
  background: #f8fafc !important;
}

.p-button.primary-button:hover {
  color: #10b981 !important;
  border-color: #10b981;
  background-color: #f8fafc;
  background: #f8fafc;
}

.p-button.p-button-icon-only {
  width: 2.5rem;
  padding: 0.5rem 0;
}

.button-list .action:not(:first-of-type) {
  margin-left: 1rem;
}

:deep(.p-datatable-emptymessage):hover {
  background-color: #fff !important;
}

:deep(.p-datatable-emptymessage > td) {
  border: none !important;
}

.empty-search-message {
  margin-top: 7.5rem;
}

.p-selectable-row.deleted {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s linear;
}

.p-selectable-row.deleted {
  background-color: #ef4444;
  color: #ef4444 !important;
}
</style>

<style>
.trusted-contacts-table.p-datatable .p-datatable-tbody > tr {
  color: #343a40;
}

.trusted-contacts-table.p-datatable .p-datatable-tbody > tr:hover {
  color: #000;
}

.header-tooltip {
  padding-bottom: 0.85rem;
}
</style>

<template>
  <div class="select-all-button-container">
    <ToggleButton
      v-model="userHasSelectedAll"
      onLabel="Clear selection"
      :offLabel="`Select all ${totalHits} matching emails`"
    />
  </div>
</template>

<script>
import { TOGGLE_SELECT_ALL_EMAILS } from '@/store/actions/search';
import ToggleButton from 'primevue/togglebutton';

export default {
  name: 'SelectAllButton',
  components: { ToggleButton },
  props: {
    createBatchTask: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    totalHits() {
      return this.$store.state.search.totalHits;
    },
    userHasSelectedAll: {
      get() {
        return this.$store.state.search.userHasSelectedAll;
      },
      set() {
        this.$store.dispatch(TOGGLE_SELECT_ALL_EMAILS, !this.$store.state.search.userHasSelectedAll);
      },
    },
    console() {
      return console;
    },
  },
};
</script>

<style>
.select-all-button-container .p-togglebutton {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
</style>

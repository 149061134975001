<template>
  <div class="flex flex-row flex-wrap gap-2">
    <div
      v-for="filter in filterList"
      :key="'retro-filter-tags-' + filter.ID"
      class="border-solid border-2 rounded-full py-1 px-2 cursor-pointer text-sm"
      :class="filter.ID === filterId ? 'border-black' : 'border-gray-300'"
      @click="updateSelectedFilter(filter.ID, filter.Name)"
    >
      {{ filter.Name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'RetroFilterTags',
  emits: ['update:filterId', 'update:text'],
  props: {
    filters: {
      type: Array,
      default: null,
    },

    filterId: {
      type: Number,
      default: null,
    },

    filterName: {
      type: String,
      default: null,
    },
  },
  computed: {
    filterList() {
      if (!this.filters) {
        return null;
      }

      const allFilters = {
        ID: null,
        Name: 'All',
      };

      return [allFilters, ...this.filters];
    },
  },
  methods: {
    updateSelectedFilter(newFilterId, newFilterName) {
      this.$emit('update:filterId', newFilterId);
      this.$emit('update:filterName', newFilterName);
    },
  },
};
</script>

<template>
  <div class="search-results-table-options-menu-container" @mouseover="menuOpen = true" @mouseleave="menuOpen = false">
    <div class="clickable-icon-with-background">
      <span class="fa-stack fa-1x" style="vertical-align: top" aria-haspopup="true" aria-controls="overlay_menu">
        <i class="fas fa-circle fa-stack-2x background"></i>
        <i class="fas fa-ellipsis-v fa-stack-1x"></i>
      </span>
    </div>
    <div :class="`search-results-table-options-menu custom-modal ${position}`" v-if="menuOpen">
      <a class="flex align-items-center action-button" @click="externalLinkItem.command">
        <ClickableIconWithBackground :faIconClass="externalLinkItem.faIconClass" :title="externalLinkItem.hoverText" />
        <span class="display-name">{{ externalLinkItem.displayName }}</span>
      </a>
      <ActionButton
        v-for="action in visibleActions"
        :createBatchTask="createBatchTask"
        class="action no-hover"
        v-bind:key="action.displayName"
        :action="action"
        :showDisplayName="true"
        :messageId="messageId"
        @click="menuOpen = false"
      />
    </div>
  </div>
</template>
<script>
import ActionButton from '@/components/Search/ActionButton.vue';
import ClickableIconWithBackground from '@/components/Search/ClickableIconWithBackground.vue';
import { actionDisplayConfig } from '@/constants/Actions';

export default {
  name: 'IndividualActionsMenu',
  components: {
    ActionButton,
    ClickableIconWithBackground,
  },
  props: {
    createBatchTask: {
      type: Function,
      default: () => null,
    },
    messageExternalUrl: {
      type: String,
      default: '',
    },
    messageId: {
      type: String,
      default: '',
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  methods: {
    handleActionButtonClick() {
      this.menuOpen = false;
    },
  },
  computed: {
    // hack to avoid having to override primevue table's overflow when showing menu
    position() {
      let position = 'position-default';
      // assumes total rows === 50
      if (this.rowIndex >= 47) {
        position = 'position-top';
      }

      return position;
    },
    visibleActions() {
      return actionDisplayConfig.filter(({ visible }) => visible);
    },
    externalLinkItem() {
      return {
        displayName: 'Open in Gmail',
        faIconClass: 'fas fa-external-link-alt',
        hoverText: 'Open in Gmail',
        isAction: false,
        command: () => {
          window.open(this.messageExternalUrl, '_blank');
        },
      };
    },
  },
};
</script>

<style scoped>
.search-results-table-options-menu-container {
  position: relative;
}

.search-results-table-options-menu.custom-modal {
  position: absolute;
  right: 100%;
  display: flex;
  flex-direction: column;
  width: 150px;
  background-color: #fff;
  background-color: white;
  z-index: 9999;
  border-radius: 6px;
  border: 1px solid #ced4da;
  padding: 0.5rem;
  cursor: default;
}

.search-results-table-options-menu.custom-modal.position-default {
  top: 0;
}

.search-results-table-options-menu.custom-modal.position-top {
  bottom: 0;
}

.search-results-table-options-menu.custom-modal.position-top {
}

.search-results-table-options-menu.custom-modal :deep(.action-button .clickable-icon-with-background) {
  pointer-events: none;
}

.search-results-table-options-menu.custom-modal:hover {
  border-color: #10b981;
}

.search-results-table-options-menu.custom-modal:hover {
  box-shadow: 0 0 0 0.2rem #a7f3d0;
}

.search-results-table-options-menu.custom-modal > .action-button:hover {
  background-color: #e9ecef;
}
</style>

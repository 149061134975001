export default Object.freeze({
  APPLY_TAG: 'APPLY_TAG',
  MARK_AS_READ: 'MARK_AS_READ',
  MOVE_TO_FOLDER: 'MOVE_TO_FOLDER',
  ARCHIVE: 'ARCHIVE',
  MOVE_TO_TRASH: 'MOVE_TO_TRASH',
});

const actionDisplayConfig = [
  {
    name: 'APPLY_TAG',
    displayName: 'Apply Tag',
    faIconClass: 'fas fa-tag',
    hoverText: 'Apply Tag',
    visible: false,
  },
  {
    name: 'MOVE_TO_FOLDER',
    displayName: 'Move to Folder',
    faIconClass: 'fas fa-folder-plus',
    hoverText: 'Move to Folder',
    visible: true,
  },
  {
    name: 'MARK_AS_READ',
    displayName: 'Mark as Read',
    faIconClass: 'fas fa-envelope-open',
    hoverText: 'Mark as Read',
    visible: false,
  },
  {
    name: 'ARCHIVE',
    displayName: 'Move to Archive',
    faIconClass: 'fas fa-archive',
    hoverText: 'Move to Archive',
    visible: true,
  },
  {
    name: 'MOVE_TO_TRASH',
    displayName: 'Move to Trash',
    faIconClass: 'fas fa-trash',
    hoverText: 'Move to Trash',
    visible: true,
  },
  {
    name: 'CLASSIFY',
    displayName: 'Classify Emails',
    faIconClass: 'fas fa-email',
    hoverText: 'Classify Emails',
    visible: false,
  },
];

const noAction = { name: null, displayName: 'No Action' };

let actionDisplayHash = {};
actionDisplayConfig.forEach(({ name, visible }) => (actionDisplayHash[name] = visible));

export { actionDisplayConfig, actionDisplayHash, noAction };

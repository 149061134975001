<template>
  <div class="w-full h-full overflow-hidden search-page">
    <!-- TODO: something vue-y and other than prop drilling for createBatchTask -->
    <PageLoadingMessage v-if="isLoading" />
    <InboxClassifyingMessage v-else-if="isClassifying" />
    <CardSearch :search-api-key="searchApiKey" :createBatchTask="createBatchTask" v-else />
  </div>
</template>

<script>
import CardSearch from '@/components/Cards/CardSearch.vue';
import InboxClassifyingMessage from '@/components/Search/InboxClassifyingMessage.vue';
import PageLoadingMessage from '@/components/Search/PageLoadingMessage.vue';
import { REQUEST_GET_BATCH_TASKS, RESET_STATE, REQUEST_GET_INBOX_CLASSIFY_STATUS } from '@/store/actions/search';

export default {
  name: 'Search',
  components: { CardSearch, InboxClassifyingMessage, PageLoadingMessage },
  computed: {
    searchApiKey() {
      return this.$store.state.user.attributes?.searchApiKey;
    },
    isClassifying() {
      return this.$store.state.search.inboxClassifyStatus.status === 'IN_PROGRESS';
    },
    isLoading() {
      return this.$store.state.search.inboxClassifyStatus.status === undefined;
    },
  },
  mounted() {
    this.$store.commit(RESET_STATE);
    this.$store.dispatch(REQUEST_GET_INBOX_CLASSIFY_STATUS);
    this.$store.dispatch(REQUEST_GET_BATCH_TASKS);
  },
};
</script>

<style scoped></style>

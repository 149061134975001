<template>
  <div @mouseleave="dropdownPopoverShow = false" style="position: relative">
    <a class="block text-blueGray-500" href="#pablo" ref="btnDropdownRef" v-on:click="toggleDropdown($event)">
      <div class="flex items-center">
        <!-- <span
          class="inline-flex items-center justify-center w-10 h-10 border border-solid rounded-full md:hidden text-md text-blueGray-100 border-blueGray-300"
        >
          <i class="fas fa-ellipsis-v"></i>
        </span>
        <span class="hidden md:block text-md">
          {{ profile.Name }}
          <i class="ml-2 fas fa-chevron-down" />
        </span> -->
        <i class="fas fa-user-circle profile-icon"></i>
      </div>
    </a>

    <div
      ref="popoverDropdownRef"
      class="z-50 float-left py-2 text-base text-left list-none bg-white rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <a
        href="javascript:void(0);"
        @click="toggleEmailFiltering"
        class="lg:hidden block w-full px-4 py-2 text-sm font-normal whitespace-nowrap text-blueGray-700"
        :class="isFilteringOn ? 'bg-red-200' : 'bg-emerald-200'"
      >
        {{ isFilteringOn ? 'Stop Email Filtering' : 'Start Email Filtering' }}
      </a>
      <div class="h-0 my-2 border border-solid border-blueGray-100 lg:hidden" />
      <a
        href="https://www.inmoat.com/resources/knowledge-base"
        target="_blank"
        class="block w-full px-4 py-2 text-sm font-normal bg-transparent whitespace-nowrap text-blueGray-700"
      >
        Knowledge Base
      </a>
      <a
        href="https://www.inmoat.com/resources/contact-us"
        target="_blank"
        class="block w-full px-4 py-2 text-sm font-normal bg-transparent whitespace-nowrap text-blueGray-700"
      >
        Contact Us
      </a>
      <div class="h-0 my-2 border border-solid border-blueGray-100" />
      <a
        @click="showAccountSettingsModal"
        class="block w-full px-4 py-2 text-sm font-normal bg-transparent whitespace-nowrap text-blueGray-700"
      >
        Account Settings
      </a>
      <div class="h-0 my-2 border border-solid border-blueGray-100" />
      <a
        href="javascript:void(0);"
        @click="logout"
        class="block w-full px-4 py-2 text-sm font-normal bg-transparent whitespace-nowrap text-blueGray-700"
      >
        Sign Out
      </a>
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
import { mapState } from 'vuex';
import { useDialog } from 'primevue/usedialog';

import { AUTH_LOGOUT } from '@/store/actions/auth';
import image from '@/assets/img/team-1-800x800.jpg';
import store from '@/store';
import { USER_REQUEST } from '@/store/actions/user';
import ModalAccountSettings from '@/components/Modals/ModalAccountSettings.vue';

export default {
  data() {
    return {
      dropdownPopoverShow: false,
      image: image,
    };
  },
  setup() {
    const dialog = useDialog();
    return { dialog };
  },
  computed: {
    ...mapState({ profile: (state) => state.user.profile }),

    isFilteringOn() {
      return this.$store.state.user.profile.IsFilteringOn;
    },
  },
  methods: {
    showAccountSettingsModal: function () {
      this.dialog.open(ModalAccountSettings, {
        props: {
          dismissableMask: true,
          modal: true,
          draggable: false,
          header: 'Account Settings',
          style: {
            width: '25vw',
            // height: '70vh',
          },
          breakpoints: {
            '960px': '75vw',
            '640px': '90vw',
          },
        },
      });
    },
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: 'bottom-start',
        });
      }
    },

    async toggleEmailFiltering() {
      let msg = 'This action will resume email filtering.';
      if (this.isFilteringOn) {
        msg = 'This action will pause email filtering.';
      }

      if (!window.confirm(`${msg} Are you sure you want to continue?`)) {
        return;
      }

      this.axios
        .patch('/dashboard/user-filtering', {
          pauseFiltering: this.isFilteringOn,
        })
        .then((response) => {
          if (response.status !== 200) {
            // TODO: handle error
            return;
          }

          store.dispatch(USER_REQUEST);
        });
    },

    logout: function () {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push('/auth/login');
      });
    },
  },
};
</script>

<style scoped>
.profile-icon {
  font-size: 3rem;
  color: #254275;
}
a {
  cursor: pointer;
}
</style>

<template>
  <div>
    <div
      class="fixed z-50 block max-w-md text-sm font-normal leading-normal text-left no-underline break-words transform bg-white rounded-lg border-10 -translate-x-2/4 -translate-y-2/4 top-1/2 left-1/2"
      v-show="modelValue"
    >
      <div class="pb-3 pt-6 px-8 text-xl font-bold text-black text-center">
        <slot name="intro-text"></slot>
      </div>

      <div class="px-8 pt-3 pb-6 text-black text-l">
        <slot name="copy"></slot>

        <input type="checkbox" v-model="consentGiven" />
        Yes, I give InMoat permission to store my emails during a Retro session.
      </div>

      <div class="w-full text-center">
        <button
          @click.prevent="modalHide"
          class="bg-gray-400 text-white active:bg-gray-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-3 ease-linear transition-all duration-150"
        >
          Cancel
        </button>
        <button
          :disabled="!consentGiven"
          @click.prevent="submitConsent"
          class="text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-3 ease-linear transition-all duration-150"
          :class="
            consentGiven ? 'bg-brand_light active:bg-sky-600' : 'bg-gray-300 text-gray-200 disabled cursor-not-allowed'
          "
        >
          Agree and begin
        </button>
      </div>
    </div>
    <div class="fixed inset-0 z-40 bg-black opacity-75" v-show="modelValue" @click="modalHide"></div>
  </div>
</template>

<script>
export default {
  name: 'ModalRetroConsent',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      consentGiven: false,
    };
  },
  emits: ['update:modelValue', 'submit:consent'],
  methods: {
    modalShow() {
      this.$emit('update:modelValue', true);
    },

    modalHide() {
      this.$emit('update:modelValue', false);
    },

    submitConsent() {
      this.$emit('submit:consent');
      this.modalHide();
    },
  },
};
</script>

<style scoped></style>

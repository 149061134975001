const decisions = Object.freeze({
  KEEP: 'KEEP',
  DELETE: 'DELETE',
});

const pages = Object.freeze({
  LANDING: 'LANDING',
  SORTING: 'SORTING',
  MESSAGES: 'MESSAGES',
  TRASH_CART: 'TRASH_CART',
  MOVING: 'MOVING',
  COMPLETE: 'COMPLETE',
  LOADING: 'LOADING',
});

const actions = Object.freeze({
  MOVE_TO_FOLDER: 'MOVE_TO_FOLDER',
  MOVE_TO_TRASH: 'MOVE_TO_TRASH',
});

export default Object.freeze({
  decisions: decisions,
  pages: pages,
  actions: actions,
});

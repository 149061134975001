<template>
  <div
    class="relative flex flex-col w-full min-w-0 py-6 px-4 mb-6 break-words rounded shadow-lg"
    :class="[color === 'light' ? 'bg-white' : 'bg-brand_dark text-white']"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'RetroBanner',
  props: {
    color: {
      default: 'light',
      validator: function (value) {
        // The value must match one of these strings
        return ['light', 'dark'].indexOf(value) !== -1;
      },
    },
  },
};
</script>

<style scoped></style>

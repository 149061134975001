<template>
  <a class="refresh-button" @click="refresh">
    <ClickableIconWithBackground :faIconClass="faIconClass" title="action.hoverText" />
  </a>
</template>

<script>
import { createWidgetMixin } from 'vue-instantsearch/vue3/es';
import ClickableIconWithBackground from '@/components/Search/ClickableIconWithBackground.vue';
import { REFRESHING_AIS } from '@/store/actions/search';

const connectRefresh = (renderFn, unmountFn) => () => ({
  init() {
    renderFn({ refresh: {} }, true);
  },

  render({ instantSearchInstance }) {
    const refresh = instantSearchInstance.refresh.bind(instantSearchInstance);
    renderFn({ refresh }, false);
  },

  dispose() {
    unmountFn();
  },
});

export default {
  props: {
    delay: {
      type: Number,
      default: 10000, // (10 seconds)
    },
  },
  components: { ClickableIconWithBackground },
  name: 'AisRefreshButton',
  mixins: [createWidgetMixin({ connector: connectRefresh })],
  // data() {
  //   return { faIconClass: 'fas fa-sync' };
  // },
  computed: {
    isRefreshing() {
      return this.$store.state.search.refreshingAis;
    },
    faIconClass() {
      return this.isRefreshing ? 'fas fa-sync fa-spin' : 'fas fa-sync';
    },
  },
  methods: {
    refresh() {
      this.$store.dispatch(REFRESHING_AIS, true);
      this.state.refresh();
    },
  },
};
</script>

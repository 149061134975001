<template>
  <i class="fas fa-question-circle info-icon" v-tooltip="{ value: message, placement: position }"></i>
</template>

<script>
export default {
  name: 'HelpTooltip',
  props: { message: { type: String, required: true }, position: { type: String, default: 'top' } },
};
</script>

<style scoped>
.info-icon {
  font-size: 0.85rem;
  margin-left: 0.25rem;
  display: inline-block;
  vertical-align: top;
  color: #6c757d;
}
</style>

<template>
  <div>
    <div>
      <ListBox v-model="selectedFolder" :options="folders" optionLabel="name" listStyle="max-height:350px;" filter />
    </div>
    <div class="button-container">
      <Button type="button" label="Move to Selected Folders" @click="handleSubmit"></Button>
    </div>
  </div>
</template>

<script>
import ListBox from 'primevue/listbox';
import Button from 'primevue/button';
import { inject } from 'vue';

export default {
  name: 'MoveToFolderActionModal',
  components: {
    ListBox,
    Button,
  },
  setup() {
    return {
      ref: inject('dialogRef'),
    };
  },
  data() {
    return {
      selectedFolder: null,
    };
  },
  methods: {
    handleSubmit() {
      this.ref.close(this.selectedFolder);
    },
  },
  computed: {
    folders() {
      return this.$store.state.user.folders;
    },
  },
};
</script>

<style>
.p-listbox .p-listbox-item.p-highlight {
  color: #0a7350 !important;
}
</style>

<style scoped>
.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
</style>

<template>
  <!-- toggle switch adapted from https://medium.com/front-end-weekly/build-a-css-only-toggle-switch-using-tailwindcss-d2739882934 -->
  <input
    type="checkbox"
    :id="inputId"
    class="absolute z-10 w-16 h-10 opacity-0 ml-4 p-1 rounded-full duration-300 ease-in-out"
    :class="checkboxClasses"
    :checked="modelValue"
    :disabled="isDisabled"
    @change="updateModelValue"
  />
  <span
    class="absolute z-0 w-16 h-10 flex items-center flex-shrink-0 bg-brand_light ml-4 p-1 rounded-full duration-300 ease-in-out"
    :class="toggleSwitchBackgroundClasses"
  >
    <span
      class="w-8 h-8 bg-white rounded-full shadow-md transform duration-300 relative"
      :class="toggleSwitchForegroundClasses"
    >
      <img
        src="@/assets/img/logo_brand_blue.svg"
        alt="InMoat logo, signifying that these emails are being sent to the @InMoat folder."
        class="mx-auto p-1 transform duration-300 absolute"
        :class="toggleSwitchOffImageClasses"
      />
      <img
        src="@/assets/img/file-tray_brand_blue.svg"
        alt="Inbox symbol, signifying that these emails are being sent to the inbox."
        class="mx-auto p-1 transform duration-300 absolute"
        :class="toggleSwitchOnImageClasses"
      />
    </span>
  </span>
</template>

<script>
const states = Object.freeze({
  ON: 'ON',
  ON_DISABLED: 'ON_DISABLED',
  OFF: 'OFF',
  OFF_DISABLED: 'OFF_DISABLED',
});

export default {
  name: 'ToggleSwitch',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    inputId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    modelValue() {
      this.loading = false;
    },
  },
  computed: {
    state() {
      if (this.modelValue && this.disabled) {
        return states.ON_DISABLED;
      } else if (!this.modelValue && this.disabled) {
        return states.OFF_DISABLED;
      }

      if (this.modelValue && this.loading) {
        return states.OFF_DISABLED;
      } else if (this.modelValue && !this.loading) {
        return states.ON;
      } else if (!this.modelValue && this.loading) {
        return states.ON_DISABLED;
      } else if (!this.modelValue && !this.loading) {
        return states.OFF;
      }

      return null;
    },

    isDisabled() {
      return this.loading || this.disabled;
    },

    checkboxClasses() {
      switch (this.state) {
        case 'ON':
          return 'cursor-pointer';
        case 'OFF_DISABLED':
          return '';
        case 'OFF':
          return 'cursor-pointer';
        case 'ON_DISABLED':
          return '';
      }

      return null;
    },

    toggleSwitchBackgroundClasses() {
      switch (this.state) {
        case 'ON':
          return '';
        case 'OFF_DISABLED':
          return 'filter grayscale opacity-50';
        case 'OFF':
          return 'filter grayscale';
        case 'ON_DISABLED':
          return 'filter grayscale opacity-50';
      }

      return null;
    },

    toggleSwitchForegroundClasses() {
      switch (this.state) {
        case 'ON':
          return 'translate-x-6';
        case 'OFF_DISABLED':
          return 'opacity-50';
        case 'OFF':
          return '';
        case 'ON_DISABLED':
          return 'opacity-50 translate-x-6';
      }

      return null;
    },

    toggleSwitchOnImageClasses() {
      switch (this.state) {
        case 'ON':
          return 'opacity-100';
        case 'OFF_DISABLED':
          return 'opacity-0';
        case 'OFF':
          return 'opacity-0';
        case 'ON_DISABLED':
          return 'opacity-100';
      }

      return null;
    },

    toggleSwitchOffImageClasses() {
      switch (this.state) {
        case 'ON':
          return 'opacity-0';
        case 'OFF_DISABLED':
          return 'opacity-100';
        case 'OFF':
          return 'opacity-100';
        case 'ON_DISABLED':
          return 'opacity-0';
      }

      return null;
    },
  },
  methods: {
    updateModelValue() {
      this.loading = true;
      this.$emit('update:modelValue');
    },
  },
};
</script>

<style scoped></style>

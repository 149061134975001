<template>
  <div>
    <div
      class="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
      v-show="modelValue"
    >
      <div class="relative w-auto max-w-5xl mx-auto my-6">
        <!--content-->
        <div
          class="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none"
        >
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
            <h3 class="text-3xl font-semibold text-center">
              <slot name="header"></slot>
            </h3>
            <button
              class="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
              @click="modalHide"
            >
              <span class="block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none">
                ×
              </span>
            </button>
          </div>

          <!--body-->
          <div class="relative flex-auto p-6">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div class="relative w-full mb-3">
                <label class="block mb-2 text-xs font-bold uppercase text-blueGray-600"> Your Smart Filters </label>
                <input
                  type="text"
                  v-model="filterTextSmartFilters"
                  placeholder="Search for filters"
                  class="relative w-full px-3 py-1 pl-10 mb-3 text-sm bg-white rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:shadow-outline"
                />
                <div class="overflow-y-scroll max-h-48">
                  <table>
                    <tbody>
                      <tr v-for="filter in smartFiltersFiltered" :key="'filter' + filter.ID">
                        <td>
                          <!-- loading spinner -->
                          <button v-if="filter.isLoading" class="button-rule p-2">
                            <i class="fas fa-spinner animate-spin" />
                          </button>

                          <!-- minus button -->
                          <button
                            v-else-if="filter.isSelected"
                            class="button-rule font-bold p-2"
                            @click="removeFilter(filter)"
                          >
                            <i class="fas fa-minus-circle" />
                          </button>

                          <!-- plus button -->
                          <button v-else class="button-rule font-bold p-2" @click="addFilter(filter)">
                            <i class="fas fa-plus-circle" />
                          </button>
                        </td>
                        <td class="px-2 py-1">{{ filter.Name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="relative w-full mb-3">
                <label class="block mb-2 text-xs font-bold uppercase text-blueGray-600"> Your Trusted Contacts </label>
                <input
                  type="text"
                  v-model="filterTextTrustedContacts"
                  placeholder="Search for contacts"
                  class="relative w-full px-3 py-1 pl-10 mb-3 text-sm bg-white rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:shadow-outline"
                />
                <div class="overflow-y-scroll max-h-48">
                  <table>
                    <tbody>
                      <tr
                        v-for="contact in trustedContactsFiltered"
                        :key="'contact' + contact.username + '@' + contact.domain"
                      >
                        <td>
                          <!-- loading spinner -->
                          <button v-if="contact.isLoading" class="button-rule p-2">
                            <i class="fas fa-spinner animate-spin" />
                          </button>

                          <!-- minus button -->
                          <button
                            v-else-if="contact.isSelected"
                            class="button-rule font-bold p-2"
                            @click="removeContact(contact)"
                          >
                            <i class="fas fa-minus-circle" />
                          </button>

                          <!-- plus button -->
                          <button v-else class="button-rule font-bold p-2" @click="addContact(contact)">
                            <i class="fas fa-plus-circle" />
                          </button>
                        </td>
                        <td class="px-2 py-1" style="white-space: pre">
                          {{
                            contact.name
                              ? contact.name + '\n(' + contact.username + '@' + contact.domain + ')'
                              : contact.username + '@' + contact.domain
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
            <button
              class="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
              type="button"
              @click="modalHide"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="fixed inset-0 z-40 bg-black opacity-75" v-show="modelValue"></div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    folders: {
      type: Object,
      default: () => ({}),
    },
    selectedFolderId: {
      type: Number,
      default: null,
    },
    smartFilters: {
      type: Array,
      required: true,
      default: () => [],
    },
    trustedContacts: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      smartFiltersData: [],
      trustedContactsData: [],
      filterTextSmartFilters: '',
      filterTextTrustedContacts: '',
    };
  },
  computed: {
    smartFiltersFiltered() {
      return this.smartFiltersData.filter((filter) => {
        return (
          filter.IsActive &&
          (filter.Name.toUpperCase().includes(this.filterTextSmartFilters.toUpperCase()) ||
            this.filterTextSmartFilters === '')
        );
      });
    },

    trustedContactsFiltered() {
      return this.trustedContactsData.filter((trustedContact) => {
        const email = trustedContact.username + '@' + trustedContact.domain;

        return (
          trustedContact.confirmed &&
          (trustedContact.name.toUpperCase().includes(this.filterTextTrustedContacts.toUpperCase()) ||
            email.toUpperCase().includes(this.filterTextTrustedContacts.toUpperCase()) ||
            this.filterTextTrustedContacts === '')
        );
      });
    },
  },
  watch: {
    selectedFolderId() {
      this.updateSelections();
    },

    folders() {
      this.updateSelections();
    },
  },
  methods: {
    modalShow() {
      this.$emit('update:modelValue', true);
    },

    modalHide() {
      this.filterTextTrustedContacts = '';
      this.filterTextSmartFilters = '';
      this.$emit('update:modelValue', false);
    },

    updateSelections() {
      const folder = this.folders.find((folder) => folder.userFolderId === this.selectedFolderId);

      this.smartFiltersData = this.smartFilters.map((filter) => {
        return {
          isSelected: !!folder?.smartFilters?.some((f) => f.ID === filter.ID),
          isLoading: false,
          ...filter,
        };
      });

      this.trustedContactsData = this.trustedContacts.map((contact) => {
        return {
          isSelected: !!folder?.trustedContacts?.some((c) => c.id === contact.id),
          isLoading: false,
          ...contact,
        };
      });
    },

    findFilterInOtherFolders(filter) {
      for (const folder of this.folders) {
        if (folder.userFolderId === this.selectedFolderId) {
          continue;
        }

        if (folder.smartFilters?.some((f) => f.ID === filter.ID)) {
          return {
            filterBelongsToOtherFolder: true,
            otherFolderId: folder.userFolderId,
          };
        }
      }

      return {
        filterBelongsToOtherFolder: false,
        otherFolderId: null,
      };
    },

    findContactInOtherFolders(contact) {
      for (const folder of this.folders) {
        if (folder.userFolderId === this.selectedFolderId) {
          continue;
        }

        if (folder.trustedContacts?.some((c) => c.id === contact.id)) {
          return {
            contactBelongsToOtherFolder: true,
            otherFolderId: folder.userFolderId,
          };
        }
      }

      return {
        contactBelongsToOtherFolder: false,
        otherFolderId: null,
      };
    },

    addFilter(filter) {
      let { filterBelongsToOtherFolder, otherFolderId } = this.findFilterInOtherFolders(filter);

      if (
        filterBelongsToOtherFolder &&
        !window.confirm(
          'This filter is already assigned to the ' +
            this.folders.find((folder) => folder.userFolderId === otherFolderId).name +
            ' folder. Reassign to this one instead?',
        )
      ) {
        return;
      }

      const filterIndex = this.smartFiltersData.findIndex((f) => f.ID === filter.ID);
      this.smartFiltersData[filterIndex].isLoading = true;

      this.$emit('add-filter-rule', {
        filterId: filter.ID,
        folderId: this.selectedFolderId,
      });
    },

    removeFilter(filter) {
      filter.isLoading = true;

      this.$emit('remove-filter-rule', {
        filterId: filter.ID,
        folderId: this.selectedFolderId,
      });
    },

    addContact(contact) {
      let { contactBelongsToOtherFolder, otherFolderId } = this.findContactInOtherFolders(contact);

      if (
        contactBelongsToOtherFolder &&
        !window.confirm(
          'This contact is already assigned to the ' +
            this.folders.find((folder) => folder.userFolderId === otherFolderId).name +
            ' folder. Reassign to this one instead?',
        )
      ) {
        return;
      }

      const contactIndex = this.trustedContactsData.findIndex((c) => c.id === contact.id);
      this.trustedContactsData[contactIndex].isLoading = true;

      this.$emit('add-contact-rule', {
        contactId: contact.id,
        folderId: this.selectedFolderId,
      });
    },

    removeContact(contact) {
      contact.isLoading = true;

      this.$emit('remove-contact-rule', {
        contactId: contact.id,
        folderId: this.selectedFolderId,
      });
    },
  },
};
</script>

<style scoped>
.button-rule {
  color: #0ea4e9;
}

.button-rule:hover {
  color: #0ea4e9;
  filter: brightness(85%);
}
</style>

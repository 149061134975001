<template>
  <div
    @click="selectCategoryTile"
    class="tile-category-color rounded-lg p-2 border-solid border-2 cursor-pointer flex flex-col justify-center items-center relative w-full h-full"
    :style="cssVariables"
  >
    <div v-if="isLoading" class="flex flex-col justify-center items-center">
      <i class="fas fa-spinner animate-spin" />
    </div>
    <div v-else>
      <div class="absolute top-0 left-0 p-1 text-xl font-bold">
        <slot name="category-name" />
      </div>
      <div class="flex flex-col justify-center items-center">
        <div class="text-3xl font-bold">
          <slot name="message-count" />
        </div>
        <div class="text-xs">emails ready to review</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RetroCategoryTile',
  emits: ['select:category'],
  props: {
    themeColor: {
      type: String,
      required: false,
      default: null,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cssVariables() {
      return {
        '--color': this.themeColor ? this.themeColor : '#0ea4e9',
      };
    },
  },
  methods: {
    selectCategoryTile() {
      this.$emit('select:category');
    },
  },
};
</script>

<style scoped>
.tile-category-color {
  border-color: var(--color);
}
.tile-category-color:active {
  filter: brightness(85%);
}
</style>

<template>
  <div class="upsell-modal-container">
    <div class="left">
      <div class="checklist-container">
        <div class="title">Premium includes:</div>
        <div class="checklists">
          <div class="checklist left">
            <div class="checklist-item" v-for="item in checklistItemsLeft" v-bind:key="item">
              <i class="fas fa-check" /><span class="text">{{ item }}</span>
            </div>
          </div>
          <div class="checklist right">
            <div class="checklist-item" v-for="item in checklistItemsRight" v-bind:key="item">
              <i class="fas fa-check" /><span class="text">{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <h2 class="subtitle">Learn more about paid plans and features</h2>
        <a href="https://www.inmoat.com/pricing" target="_blank">
          <Button class="button mr-4" label="Compare Plans" />
        </a>
        <Button class="button" @click="handleUpdateSubscriptionClick" label="Upgrade Your Plan" />
      </div>
    </div>

    <div class="right">
      <div class="image">
        <img :src="content.imgUrl" />
      </div>
    </div>
  </div>
</template>

<script>
import { useDialog } from 'primevue/usedialog';
import { inject } from 'vue';
import Button from 'primevue/button';
import upsell90DayImg from '@/assets/img/upsell_90_day.svg';
import upsellSearchActionsImg from '@/assets/img/upsell_search_actions.svg';

export default {
  name: 'UpsellModal',
  components: { Button },
  setup() {
    const dialog = useDialog();

    return {
      dialog,
      ref: inject('dialogRef'),
    };
  },
  data() {
    const contentMap = {
      default: {
        title: "You're currently on the free plan. Upgrade to unlock your full email history!",
        imgUrl: upsell90DayImg,
        checklistItems: [
          'Full Search History',
          'Bulk Actions (Deep Cleaner)',
          'Auto-labeling & Tagging',
          'Custom Filter Requests',
          'Custom Rules (Unlimited)',
          'Inbox Insights & Analytics',
          'Email Filtering (Unlimited)',
          'Trusted Contacts (Unlimited)',
        ],
      },
      action: {
        title: "You're currently on the free plan. Upgrade to start cleaning emails out!",
        imgUrl: upsellSearchActionsImg,
        checklistItems: [
          'Bulk Actions (Deep Cleaner)',
          'Full Email Search History',
          'Auto-labeling & Tagging',
          'Custom Filter Requests',
          'Custom Rules (Unlimited)',
          'Inbox Insights & Analytics',
          'Email Filtering (Unlimited)',
          'Trusted Contacts (Unlimited)',
        ],
      },
    };

    return {
      contentMap,
    };
  },
  mounted() {
    this.ref.options.props.header = this.content.title;
  },
  computed: {
    upsellType() {
      return this.ref.data.upsellType || 'default';
    },
    content() {
      return this.contentMap[this.upsellType];
    },
    checklistItemsLeft() {
      return this.content.checklistItems.slice(0, 4);
    },
    checklistItemsRight() {
      return this.content.checklistItems.slice(4, 8);
    },
    console: () => console, // TODO: deleteme
  },
  methods: {
    // handleComparePlansClick() {
    //   this.dialog.open(PricingModal, {
    //     props: {
    //       header: 'Choose the plan to fit your needs.',
    //       dismissableMask: true,
    //       draggable: false,
    //       modal: true,
    //       style: {
    //         width: '75vw',
    //       },
    //       breakpoints: {
    //         '960px': '75vw',
    //         '640px': '90vw',
    //       },
    //     },
    //   });
    //   // TODO: animate the transition between these modals
    //   // setTimeout smooths it abit for now
    //   setTimeout(() => this.ref.close(), 10);
    // },

    async handleUpdateSubscriptionClick() {
      this.axios
        .get('/dashboard/user/subscription/customer-portal-url', { params: { flowType: 'subscription_update' } })
        .then((response) => {
          if (response.status !== 200) {
            // TODO: handle error
          }

          window.location.href = response.data;
        })
        .catch((e) => {
          console.log(e);
          // TODO: handle error
        });
    },
  },
};
</script>

<style scoped>
.upsell-modal-container {
  display: flex;
  justify-content: space-between;
}

.content > div {
  display: inline-block;
}

.image {
  margin-right: -1.5rem;
}

.subtitle {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.upsell-modal-container > .left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.button {
}

.checklist {
  display: inline-block;
}

.checklist.left {
  margin-left: 1rem;
  margin-right: 2rem;
}

.checklist-item {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
}

.checklist-item:not(:last-of-type) {
  margin-bottom: 1rem;
}

.checklist-item i {
  color: #10b981;
  margin-right: 0.5rem;
}

.checklist-container {
  padding-top: 2rem;
  padding-bottom: 5rem;
}

.checklist-container .title {
  margin-bottom: 0.75rem;
  font-size: 1.5rem;
}
</style>

<style>
.p-dialog-title {
  /* primeevue override */
  font-size: 1.75rem !important;
}
</style>

<template>
  <a class="clickable-icon-with-background">
    <span class="fa-stack fa-1x" style="vertical-align: top">
      <i class="fas fa-circle fa-stack-2x background-icon"></i>
      <i :class="faIconClass + ' fas  fa-stack-1x front-icon'"></i>
    </span>
  </a>
</template>
<script>
export default {
  name: 'ClickableIconWithBackground',
  props: {
    faIconClass: {
      type: String,
      default: '',
    },
    handleClick: {
      type: Function,
      default: () => null,
    },
  },
};
</script>

<style scoped>
.clickable-icon-with-background i {
  transition: all 0.2s ease;
}

.clickable-icon-with-background .background-icon {
  color: transparent;
}

/* .clickable-icon-with-background:hover .background-icon {
  color: #f0fdfa;
} */

.clickable-icon-with-background:hover .front-icon {
  color: #0a7350;
}

.clickable-icon-with-background:hover .background-icon {
  color: #c3dfd8;
}
</style>

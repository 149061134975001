export const UPDATE_SELECTED_CURR_PAGE_EMAILS = 'UPDATE_SELECTED_CURR_PAGE_EMAILS';
export const UPDATE_BATCH_TASKS = 'UPDATE_BATCH_TASKS';
export const UPDATE_CURRENT_QUERY = 'UPDATE_CURRENT_QUERY';
export const UPDATE_SELECTED_TAG_IDS = 'UPDATE_SELECTED_TAG_IDS';
export const REQUEST_GET_BATCH_TASKS = 'REQUEST_GET_BATCH_TASKS';
export const UPDATE_ALL_CURR_PAGE_EMAILS = 'UPDATE_ALL_CURR_PAGE_EMAILS';
export const SELECT_ALL_CURR_PAGE_EMAILS = 'SELECT_ALL_CURR_PAGE_EMAILS';
export const TOGGLE_SELECT_ALL_EMAILS = 'SELECT_ALL_EMAILS';
export const UPDATE_USER_HAS_SELECTED_ALL = 'UPDATE_USER_HAS_SELECTED_ALL';
export const UPDATE_TOTAL_HITS = 'UPDATE_TOTAL_HITS';
export const REQUEST_CREATE_BATCH_TASK = 'REQUEST_CREATE_BATCH_TASK'; // TODO: success/error states
export const REFRESHING_AIS = 'REFRESHING_AIS';
export const RESET_STATE = 'RESET_STATE';
export const UPDATE_INBOX_CLASSIFY_STATUS = 'UPDATE_INBOX_CLASSIFY_STATUS';
export const REQUEST_GET_INBOX_CLASSIFY_STATUS = 'REQUEST_GET_INBOX_CLASSIFY_STATUS';
export const REMOVE_FROM_SELECTED_CURR_PAGE_EMAILS = 'REMOVE_SELECTED_CURR_PAGE_EMAILS';

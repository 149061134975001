<template>
  <div>
    <main>
      <section class="relative w-full h-full min-h-screen pricing-container">
        <div class="absolute top-0 w-full h-full bg-no-repeat bg-full"></div>

        <div class="relative flex flex-row justify-between items-center px-4 pt-4">
          <div>
            <img :src="logo" alt="logo" class="inline-block w-12" />
          </div>
          <div>
            <a class="font-bold" href="javascript:void(0);" @click="logout">Sign out</a>
          </div>
        </div>

        <div class="relative container mx-auto px-4 py-4 h-full">
          <div class="flex flex-col content-center items-center justify-center h-full w-full">
            <router-view class="relative" />
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import logo from '@/assets/img/logo_blue.svg';
import registerBg2 from '@/assets/img/register_bg_2.png';
import { AUTH_LOGOUT } from '@/store/actions/auth';

export default {
  data() {
    return {
      logo,
      registerBg2,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push('/auth/login');
      });
    },
  },
};
</script>

<style scoped>
.pricing-container {
  background-color: #f9fbfd;
  color: rgb(52, 58, 64);
}
</style>

<template>
  <div class="text-center w-full lg:w-4/5 header">
    <div class="font-bold title">Regain control of your inbox.</div>
    <div class="font-light subtitle">Pick a plan that works for you.</div>
  </div>

  <div class="recurrence-toggle">
    <SelectButton
      v-model="selectedRecurrence"
      :options="recurrenceOptionsArr"
      optionLabel="name"
      optionValue="value"
      :allowEmpty="false"
    />
  </div>

  <div class="price-cols w-full" v-if="!loading">
    <div
      class="price-col"
      v-for="(product, index) in products"
      v-bind:key="product.id"
      :class="{ featured: index === 1 && highlightFeaturedProduct }"
      v-on:mouseenter="highlightFeaturedProduct = false"
      v-on:mouseleave="highlightFeaturedProduct = true"
    >
      <span class="title" :style="{ color: colorBank[index].color }">
        {{ product.title }}
      </span>

      <div class="description">{{ product.description }}</div>

      <div class="cost">
        <div class="large">${{ getPrice(index)?.cost }}</div>
        <div class="timeframe" v-if="getPrice(index)?.cost !== 0">/ {{ getPrice(index)?.recursEvery.unit }}</div>
      </div>

      <!-- <div class="h-div" /> -->
      <Button class="subscribe-button" @click="goToCheckout(getPrice(index)?.id)">Get Started</Button>

      <div class="features-container">
        <div class="title">Includes:</div>
        <div class="feature-list">
          <div class="feature" v-for="feature in product.features" v-bind:key="feature">
            <CheckIcon /> {{ feature }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <stripe-pricing-table
    class="w-full"
    :pricing-table-id="stripePricingTableId"
    :publishable-key="stripeKey"
    :client-reference-id="userId"
    :customer-email="userEmail"
  /> -->

  <div class="text-center text-sm mb-12" v-if="!loading">Have a discount code? Enter it at checkout.</div>
</template>

<script>
import axios from 'axios';
import logo from '@/assets/img/logo_brand_blue.svg';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import orderBy from 'lodash-es/orderBy';
import { CheckIcon } from '@heroicons/vue/24/solid';
import SelectButton from 'primevue/selectbutton';
import Button from 'primevue/button';

export default {
  components: {
    CheckIcon,
    SelectButton,
    Button,
  },
  data() {
    return {
      logo,
      loading: true,
      highlightFeaturedProduct: true,
      products: [],
      colorBank: [
        { bg: '#f3effb', color: '#8159da' },
        { bg: '#fcf4e7', color: '#5972da' },
        { bg: '#e6f8f0', color: '#c259da' },
      ],
      selectedRecurrence: '1_MONTH',
      recurrenceOptions: {
        '1_MONTH': { value: '1_MONTH', name: 'Monthly' },
        '1_YEAR': { value: '1_YEAR', name: 'Yearly' },
      },
      // duplicating this for speed, sorry
      recurrenceOptionsArr: [
        { value: '1_MONTH', name: 'Monthly' },
        { value: '1_YEAR', name: 'Yearly' },
      ],
    };
  },
  async created() {
    const products = await this.getProducts();
    this.products = products;
  },
  computed: {
    stripePricingTableId() {
      return process.env.VUE_APP_STRIPE_PRICING_TABLE_ID;
    },
    stripeKey() {
      return process.env.VUE_APP_STRIPE_KEY;
    },

    userId() {
      return this.$store.state.user.profile.ID;
    },

    userEmail() {
      return this.$store.state.user.profile.Email;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push('/auth/login');
      });
    },

    async getProducts() {
      const url = '/dashboard/products';
      const res = await axios.get(url);
      this.loading = false;
      return orderBy(res.data.products, ['displayOrder']);
    },

    getPrice(productIndex) {
      return this.products[productIndex].prices[this.selectedRecurrence];
    },

    async goToCheckout(priceId) {
      const link = await this.requestCheckoutLink(priceId);
      window.location.href = link;
    },

    async requestCheckoutLink(priceId) {
      const url = '/dashboard/user/checkout';
      const payload = { priceId };
      const res = await axios.post(url, JSON.stringify(payload));
      return res.data.url;
    },
  },
};
</script>

<style scoped>
.header > .title {
  margin-top: -2rem;
  font-size: 3.75rem;
  line-height: 1;
}

.header > .subtitle {
  font-size: 3rem;
}

.price-cols {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

.price-col {
  border: 1px solid #d8ddf7;
  border-radius: 25px;
  margin: 0 1.5rem;
  padding: 2.5rem;
  border: 1px solid rgba(149, 157, 165, 0.2);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: box-shadow 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out;
  max-width: 350px;
  position: relative;
}

.price-col.featured,
.price-col:hover {
  transform: scale(1.025);
  transition-duration: 0.2s;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-shadow: 0 0 1rem #a7f3d0;
  border-color: #10b981;
  cursor: pointer;
}

.price-col.featured .subscribe-button,
.price-col:hover .subscribe-button {
  background: #0ea271;
  color: white;
}

.price-col > .title {
  font-size: 2.5rem;
}

.price-col > .description {
  color: #6c757d;
}

.features-container .title {
}

.feature {
  display: flex;
  width: 250px;
  margin-top: 0.5rem;
  font-size: 1.25rem;
}

.feature svg {
  width: 22px;
  margin-right: 0.5rem;
  color: rgb(16, 185, 129);
}

.h-div {
  height: 1.5rem;
  border-bottom: 1px solid #d8ddf7;
  margin-bottom: 2.5rem;
}

.cost {
  margin-top: 2rem;
  display: flex;
  align-items: baseline;
}

.cost .large {
  font-weight: bold;
  font-size: 3.5rem;
}

.cost .timeframe {
  margin-left: 1rem;
  color: #b9bec4;
}

.recurrence-toggle {
  margin-top: 1rem;
}

.subscribe-button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 3rem 0 2.5rem 0;
  font-size: 1.25rem;
  background: transparent;
  color: rgb(16, 185, 129);
}

:deep(.p-selectbutton .p-button) {
  border-radius: 0;
  box-shadow: none;
}

:deep(.p-selectbutton .p-button:first-of-type) {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

:deep(.p-selectbutton .p-button:last-of-type) {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
</style>

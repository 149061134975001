<template>
  <div class="flex flex-wrap">
    <div class="w-full px-4 mb-12">
      <card-folders
        :folders="userFolders"
        :smartFilters="smartFilters"
        :trustedContacts="trustedContacts"
        @add-filter-rule="addFilter"
        @remove-filter-rule="removeFilter"
        @add-contact-rule="addContact"
        @remove-contact-rule="removeContact"
      />
    </div>
  </div>
</template>

<script>
import CardFolders from '@/components/Cards/CardFolders.vue';

export default {
  name: 'rules',
  components: {
    CardFolders,
  },
  data() {
    return {
      userFolders: [],
      smartFilters: [],
      trustedContacts: [],
    };
  },
  mounted() {
    this.axios
      .all([
        this.axios.get('/dashboard/user-smart-filters'),
        this.axios.get('/dashboard/v2/user-trusted-sources'),
        this.axios.get('/dashboard/user-folders'),
      ])
      .then(
        this.axios.spread((smartFilters, trustedContacts, userFolders) => {
          (this.smartFilters = smartFilters.data),
            (this.trustedContacts = trustedContacts.data),
            (this.userFolders = userFolders.data);
        }),
      );
  },
  computed: {},
  methods: {
    async addFilter({ filterId, folderId }) {
      const response = await this.axios.patch(
        '/dashboard/user-folders/filter/add',
        JSON.stringify({ filterId, folderId }),
      );
      this.userFolders = response.data;
    },

    async removeFilter({ filterId, folderId }) {
      const response = await this.axios.patch(
        '/dashboard/user-folders/filter/remove',
        JSON.stringify({ filterId, folderId }),
      );
      this.userFolders = response.data;
    },

    async addContact({ contactId, folderId }) {
      const response = await this.axios.patch(
        '/dashboard/user-folders/trusted-source/add',
        JSON.stringify({ contactId, folderId }),
      );
      this.userFolders = response.data;
    },

    async removeContact({ contactId, folderId }) {
      const response = await this.axios.patch(
        '/dashboard/user-folders/trusted-source/remove',
        JSON.stringify({ contactId, folderId }),
      );
      this.userFolders = response.data;
    },
  },
};
</script>

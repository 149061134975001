<template>
  <nav>
    <div class="flex flex-wrap items-center justify-between w-full">
      <!-- Brand -->
      <a v-if="showNavbarBrand" href="/" class="flex brand"
        ><img :src="logo" alt="logo" /> <span class="text pl-2">InMoat</span></a
      >

      <div class="page-title-container">
        <h2 v-if="showPageName" class="page-title">
          {{ this.$route.name }}
        </h2>
        <!-- <i class="fas fa-question-circle info-icon" v-tooltip="'Lorem ipsum'"></i> -->
      </div>

      <!-- Filtering -->
      <div class="hidden lg:flex lg:flex-grow flex-row justify-end">
        <!-- <button
          v-if="showEmailFilterButton"
          class="px-4 py-2 mx-4 font-bold uppercase transition-all duration-150 ease-linear rounded shadow text-white hover:shadow-md focus:outline-none"
          :style="{ backgroundColor: isFilteringOn ? '#e24c4c' : '#10b981' }"
          @click="toggleEmailFiltering"
        >
          {{ isFilteringOn ? 'Stop Email Filtering' : 'Start Email Filtering' }}
        </button> -->
      </div>

      <!-- User -->
      <ul class="flex-col items-center hidden list-none md:flex-row md:flex">
        <user-dropdown />
      </ul>
    </div>
  </nav>
</template>

<script>
import UserDropdown from '@/components/Dropdowns/UserDropdown.vue';
import store from '@/store';
import { USER_REQUEST } from '@/store/actions/user';
import logo from '@/assets/img/logo_brand_blue_dark.svg';

export default {
  components: {
    UserDropdown,
  },
  data() {
    return {
      logo,
    };
  },
  computed: {
    isFilteringOn() {
      return this.$store.state.user.profile.IsFilteringOn;
    },
    showEmailFilterButton() {
      return this.$route.name !== 'Home';
    },
    showNavbarBrand() {
      return this.$route.name === 'Home';
    },
    showPageName() {
      return this.$route.name !== 'Home';
    },
  },
  methods: {
    async toggleEmailFiltering() {
      let msg = 'This action will resume email filtering.';
      if (this.isFilteringOn) {
        msg = 'This action will pause email filtering.';
      }

      if (!window.confirm(`${msg} Are you sure you want to continue?`)) {
        return;
      }

      this.axios
        .patch('/dashboard/user-filtering', {
          pauseFiltering: this.isFilteringOn,
        })
        .then((response) => {
          if (response.status !== 200) {
            // TODO: handle error
            return;
          }

          store.dispatch(USER_REQUEST);
        });
    },
  },
};
</script>

<style scoped>
.brand {
  align-items: center;
}

.brand img {
  width: 3rem;
}

.brand .text {
  font-weight: bold;
  font-size: 2rem;
  color: #254275;
}

.page-title {
  margin-left: 1rem;
  font-weight: bold;
  font-size: 2rem;
  margin-right: 0.5rem;
}

.page-title-container {
  display: flex;
}

.info-icon {
  cursor: pointer;
  color: #6c757d;
  font-size: 1rem;
}
</style>

import {
  USER_REQUEST,
  USER_REFRESH,
  USER_ERROR,
  USER_SUCCESS,
  FLAGS_SUCCESS,
  ATTRIBUTES_SUCCESS,
  FOLDERS_SUCCESS,
  TAGS_SUCCESS,
} from '../actions/user';
import axios from 'axios';
import { AUTH_LOGOUT } from '../actions/auth';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
});

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

const state = { status: '', profile: {}, flags: {}, attributes: {}, folders: [], tags: [] };

const getters = {
  getProfile: (state) => state.profile,
  isProfileLoaded: (state) => !!state.profile.name,
  isFlagSet: (state) => (flag) => {
    return state.flags[flag];
  },
};

const actions = {
  [USER_REQUEST]: ({ commit, dispatch }) => {
    commit(USER_REQUEST);

    const userPromise = api
      .get('dashboard/user')
      .then((resp) => {
        commit(USER_SUCCESS, resp.data);
      })
      .catch(() => {
        commit(USER_ERROR);
        // if resp is unauthorized, logout, to
        dispatch(AUTH_LOGOUT);
      });

    const featureFlagsPromise = api
      .get('dashboard/feature-flags')
      .then((resp) => {
        commit(FLAGS_SUCCESS, resp.data);
      })
      .catch(() => {
        commit(USER_ERROR);
        // if resp is unauthorized, logout, to
        dispatch(AUTH_LOGOUT);
      });

    const attributesPromise = api
      .get('dashboard/user/attributes')
      .then((resp) => {
        commit(ATTRIBUTES_SUCCESS, resp.data);
      })
      .catch(() => {
        commit(USER_ERROR);
        // if resp is unauthorized, logout, to
        dispatch(AUTH_LOGOUT);
      });

    const foldersPromise = api
      .get('dashboard/user-folders')
      .then((resp) => {
        commit(FOLDERS_SUCCESS, resp.data);
      })
      .catch(() => {
        commit(USER_ERROR);
        // if resp is unauthorized, logout, to
        dispatch(AUTH_LOGOUT);
      });

    const tagsPromise = api
      .get('dashboard/user-tags')
      .then((resp) => {
        commit(TAGS_SUCCESS, resp.data);
      })
      .catch(() => {
        commit(USER_ERROR);
        // if resp is unauthorized, logout, to
        dispatch(AUTH_LOGOUT);
      });

    return Promise.allSettled([userPromise, featureFlagsPromise, attributesPromise, foldersPromise, tagsPromise]);
  },
  [USER_REFRESH]: ({ dispatch }) => {
    if (!state.status === 'success') return dispatch(USER_REQUEST);
    if (!state.profile.ID) return dispatch(USER_REQUEST);
  },
};

const mutations = {
  [USER_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = 'success';
    state.profile = resp;
    // In Vue 2 the below .set() was needed now, the above is preferred method
    // Vue.set(state, "profile", resp);
  },
  [FLAGS_SUCCESS]: (state, flags) => {
    state.status = 'success';
    state.flags = flags;
  },
  [ATTRIBUTES_SUCCESS]: (state, attributes) => {
    state.status = 'success';
    state.attributes = attributes;
  },
  [FOLDERS_SUCCESS]: (state, folders) => {
    state.status = 'success';
    state.folders = folders ? folders : [];
  },
  [TAGS_SUCCESS]: (state, tags) => {
    state.status = 'success';
    state.tags = tags ? tags : [];
  },
  [USER_ERROR]: (state) => {
    state.status = 'error';
  },
  [AUTH_LOGOUT]: (state) => {
    state.profile = {};
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

import axios from 'axios';
import VueAxios from 'vue-axios';

const axiosPlugin = {
  install(app, options) {
    if (!options) {
      options = {};
    }

    const token = localStorage.getItem('token');
    axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    app.use(VueAxios, axios);
  },
};

export default axiosPlugin;

<template>
  <div>
    <retro-landing-page
      v-show="currentPage === retro.pages.LANDING"
      :inbox-count="inboxCount"
      :is-subscription-needed="isSubscriptionNeeded"
      :is-upgrade-needed="isUpgradeNeeded"
      @refresh:user="refreshUser"
      @navigate:next="startRetro"
    />
    <retro-progress v-show="currentPage === retro.pages.SORTING" :progress="sort.progress">
      <div class="text-5xl mb-4">Scanning your inbox.</div>
      <div class="text-2xl mb-8">
        This might take a while. Feel free to close this window—we’ll send you an email when it’s done.
      </div>
      <div class="mb-4">Depending on how many emails you have in your inbox, scanning can take up to 24 hours.</div>
    </retro-progress>
    <retro-messages
      v-show="currentPage === retro.pages.MESSAGES"
      :categories="categories"
      :messages="messages"
      @update:messages="performEdit"
      @navigate:trash-cart="currentPage = retro.pages.TRASH_CART"
    />
    <retro-trash-cart
      :messages="messages"
      v-show="currentPage === retro.pages.TRASH_CART"
      @navigate:back="currentPage = retro.pages.MESSAGES"
      @update:messages="performEdit"
      @submit:trash-cart="submitTrashCart($event)"
    />
    <retro-progress v-show="currentPage === retro.pages.MOVING" :progress="move.progress">
      <div class="text-5xl mb-4">Cleaning up your inbox.</div>
      <div class="text-2xl mb-8">You're so close...</div>
      <div class="mb-4">
        Depending on how many emails you have in your inbox, this might take a little while too. Again, feel free to
        close this window and take a walk—we'll email you again to let you know when we're done!
      </div>
    </retro-progress>
    <retro-complete v-show="currentPage === retro.pages.COMPLETE" @submit:retro-start="startRetro" />
    <retro-loading v-show="currentPage === retro.pages.LOADING"></retro-loading>
  </div>
</template>

<script>
import { parseISO } from 'date-fns';
import retro from '@/constants/Retro.js';
import RetroLandingPage from '@/components/Retro/RetroLandingPage.vue';
import RetroProgress from '@/components/Retro/RetroProgress.vue';
import RetroMessages from '@/components/Retro/RetroMessages.vue';
import RetroTrashCart from '@/components/Retro/RetroTrashCart.vue';
import RetroComplete from '@/components/Retro/RetroComplete.vue';
import { USER_REQUEST } from '@/store/actions/user';
import RetroLoading from '@/components/Retro/RetroLoading.vue';

export default {
  components: {
    RetroLoading,
    RetroLandingPage,
    RetroProgress,
    RetroMessages,
    RetroTrashCart,
    RetroComplete,
  },
  data() {
    return {
      retro,
      currentPage: retro.pages.LOADING,
      sessionUrl: '',
      categories: [],
      messagesById: {},
      inboxCount: null,
      sort: {
        statusUrl: '',
        resultsUrl: '',
        progress: {
          completed: 0,
          total: null,
        },
        handleResults: (results) => {
          this.messagesById = this.indexMessages(results);
        },
        next: () => {
          this.currentPage = retro.pages.MESSAGES;
        },
      },
      edit: {
        statusUrl: '',
        resultsUrl: '',
        progress: {
          completed: 0,
          total: null,
        },
        handleResults: (results) => {
          this.updateIndex(results);
        },
        next: () => {},
      },
      move: {
        statusUrl: '',
        resultsUrl: '',
        actions: {
          kept: '',
          deleted: '',
        },
        progress: {
          completed: null,
          total: null,
        },
        handleResults: (results) => {
          this.updateIndex(results);
        },
        next: () => {
          this.currentPage = retro.pages.COMPLETE;
          this.$store.dispatch(USER_REQUEST);
        },
      },
      pollingTimers: [],
    };
  },
  async created() {
    await this.$store.dispatch(USER_REQUEST);
    await this.setStartingState();
  },
  computed: {
    isSubscriptionNeeded() {
      return (
        !this.$store.state.user.profile.SubscriptionStatus ||
        this.$store.state.user.profile.SubscriptionStatus === 'canceled'
      );
    },

    isUpgradeNeeded() {
      return this.$store.state.user.profile.SubscriptionStatus !== 'active';
    },

    messages() {
      return Object.values(this.messagesById);
    },
  },
  methods: {
    async setStartingState() {
      const { task, summary, statusUrl } = await this.getRetroStatus();

      if (task?.taskId == null && summary?.id == null) {
        // if we have no current task and no past summary, user has never run retro
        this.currentPage = retro.pages.LANDING;
        await this.getUserInboxCount();
        return;
      }

      if (task?.taskId == null) {
        // if we have no current task but we do have a past summary, go to the complete page
        this.currentPage = retro.pages.COMPLETE;
        return;
      }

      if (task.operation === 'SORT' && task.status === 'IN_PROGRESS') {
        // if sort is either in progress or complete, restart the flow by polling for its status
        this.currentPage = retro.pages.SORTING;
        this.sort.statusUrl = statusUrl;
        await this.pollForResults(this.sort);
        return;
      }

      if (task.operation === 'SORT' && task.status === 'COMPLETE') {
        // if sort is either in progress or complete, restart the flow by polling for its status
        this.currentPage = retro.pages.LOADING;
        this.sort.statusUrl = statusUrl;
        await this.fetchExistingResultsFromStatusUrl(this.sort);
        return;
      }

      if (task.operation === 'MOVE' && task.status === 'IN_PROGRESS') {
        // if move is either in progress or complete, restart the flow by polling for its status
        this.currentPage = retro.pages.MOVING;
        this.move.statusUrl = statusUrl;
        await this.pollForResults(this.move);
        return;
      }

      if (task.operation === 'MOVE' && task.status === 'COMPLETE') {
        // if move is either in progress or complete, restart the flow by polling for its status
        this.currentPage = retro.pages.LOADING;
        this.move.statusUrl = statusUrl;
        await this.fetchExistingResultsFromStatusUrl(this.move);
        return;
      }

      // if we're in any other state, it's unexpected; just show the landing page
      this.currentPage = retro.pages.LANDING;
      await this.getUserInboxCount();
      return;
    },

    async getRetroStatus() {
      const response = await this.axios.get('/dashboard/retro/status');

      if (response.status !== 200 && response.status !== 204) {
        // TODO: handle error
        return;
      }

      const { task, summary } = response.data;
      const statusUrl = response.headers['location'];

      return { task, summary, statusUrl };
    },

    async getRetroCategories() {
      const response = await this.axios.get('/dashboard/retro/categories');
      if (response.status !== 200) {
        // TODO: handle error
        return;
      }

      return response.data;
    },

    async performSort() {
      const response = await this.axios.post('/dashboard/retro/sort');
      if (response.status !== 202) {
        // TODO: handle error
        return;
      }

      this.sort.statusUrl = response.headers['location'];
      if (!this.sort.statusUrl) {
        // TODO: handle error
        return;
      }

      await this.pollForResults(this.sort);
    },

    async performEdit(updates) {
      const response = await this.axios.post('/dashboard' + this.sessionUrl + '/edit', updates);
      if (response.status !== 202) {
        // TODO: handle error
        return;
      }

      this.edit.statusUrl = response.headers['location'];
      if (!this.edit.statusUrl) {
        // TODO: handle error
        return;
      }

      await this.pollForResults(this.edit, 500);
    },

    async performMove() {
      const response = await this.axios.post('/dashboard' + this.sessionUrl + '/move', this.move.actions);
      if (response.status !== 202) {
        // TODO: handle error
        return;
      }

      this.move.statusUrl = response.headers['location'];
      if (!this.move.statusUrl) {
        // TODO: handle error
        return;
      }

      await this.pollForResults(this.move);
    },

    async pollForResults(currentOperation, timeout = 1000) {
      const response = await this.axios.get('/dashboard' + currentOperation.statusUrl);

      // if 201 CREATED is returned, operation is complete and results are created
      if (response.status === 201) {
        // stop all leftover polling timers
        this.clearAllTimers();

        // if a location was returned, that's the URL to fetch the results from
        const location = response.headers['location'];
        if (location) {
          currentOperation.resultsUrl = location;
        }

        // set progress to complete
        currentOperation.progress.completed = currentOperation.progress.total;

        // store the results we got and fetch any more there might be
        await this.fetchAndHandleResults(currentOperation);

        // move to the correct next page
        currentOperation.next();

        return;
      }

      // else, operation is not yet completed and we've received progress update
      currentOperation.progress.completed = response.data?.completed;
      currentOperation.progress.total = response.data?.total;

      // since operation is still running, set a timer to check again
      this.pollingTimers.push(
        setTimeout(async () => {
          await this.pollForResults(currentOperation, timeout);
        }, timeout),
      );
    },

    async fetchAndHandleResults(currentOperation) {
      // populate categories
      this.categories = await this.getRetroCategories();

      // start preparing the url for the next request
      let params = new URLSearchParams();

      // if a page size was specified, add it to the request; else, leave page size out
      if (currentOperation.resultsPageSize) {
        params.set('pageSize', currentOperation.resultsPageSize);
      }

      // fetch pages until no more page tokens are returned
      let results = [];
      let response;
      let nextPageToken;

      do {
        // if page token specified, include that in the request
        if (nextPageToken) {
          params.set('pageToken', nextPageToken);
        }

        // request next page of results
        const nextPageUrl = currentOperation.resultsUrl + '?' + params.toString();
        response = await this.axios.get(nextPageUrl);

        // add results to list
        results = results.concat(response.data.messages);

        // set next page token
        nextPageToken = response.data.nextPageToken;
      } while (nextPageToken);

      // now we have the full list of results
      // handle them and be done
      currentOperation.handleResults(results);

      // if a location was returned, that's the URL to start the next operation
      const location = response.headers['location'];
      if (location) {
        this.sessionUrl = location;
      }
    },

    async fetchExistingResultsFromStatusUrl(currentOperation) {
      // hit the status URL
      const response = await this.axios.get('/dashboard' + currentOperation.statusUrl);

      // if a location was returned, that's the URL to fetch the results from
      const location = response.headers['location'];
      if (location) {
        currentOperation.resultsUrl = location;
      }

      // handle results
      await this.fetchAndHandleResults(currentOperation);

      // move to next page
      currentOperation.next();
    },

    formatMessage(message) {
      return {
        ...message,
        date: parseISO(message.date),
      };
    },

    updateMessage(oldMessage, newMessage) {
      return {
        ...oldMessage,
        decision: newMessage.decision,
        isProcessed: newMessage.isProcessed,
        isMoved: newMessage.isMoved,
      };
    },

    indexMessages(messages) {
      return Object.fromEntries(
        messages?.map((message) => {
          return [message.id, this.formatMessage(message)];
        }),
      );
    },

    updateIndex(updates) {
      updates?.forEach((update) => {
        const oldMessage = this.messagesById[update.id];
        const newMessage = this.updateMessage(oldMessage, update);
        this.messagesById[update.id] = newMessage;
      });
    },

    submitTrashCart(actions) {
      this.move.actions = actions;
      this.performMove();
      this.currentPage = retro.pages.MOVING;
    },

    async refreshUser() {
      await this.$store.dispatch(USER_REQUEST);
      window.location.reload();
    },

    async getUserInboxCount() {
      const response = await this.axios.get('/dashboard/user/inbox-count');
      if (response.status !== 200) {
        // TODO: handle error
        return;
      }

      this.inboxCount = response.data;
    },

    async startRetro() {
      this.currentPage = retro.pages.SORTING;
      await this.performSort();
    },

    clearAllTimers() {
      for (const timer of this.pollingTimers) {
        clearTimeout(timer);
      }
    },
  },
  /* eslint-disable no-unused-vars */
  beforeRouteLeave(to, from) {
    this.clearAllTimers();
  },
  /* eslint-enable no-unused-vars */
};
</script>

<template>
  <div class="search-results-table-state-message-container">
    <div class="icon">
      <i :class="messageConfig[type].icon.class" :style="{ color: messageConfig[type].icon.color }" />
    </div>
    <div class="title">{{ messageConfig[type].title }}</div>
    <div v-if="!messageConfig[type].url" class="action">{{ messageConfig[type].action }}</div>
    <a v-if="messageConfig[type].url" :href="messageConfig[type].url" target="_blank" class="action">{{
      messageConfig[type].action
    }}</a>
  </div>
</template>
<script>
export default {
  name: 'SearchResultsTableStateMessage',
  props: {
    type: {
      type: String, // 'LOADING', 'ERROR', 'EMPTY', 'UPGRADE'
      default: null,
    },
  },
  data: () => ({
    messageConfig: {
      LOADING: {
        title: '',
        action: '',
        icon: { class: 'fas fa-spinner fa-spin', color: 'rgb(16, 185, 129)' }, // TODO: i dont think this color makes sense. change to something else?
      },
      ERROR: {
        title: 'Something went wrong.',
        action: 'Please refresh the page or wait and then try again.',
        icon: { class: 'fas fa-exclamation-triangle', color: '#e24c4c' },
      },
      EMPTY: {
        title: 'No results found.',
        action: 'Please try different search terms or tags.',
        icon: { class: 'fab fa-creative-commons-zero', color: 'rgb(16, 185, 129)' }, // TODO: i dont think this color makes sense. change to something else?
      },
      UPGRADE: {
        title: 'Upgrade now to use this content.', // TODO: better messaging hook
        action: 'Check out our flexible pricing plans',
        url: 'https://www.inmoat.com/pricing',
        icon: { class: 'fas fa-hand-point-up', color: 'rgb(37, 66, 117)' }, // TODO: change to upgrade icon
      },
    },
  }),
};
</script>

<style scoped>
.search-results-table-state-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 250px;
  width: 100%;
}

.icon {
  font-size: 6rem;
}

.title {
  font-size: 2rem;
}

.action {
  font-size: 1.25rem;
}

a.action {
  color: rgb(16, 185, 129);
}

a.action:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>

<template>
  <CardSettings />
</template>

<script>
import CardSettings from '@/components/Cards/CardSettings.vue';

export default {
  name: 'ModalAccountSettings',
  components: { CardSettings },
};
</script>

<template>
  <div class="flex flex-wrap">
    <div class="w-full px-4 mb-12">
      <div
        class="relative flex flex-col w-full min-w-0 py-6 mb-6 break-words rounded shadow-lg"
        :class="[color === 'light' ? 'bg-white' : 'bg-brand_dark text-white']"
      >
        <div>
          <div class="flex flex-col items-center justify-center mb-6">
            <div class="w-3/4 md:w-2/3 text-center"><slot /></div>
            <div v-if="isProgressLoaded" class="w-3/4 md:w-2/3 flex flex-row">
              <div :style="'width: ' + percent + '%'">
                <div class="bg-brand_dark rounded-full w-full h-full"></div>
                <div class="text-right min-w-min whitespace-nowrap">{{ percent.toFixed(0) }}% completed</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RetroProgress',
  props: {
    progress: {
      completed: {
        type: Number,
      },
      total: {
        type: Number,
      },
    },

    color: {
      default: 'light',
      validator: function (value) {
        // The value must match one of these strings
        return ['light', 'dark'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    isProgressLoaded() {
      return !!this.progress && !!this.progress.total;
    },

    decimal() {
      if (!this.isProgressLoaded) {
        return null;
      }

      return this.progress.total && this.progress.total !== 0 ? this.progress.completed / this.progress.total : 0;
    },

    percent() {
      if (!this.isProgressLoaded) {
        return null;
      }

      return Math.floor(this.decimal * 100);
    },
  },
};
</script>

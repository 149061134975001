<template>
  <a class="action-button" @click="handleClick">
    <ClickableIconWithBackground :faIconClass="action.faIconClass" :title="action.hoverText" />
    <span class="display-name" v-if="showDisplayName">{{ action.displayName }}</span>
  </a>
</template>

<script>
import { useDialog } from 'primevue/usedialog';
import { useConfirm } from 'primevue/useconfirm';
import MoveToFolderActionModal from '@/components/Search/MoveToFolderActionModal.vue';
import ClickableIconWithBackground from '@/components/Search/ClickableIconWithBackground.vue';
import UpsellModal from '@/components/Search/UpsellModal.vue';
import {
  UPDATE_SELECTED_CURR_PAGE_EMAILS,
  REQUEST_CREATE_BATCH_TASK,
  REMOVE_FROM_SELECTED_CURR_PAGE_EMAILS,
} from '@/store/actions/search';
import { createWidgetMixin } from 'vue-instantsearch/vue3/es';
import { REFRESHING_AIS } from '@/store/actions/search';
import flags from '@/constants/FeatureFlags';

const connectRefresh = (renderFn, unmountFn) => () => ({
  init() {
    renderFn({ refresh: {} }, true);
  },

  render({ instantSearchInstance }) {
    const refresh = instantSearchInstance.refresh.bind(instantSearchInstance);
    renderFn({ refresh }, false);
  },

  dispose() {
    unmountFn();
  },
});

export default {
  name: 'ActionButton',
  components: { ClickableIconWithBackground },
  mixins: [createWidgetMixin({ connector: connectRefresh })],
  emits: ['select-action'],
  props: {
    action: {
      type: Object,
      default: () => {},
    },
    createBatchTask: {
      type: Function,
      default: () => null,
    },
    showDisplayName: {
      type: Boolean,
      default: true,
    },
    onSuccess: { type: Function, default: () => null },
    messageId: {
      type: String,
      default: null,
    },
  },
  setup() {
    const dialog = useDialog();
    const confirm = useConfirm();
    return { dialog, confirm };
  },
  computed: {
    selectedCurrPageEmails: {
      get() {
        return this.$store.state.search.selectedCurrPageEmails;
      },
      set(value) {
        this.$store.commit(UPDATE_SELECTED_CURR_PAGE_EMAILS, value);
      },
    },
    isActionsEnabled() {
      return this.$store.getters.isFlagSet(flags.SearchActions) === true;
    },
    console() {
      return console;
    },
  },
  methods: {
    async emitAction(opts = {}) {
      const queryParams = {
        action: this.action.name,
        ...opts,
      };
      let afterRefreshAction = () => this.$store.commit(UPDATE_SELECTED_CURR_PAGE_EMAILS, []);

      if (this.messageId) {
        // single email action
        queryParams.messageIds = [this.messageId];
        afterRefreshAction = () => this.$store.commit(REMOVE_FROM_SELECTED_CURR_PAGE_EMAILS, [this.messageId]);
      } else if (this.$store.state.search.userHasSelectedAll) {
        // user has hit select all emails (all hits)
        queryParams.tags = this.$store.state.search.selectedTagIds.map((idString) => parseInt(idString));
        queryParams.query = this.$store.state.search.currentQuery;
      } else {
        // multiselect (but not select all) action
        queryParams.messageIds = this.selectedCurrPageEmails.map(({ message_id }) => message_id);
      }

      this.$store.dispatch(REFRESHING_AIS, true);
      this.$store.dispatch(REQUEST_CREATE_BATCH_TASK, queryParams).then((res) => {
        const task = res.data;
        this.onSuccess(task);

        setTimeout(() => {
          this.state.refresh();
          afterRefreshAction();
        }, 2500);
      });
    },
    showConfirmModal() {
      const numAffectedEmails = this.$store.state.search.userHasSelectedAll
        ? this.$store.state.search.totalHits
        : this.$store.state.search.selectedCurrPageEmails.length;

      this.confirm.require({
        message: `${numAffectedEmails} emails will be affected.`,
        header: `Confirm ${this.action.displayName}`,
        icon: this.action.faIconClass,
        acceptClass: 'p-button-text p-button-text',
        acceptLabel: this.action.displayName,
        rejectClass: 'p-button-danger p-button-text',
        rejectLabel: 'Cancel',
        accept: () => {
          this.emitAction();
        },
        // reject: () => {},
      });
    },
    handleClick() {
      if (!this.isActionsEnabled) {
        this.dialog.open(UpsellModal, {
          data: {
            upsellType: 'action',
          },
          props: {
            dismissableMask: true,
            modal: true,
            draggable: false,
            style: {
              width: '60vw',
            },
          },
        });
        return;
      }
      if (this.action.name === 'MOVE_TO_FOLDER') {
        this.openMoveToFolderActionModal();
      } else {
        if (this.messageId) {
          this.emitAction();
        } else {
          this.showConfirmModal();
        }
      }
    },
    handleMoveToFolderActionModalSubmit({ userFolderId }) {
      this.emitAction({ userFolderId });
    },
    openMoveToFolderActionModal() {
      this.dialog.open(MoveToFolderActionModal, {
        props: {
          dismissableMask: true,
          draggable: false,
          modal: true,
          header: 'Select New Folder',
          style: {
            width: '50vw',
          },
          breakpoints: {
            '960px': '75vw',
            '640px': '90vw',
          },
        },
        onClose: (options) => {
          const data = options.data;
          if (data) {
            this.handleMoveToFolderActionModalSubmit(data);
          }
        },
      });
    },
  },
};
</script>

<style>
.action-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #495057;
  z-index: 2;
}

.p-dialog .p-dialog-content {
  padding-top: 2px !important; /* room for box shadow */
}
</style>

import axios from 'axios';
import {
  REQUEST_GET_BATCH_TASKS,
  UPDATE_BATCH_TASKS,
  UPDATE_ALL_CURR_PAGE_EMAILS,
  UPDATE_SELECTED_CURR_PAGE_EMAILS,
  UPDATE_CURRENT_QUERY,
  UPDATE_SELECTED_TAG_IDS,
  UPDATE_USER_HAS_SELECTED_ALL,
  SELECT_ALL_CURR_PAGE_EMAILS,
  UPDATE_TOTAL_HITS,
  REQUEST_CREATE_BATCH_TASK,
  TOGGLE_SELECT_ALL_EMAILS,
  REFRESHING_AIS,
  RESET_STATE,
  REQUEST_GET_INBOX_CLASSIFY_STATUS,
  UPDATE_INBOX_CLASSIFY_STATUS,
  REMOVE_FROM_SELECTED_CURR_PAGE_EMAILS,
} from '../actions/search';
import { actionDisplayHash } from '../../constants/Actions';
import { sub, getUnixTime } from 'date-fns';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
});

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

const state = {
  allCurrPageEmails: [],
  selectedCurrPageEmails: [],
  batchTasks: [],
  selectedTagIds: [],
  currentQuery: '',
  totalHits: 0,
  userHasSelectedAll: false,
  refreshingAis: false,
  /*
  {
    "status": "IN_PROGRESS",	// COMPLETE if no classify tasks running
    "completed": 5, 			// messages completed count, 0 if no classify tasks running
    "total": 10					// messages total count, 0 if no classify tasks running
  }
  */
  inboxClassifyStatus: {},
};

const getDefaultState = () => ({
  allCurrPageEmails: [],
  selectedCurrPageEmails: [],
  batchTasks: [],
  selectedTagIds: [],
  currentQuery: '',
  totalHits: 0,
  userHasSelectedAll: false,
  refreshingAis: false,
  inboxClassifyStatus: {},
});

const actions = {
  [REQUEST_GET_BATCH_TASKS]: async ({ commit }, status) => {
    const yesterday = sub(new Date(), { days: 1 });
    const startOfYesterday = getUnixTime(yesterday.setHours(0, 0, 0, 0));
    // status: 'IN_PROGRESS' | 'COMPLETE' | 'ERROR' | falsy
    let url = `/dashboard/user/inbox/batch-tasks?started_after=${startOfYesterday}`;
    if (status) {
      url += `&status=${status}`;
    }

    const res = await axios.get(url);
    commit(UPDATE_BATCH_TASKS, res.data);
  },
  [REQUEST_GET_INBOX_CLASSIFY_STATUS]: async ({ commit, dispatch }) => {
    const url = '/dashboard/user/inbox/status';

    const res = await axios.get(url);
    commit(UPDATE_INBOX_CLASSIFY_STATUS, res.data);

    if (res.data.status !== 'COMPLETE') {
      setTimeout(() => {
        dispatch(REQUEST_GET_INBOX_CLASSIFY_STATUS);
      }, 10000);
    }
  },
  [SELECT_ALL_CURR_PAGE_EMAILS]: ({ commit, state }) => {
    commit(UPDATE_SELECTED_CURR_PAGE_EMAILS, state.allCurrPageEmails);
  },
  [REQUEST_CREATE_BATCH_TASK]: async ({ dispatch }, queryParams) => {
    const payload = {
      operation: 'PERFORM_ACTION',
      preset: 'prioritize_matching_tags',
      ...queryParams,
    };

    const res = await axios.post('/dashboard/user/inbox/batch-tasks', JSON.stringify(payload));
    dispatch(REQUEST_GET_BATCH_TASKS);

    return res;
  },
  [TOGGLE_SELECT_ALL_EMAILS]: ({ commit, state }, toggleVal) => {
    commit(UPDATE_USER_HAS_SELECTED_ALL, toggleVal);

    if (toggleVal === true) {
      commit(UPDATE_SELECTED_CURR_PAGE_EMAILS, state.allCurrPageEmails);
    }

    if (toggleVal === false) {
      commit(UPDATE_SELECTED_CURR_PAGE_EMAILS, []);
    }
  },
  [REFRESHING_AIS]: ({ commit, state }, refreshingAis) => {
    // spins the refresh spinner for 2.5sec
    // ais refresh doesnt respond with a promise so we fake it
    commit(REFRESHING_AIS, refreshingAis);
    if (state.refreshingAis === true) {
      setTimeout(() => {
        commit(REFRESHING_AIS, false);
      }, 2500);
    }
  },
};

const mutations = {
  [UPDATE_ALL_CURR_PAGE_EMAILS]: (state, allCurrPageEmails) => {
    state.allCurrPageEmails = allCurrPageEmails;

    if (state.userHasSelectedAll) {
      state.selectedCurrPageEmails = allCurrPageEmails;
    }
  },
  [UPDATE_SELECTED_CURR_PAGE_EMAILS]: (state, selectedCurrPageEmails) => {
    state.selectedCurrPageEmails = selectedCurrPageEmails;
    // TODO: move max hits per page to constant
    if (selectedCurrPageEmails !== state.allCurrPageEmails) {
      state.userHasSelectedAll = false;
    }
  },
  [REMOVE_FROM_SELECTED_CURR_PAGE_EMAILS]: (state, messageIdsToRemove) => {
    state.selectedCurrPageEmails = state.selectedCurrPageEmails.filter(
      ({ message_id }) => !messageIdsToRemove.includes(message_id),
    );
  },
  [UPDATE_BATCH_TASKS]: (state, batchTasks) => {
    const visibleBatchActions = batchTasks.filter(({ action }) => actionDisplayHash[action]);
    state.batchTasks = visibleBatchActions;
  },
  [UPDATE_SELECTED_TAG_IDS]: (state, selectedTagIds) => {
    state.selectedTagIds = selectedTagIds;
    state.selectedCurrPageEmails = [];
    state.userHasSelectedAll = false;
  },
  [UPDATE_CURRENT_QUERY]: (state, currentQuery) => {
    state.currentQuery = currentQuery;
    state.selectedCurrPageEmails = [];
    state.userHasSelectedAll = false;
  },
  [UPDATE_USER_HAS_SELECTED_ALL]: (state, newVal) => {
    state.userHasSelectedAll = newVal;
  },
  [UPDATE_TOTAL_HITS]: (state, totalHits) => {
    state.totalHits = totalHits;
  },
  [REFRESHING_AIS]: (state, refreshingAis) => {
    state.refreshingAis = refreshingAis;
  },
  [RESET_STATE]: (state) => {
    Object.assign(state, getDefaultState());
  },
  [UPDATE_INBOX_CLASSIFY_STATUS]: (state, status) => {
    state.inboxClassifyStatus = status;
  },
};

export default { state, mutations, actions };

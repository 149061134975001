<template>
  <DataTable
    v-model:selection="selectedCurrPageEmails"
    selectionMode="multiple"
    sortMode="multiple"
    :metaKeySelection="false"
    :value="allCurrPageEmails"
    tableStyle="min-width: 50rem"
    scrollHeight="flex"
    class="search-results-table border-round p-datatable-sm"
    removableSort
    scrollable
  >
    <template #header>
      <SearchResultsTableHeader :createBatchTask="createBatchTask" />
    </template>
    <template #empty
      ><GenericMessage
        class="empty-search-message"
        icon="fas fa-search"
        title="No results found"
        subtitle="Try adjusting your search to find what you're looking for"
    /></template>
    <Column
      selectionMode="multiple"
      headerStyle="width: 1rem"
      :pt="{
        checkboxWrapper: {
          onClick(e) {
            e.stopPropagation();
          },
        },
      }"
    ></Column>
    <!-- <Column field="user_email" header="Email"></Column> -->
    <Column field="sender" header="Email" sortable></Column>
    <Column class="subject-column" field="subject" header="Subject" sortable />
    <Column field="tags" header="Tags" headerStyle="width: 15rem">
      <template #body="{ data }">
        <TagPillList :maxWidthRems="20" :tags="data.tags" :getPillColor="getPillColor" />
      </template>
    </Column>
    <Column header="Date" field="date" headerStyle="width: 8rem; text-wrap: nowrap" sortable>
      <template #body="{ data }">
        {{ formattedDateFromTimestamp(data.date) }}
      </template>
    </Column>
    <!-- <Column>
      <template #body="{ data }">
        <Button small @click="copyTextToClipboard(data.raw)">Copy source</Button>
      </template>
    </Column> -->
    <Column headerStyle="width: 1rem; text-align: center;">
      <template #body="{ data, index }">
        <IndividualActionsMenu
          id="overlay_menu"
          :messageId="data.message_id"
          :createBatchTask="createBatchTask"
          :messageExternalUrl="data.url"
          :rowIndex="index"
        />
      </template>
    </Column>
    <!-- <Column headerStyle="width: 1rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
      <template #body="{ data }">
        <div class="search-results-table-options-menu clickable-icon-with-background">
          <span class="fa-stack fa-1x" style="vertical-align: top">
            <i class="fas fa-circle fa-stack-2x background"></i>
            <i class="fas fa-ellipsis-v fa-stack-1x"></i>
          </span>
        </div>
      </template>
    </Column> -->
    <!-- <template #footer> In total there are {{ results ? results.length : 0 }} results. </template> -->
  </DataTable>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { format, fromUnixTime, isThisYear, isToday } from 'date-fns';
import TagPillList from '@/components/Search/TagPillList.vue';
import SearchResultsTableHeader from '@/components/Search/SearchResultsTableHeader.vue';
import IndividualActionsMenu from '@/components/Search/IndividualActionsMenu.vue';
import GenericMessage from '@/components/Messages/GenericMessage';
import actions from '@/constants/Actions';
import {
  UPDATE_SELECTED_CURR_PAGE_EMAILS,
  UPDATE_ALL_CURR_PAGE_EMAILS,
  UPDATE_TOTAL_HITS,
} from '@/store/actions/search';

export default {
  name: 'SearchResultsTable',
  components: {
    SearchResultsTableHeader,
    IndividualActionsMenu,
    DataTable,
    Column,
    TagPillList,
    GenericMessage,
  },
  props: {
    results: {
      type: Array,
      default: () => [],
    },
    totalHits: {
      type: Number,
      default: 0,
    },
    getPillColor: {
      type: Function, // (tag.name) => string
      default: () => 'success',
    },
    handleTagClick: {
      type: Function, // (tag.name) => null
      default: () => null,
    },
    createBatchTask: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      loading: true,
      empty: false,
      actions: actions,
    };
  },
  mounted() {
    this.$store.commit(UPDATE_ALL_CURR_PAGE_EMAILS, this.results);
    this.$store.commit(UPDATE_TOTAL_HITS, this.totalHits);
  },
  watch: {
    totalHits: function (newVal) {
      this.$store.commit(UPDATE_TOTAL_HITS, newVal);
    },
    results: function (newVal) {
      this.$store.commit(UPDATE_ALL_CURR_PAGE_EMAILS, newVal);
    },
  },
  methods: {
    formattedDateFromTimestamp(timestamp) {
      let date = fromUnixTime(timestamp);

      if (isToday(date)) {
        return format(date, 'h:mm a');
      } else if (isThisYear(date)) {
        return format(date, 'MMM d');
      } else {
        return format(date, 'MMM d, y');
      }
    },
    copyTextToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          alert('Copied to clipboard!');
        },
        () => {
          alert('Could not copy to clipboard.');
        },
      );
    },
    makeActionDisplayName(name) {
      switch (name) {
        case 'APPLY_TAG':
          return 'Apply Tag';
        case 'MOVE_TO_FOLDER':
          return 'Move to Folder';
        case 'MARK_AS_READ':
          return 'Mark as Read';
        case 'ARCHIVE':
          return 'Move to Archive';
        case 'MOVE_TO_TRASH':
          return 'Move to Trash';
        default:
          return name;
      }
    },
  },
  computed: {
    selectedCurrPageEmails: {
      get() {
        return this.$store.state.search.selectedCurrPageEmails;
      },
      set(value) {
        this.$store.commit(UPDATE_SELECTED_CURR_PAGE_EMAILS, value);
      },
    },
    allCurrPageEmails: {
      get() {
        return this.$store.state.search.allCurrPageEmails;
      },
    },
    transformedActions() {
      return Object.values(this.actions)
        .filter((a) => a !== 'APPLY_TAG') // HACK to remove APPLY_TAG from actions
        .map((action) => ({
          name: action,
          displayName: this.makeActionDisplayName(action),
          faIconClass: 'fas fa-circle',
        }));
    },
    console: () => console, // TODO: deleteme
  },
};
</script>

<style>
.table-title {
  font-size: 1.5rem;
  font-weight: bold;
}

/* gross selectors to override primevue below */
.search-results-table tr:not(.p-highlight) > td {
  /* color: #666666; */
  color: #495057;
}
.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-thead > tr > th {
  padding: 0.5rem 1rem !important;
  text-wrap: nowrap;
}

.search-results-table tr.p-highlight > td {
  /* color: #12b981; */
  color: #0a7350;
}

.search-results-table th.p-sortable-column.p-highlight .p-sortable-column-badge {
  display: none;
}

.search-results-table tr:hover > td {
  color: #000 !important;
}

.search-results-table.p-datatable .p-datatable-tbody > tr:focus {
  outline: none;
}

.search-results-table th {
  background: #c3dfd8;
}

.search-results-table .subject-column {
  max-width: 45rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.p-checkbox,
.p-checkbox-box,
.p-checkbox-box .p-component {
  width: 16px !important;
  height: 16px !important;
  border-radius: 4px !important;
}

.p-checkbox {
  margin-bottom: 1px !important;
}

.clickable-icon-with-background i {
  transition: all 0.1s ease;
  cursor: pointer;
}

.clickable-icon-with-background .background {
  color: transparent;
}

.clickable-icon-with-background:hover .background {
  color: #f0fdfa;
}

.clickable-icon-with-background:hover .fa-external-link-alt {
  color: #0a7350;
}

.p-selectable-row.p-highlight .clickable-icon-with-background:hover .background {
  color: #c3dfd8;
}

/* .action-list-container .action,
.action-list-container .hr {
  margin-right: 0.5rem;
} */

.divider {
  border-left: 1px solid #dee2e6 !important;
  height: 10px !important;
}

.p-datatable.p-datatable-sm .p-datatable-header {
  padding-left: 0.75rem !important;
}

.p-datatable-emptymessage > td {
  border: none !important;
}

.empty-search-message {
  margin-top: 7.5rem;
}

.p-datatable-emptymessage:hover {
  background-color: #fff !important;
}
</style>

<template>
  <div class="moatspace-page-container w-full container m-auto">
    <!-- <div class="flex flex-wrap row header">
      <h1><img :src="logo" alt="logo" /> <span>InMoat</span></h1>
    </div> -->
    <div class="flex flex-wrap row subheader">
      <h2>Hi {{ firstName }}, welcome to your Moatspace.</h2>
    </div>
    <div class="row subapp-list grid grid-cols-2 gap-10">
      <CardInmoatSubapp v-for="subapp in subapps" v-bind="subapp" v-bind:key="subapp.title" />
    </div>
  </div>
</template>

<script>
import logo from '@/assets/img/logo_brand_blue_dark.svg';
import subappFilterIcon from '@/assets/img/subapp-filter-icon.svg';
import subappSearchIcon from '@/assets/img/subapp-search-icon.svg';
import CardInmoatSubapp from '@/components/Cards/CardInmoatSubapp.vue';

export default {
  name: 'moatspace-page',
  components: {
    CardInmoatSubapp,
  },
  data() {
    return {
      logo,
      subapps: [
        {
          title: 'Search & Clean',
          description:
            'Use search tags to find any email effortlessly. Bulk delete or file emails you no longer need in just a couple clicks and get to inbox zero fast.',
          actionUrl: '/search',
          helpUrl: 'https://www.google.com',
          iconUrl: subappSearchIcon,
          iconClass: 'fas fa-search',
        },
        {
          title: 'Filters & Rules',
          description:
            'To keep your inbox organized moving forward, turn on Smart Filters important to you, and choose what goes where.',
          actionUrl: '/smart-filters',
          helpUrl: 'https://www.google.com',
          iconUrl: subappFilterIcon,
          iconClass: 'fas fa-filter',
        },
        {
          title: 'Insights & Analytics',
          description:
            "Get a statistical breakdown of all the emails you're receiving and filtering, and see how much time you're saving from unwanted distractions.",
          actionUrl: '/insights',
          helpUrl: 'https://www.google.com',
          iconUrl: logo,
          iconClass: 'fas fa-chart-line',
        },
        {
          title: 'Trusted Contacts',
          description:
            'Your Trusted Contacts are VIP contacts & organizations that you typically engage with, such as your colleagues, clients, partners, associates, and more.',
          actionUrl: '/trusted-contacts',
          helpUrl: 'https://www.google.com',
          iconUrl: logo,
          iconClass: 'fas fa-users',
        },
      ],
    };
  },
  computed: {
    firstName() {
      return this.$store.state.user.profile.Name.split(' ')[0];
    },
  },
};
</script>

<style scoped>
.moatspace-page-container {
  display: flex;
  flex-direction: column;
}

.row {
  margin-bottom: 1.5rem;
}

.row.header h1 {
  display: flex;
  align-items: center;
  color: #254275;
  font-weight: bold;
}

.row.header span {
  font-size: 5rem;
}

.row.header img {
  width: 7.5rem;
}

.row.subheader {
  font-size: 2.5rem;
  margin: 2rem 0 4rem 0;
}
</style>

import { createApp } from 'vue';
import router from './router';
import store from './store';
import axiosPlugin from './plugins/axios/main';
import PrimeVue from 'primevue/config';
import DialogService from 'primevue/dialogservice';
import ConfirmationService from 'primevue/confirmationservice';
import vClickOutside from 'click-outside-vue3';
import Tooltip from 'primevue/tooltip';

// styles

import '@fortawesome/fontawesome-free/css/all.min.css';
import '@/assets/styles/tailwind.css';
// import '@/assets/styles/soho-light-tiny.css';
import '@/assets/styles/lara-light-tiny-mint.css';
// mounting point for the whole app

import App from '@/App.vue';

createApp(App)
  .use(vClickOutside)
  .use(router)
  .use(store)
  .use(axiosPlugin)
  .use(PrimeVue, { ripple: true })
  .use(DialogService)
  .use(ConfirmationService)
  .directive('tooltip', Tooltip)
  .mount('#app');

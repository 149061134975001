<template>
  <div class="tag-pill-list">
    <TagPill
      v-for="{ name, key } in truncatedTags"
      :style="{ maxWidth: tagWidth }"
      :name="name"
      :value="name"
      :key="key"
      :color="getPillColor(name)"
      :handleClick="() => handleTagClick(name)"
    />
    <!-- {this.tags.slice(0, this.max).map(({ name, key }) => ( <TagPill style="max-width: 3rem;" name={name} value={name}
    key={key} color={this.getPillColor(name)} truncate={this.truncate} handleClick={() => this.handleTagClick(name)} />
    ))} -->
  </div>
</template>

<script>
import TagPill from '@/components/Search/TagPill.vue';

export default {
  name: 'TagPillList',
  components: { TagPill },
  props: {
    tags: {
      type: Array, // [{ name: string, key: string, onClick: fn }]
      default: () => [],
    },
    max: {
      type: Number,
      default: 3,
    },
    maxWidthRems: {
      type: Number,
      default: 10,
    },
    getPillColor: {
      type: Function, // (tag.name) => string
      default: () => 'success',
    },
    handleTagClick: {
      type: Function, // (tag.name) => null
      default: () => null,
    },
  },
  computed: {
    truncatedTags() {
      return this.tags ? this.tags.slice(0, this.max) : [];
    },
    tagWidth() {
      return Math.floor(this.maxWidthRems / this.truncatedTags.length) + 'rem';
    },
  },
};
</script>

<style scoped>
.tag-pill-list {
  display: flex;
}
.tag-pill-list > .tag-pill:not(:last-child) {
  margin-right: 0.5rem;
}
</style>

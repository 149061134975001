<template>
  <ais-pagination class="table-paginator">
    <template v-slot="{ currentRefinement, nbHits, isFirstPage, isLastPage, refine, createURL }">
      <span class="page-range"
        >{{ getRangeMin(currentRefinement, nbHits) }}-{{ getRangeMax(currentRefinement, nbHits, isLastPage) }} of
        {{ nbHits }}</span
      >
      <ul>
        <li v-bind:class="{ disabled: isFirstPage }">
          <a :href="createURL(currentRefinement - 1)" @click.prevent="refine(currentRefinement - 1)">
            <ClickableIconWithBackground faIconClass="fas fa-chevron-left" title="Previous Page" />
          </a>
        </li>

        <li v-bind:class="{ disabled: isLastPage }">
          <a :href="createURL(currentRefinement + 1)" @click.prevent="refine(currentRefinement + 1)">
            <ClickableIconWithBackground faIconClass="fas fa-chevron-right" title="Next Page" />
          </a>
        </li>
      </ul>
    </template>
  </ais-pagination>
</template>
<script>
import { AisPagination } from 'vue-instantsearch/vue3/es';
import ClickableIconWithBackground from '@/components/Search/ClickableIconWithBackground.vue';

export default {
  name: 'Paginator',
  components: { AisPagination, ClickableIconWithBackground },
  props: {
    itemsPerPage: {
      type: Number,
      default: 50,
    },
  },
  methods: {
    getRangeMin(currRefinement, nbHits) {
      if (nbHits === 0) {
        return 0;
      }

      const currPage = currRefinement + 1;
      return this.itemsPerPage * (currPage - 1) + 1;
    },
    getRangeMax(currRefinement, nbHits, isLastPage) {
      if (nbHits === 0) {
        return 0;
      }

      const currPage = currRefinement + 1;
      if (isLastPage) {
        return nbHits;
      }

      return this.itemsPerPage * currPage;
    },
  },
};
</script>

<style scoped>
.table-paginator {
  display: flex;
  gap: 1rem;
  margin-left: 0.5rem;
  align-items: center;
}

.table-paginator ul {
  display: flex;
}

.table-paginator ul li.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.7;
}
</style>

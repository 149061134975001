<template>
  <div @mouseleave="dropdownPopoverShow = false">
    <a
      class="block text-blueGray-500"
      href="javascript:void(0);"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="flex items-center">
        <span class="text-md">
          {{ timeIntervalText }}
          <i class="ml-2 fas fa-chevron-down" />
        </span>
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="z-50 float-left py-2 text-base text-left list-none bg-white rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <a
        href="javascript:void(0);"
        class="block w-full px-4 py-2 text-sm font-normal bg-transparent whitespace-nowrap text-blueGray-700"
        v-for="interval in timeIntervals"
        v-bind:key="interval.value"
        @click="selectInterval(interval)"
      >
        {{ interval.text }}
      </a>
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';

export default {
  data() {
    return {
      dropdownPopoverShow: false,
      timeIntervals: [
        { value: '7DAY', text: 'Last 7 days' },
        { value: '2WEEK', text: 'Last 2 weeks' },
        { value: '4WEEK', text: 'Last 4 weeks' },
        { value: '2MONTH', text: 'Last 2 months' },
        { value: '3MONTH', text: 'Last 3 months' },
        { value: '6MONTH', text: 'Last 6 months' },
        { value: '9MONTH', text: 'Last 9 months' },
        { value: '1YEAR', text: 'Last year' },
      ],
      selectedTimeInterval: { value: '7DAY', text: 'Last 7 days' },
    };
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  computed: {
    timeIntervalText() {
      if (!this.modelValue) {
        return '';
      }

      const selectedInterval = this.timeIntervals.find((interval) => interval.value === this.modelValue);
      return selectedInterval.text;
    },
  },
  created() {
    this.selectInterval(this.selectedTimeInterval);
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: 'bottom-start',
        });
      }
    },
    selectInterval: function (newInterval) {
      this.selectedTimeInterval = newInterval;
      this.dropdownPopoverShow = false;
      this.$emit('update:modelValue', this.selectedTimeInterval.value);
    },
  },
};
</script>

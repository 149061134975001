<template>
  <div>
    <!-- header -->
    <div class="flex flex-row justify-center items-center relative mb-4">
      <!-- back button -->
      <div class="absolute left-0">
        <button @click="$emit('navigate:back')">
          <i class="fas fa-chevron-left" />
          back to messages
        </button>
      </div>

      <!-- title -->
      <img :src="trashCartIcon" alt="trash cart" />
      <div class="text-5xl ml-4">Trash cart</div>
    </div>

    <!-- cart empty warning -->
    <div v-if="isCartEmpty" class="flex flex-col items-center my-12">
      <p>Your trash cart is empty.</p>
      <p>Go delete some emails!</p>
    </div>

    <!-- cart -->
    <div v-else class="flex flex-col">
      <!-- TODO: divide-y-2 -->
      <!-- header -->
      <div class="text-3xl font-bold">Retro order summary</div>

      <!-- kept and deleted lists -->
      <div v-for="category in categories" :key="'trash-cart-category' + category.id" class="flex flex-row my-6 gap-6">
        <div class="w-1/3 h-36 md:h-48">
          <!-- category tile -->
          <retro-sort-modal
            :category="category"
            :messages="category.messages"
            :themeColor="category.color"
            :disableTags="true"
            :onlyRefreshMessagesWhenVisible="false"
            @update:messages="submitMessagesUpdate($event, category.decision)"
          >
            <template v-slot:header> {{ category.name }}</template>
            <template v-slot:sorted-out-list>{{ category.sortedOutListText }}</template>
            <template v-slot:description>{{ category.description }}</template>
            <template v-slot:show-sorted-out-list>{{ category.showSortedOutListText }}</template>
            <template v-slot:show-main-list>Back</template>
            <template v-slot:move-to-sorted-out-list>{{ category.moveToSortedOutListText }}</template>
            <template v-slot:move-to-main-list>{{ category.moveToMainListText }}</template>
            <template v-slot:sorted-out-verb>{{ category.sortedOutVerbText }}</template>
            <template v-slot:submit-button>{{ category.submitButtonText }}</template>
          </retro-sort-modal>
        </div>

        <!-- actions -->
        <div class="w-2/3">
          <div class="font-bold mb-2">
            <div v-if="category.action === retro.actions.MOVE_TO_TRASH">
              <p>These emails will be moved to your Trash Folder.</p>
            </div>
            <div v-else-if="category.action === retro.actions.MOVE_TO_FOLDER">
              <p>These emails will be kept in a new folder.</p>
            </div>
          </div>
          <!-- <div class="mb-2">
            Select an action:
            <retro-actions-dropdown
              :options="retroActionsOptions"
              :defaultOption="category.action"
              v-model="category.action"
            />
          </div> -->
          <div class="text-sm italic">
            <div v-if="category.action === retro.actions.MOVE_TO_TRASH">
              <p>
                Note: emails moved to your Trash Folder will be available for 30 days. InMoat does not permanently
                delete any emails.
              </p>
              <p>If you think you missed something, you can always go to your Trash Folder to easily retrieve it.</p>
            </div>
            <div v-else-if="category.action === retro.actions.MOVE_TO_FOLDER">
              <p>
                Note: emails you keep will be moved to a new sub-folder named @Retro. The folder will be listed under
                the @InMoat folder within your inbox.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- total block -->
      <div>
        <table class="float-right">
          <tbody>
            <tr>
              <td class="font-bold">Sub-total:</td>
              <!-- TODO: deleted or total? edit copy to be clearer? -->
              <td>{{ totalMessages }} {{ totalMessages === 1 ? 'email' : 'emails' }} to be cleaned!</td>
            </tr>
            <tr>
              <td class="font-bold">Savings:</td>
            </tr>
            <tr>
              <td class="font-bold">Time saved:</td>
              <td>{{ timeSaved }}</td>
            </tr>
            <tr>
              <td class="font-bold">Sanity:</td>
              <td>priceless</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- submit button -->
      <div class="mt-6">
        <button
          class="float-right px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
          @click="submitTrashCart"
        >
          Confirm and clear!
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import retro from '@/constants/Retro.js';
import RetroSortModal from '@/components/Retro/RetroSortModal.vue';
// import RetroActionsDropdown from '@/components/Retro/RetroActionsDropdown.vue';
import trashCartIcon from '@/assets/img/trash_cart_color.svg';

export default {
  name: 'RetroTrashCart',
  emits: ['navigate:back', 'submit:trash-cart'],
  components: {
    RetroSortModal,
    // RetroActionsDropdown,
  },
  props: {
    messages: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      retro,
      trashCartIcon,
      categories: {
        kept: {
          name: 'Keeping',
          verb: 'keep',
          messages: [],
          color: '#79CBAB',
          decision: retro.decisions.KEEP,
          action: retro.actions.MOVE_TO_FOLDER,
          isProcessed: true,
          modalVisible: false,
          sortedOutListText: 'Messages to clean out',
          showSortedOutListText: 'Show messages to clean out',
          moveToSortedOutListText: 'Clean out selected',
          moveToMainListText: 'Keep selected',
          sortedOutVerbText: 'cleaning out',
          submitButtonText: 'Save and close',
        },
        deleted: {
          name: 'Cleaning out',
          verb: 'clean',
          messages: [],
          color: '#D0442D',
          decision: retro.decisions.DELETE,
          action: retro.actions.MOVE_TO_TRASH,
          isProcessed: true,
          modalVisible: false,
          sortedOutListText: 'Messages to keep',
          showSortedOutListText: 'Show messages to keep',
          moveToSortedOutListText: 'Keep selected',
          moveToMainListText: 'Clean out selected',
          sortedOutVerbText: 'keeping',
          submitButtonText: 'Save and close',
        },
      },
    };
  },
  computed: {
    isCartEmpty() {
      return this.categories.kept.messages.length <= 0 && this.categories.deleted.messages.length <= 0;
    },

    retroActionsOptions() {
      return {
        MOVE_TO_TRASH: {
          text: 'Move to my Trash',
          value: retro.actions.MOVE_TO_TRASH,
        },
        MOVE_TO_FOLDER: {
          text: 'Move to a folder',
          value: retro.actions.MOVE_TO_FOLDER,
        },
      };
    },

    totalMessages() {
      return this.categories.kept.messages.length + this.categories.deleted.messages.length;
    },

    timeSaved() {
      if (!this.messages) {
        return 0;
      }

      let number = this.totalMessages * 1.17;

      if (number >= 60 * 24) {
        number = number / (60 * 24);
        let days = Math.floor(number);
        let remainder = number - days;
        let hours = Math.floor(remainder * 24);
        return days + ' days, ' + hours + ' hours';
      } else if (number >= 60) {
        number = number / 60;
        let hours = Math.floor(number);
        let remainder = number - hours;
        let minutes = Math.floor(remainder * 60);
        return hours + ' hours, ' + minutes + ' minutes';
      } else {
        let minutes = Math.floor(number);
        return minutes + ' minutes';
      }
    },
  },
  watch: {
    messages: {
      handler(newValue) {
        this.categories.kept.messages = newValue
          .filter((message) => message.isProcessed && message.decision === retro.decisions.KEEP)
          .map((message) => {
            return {
              ...message,
              isInMainList: true,
            };
          });

        this.categories.deleted.messages = newValue
          .filter((message) => message.isProcessed && message.decision === retro.decisions.DELETE)
          .map((message) => {
            return {
              ...message,
              isInMainList: true,
            };
          });
      },
      eager: true,
    },
  },
  methods: {
    // TODO: handle this in modal directly instead of here and in RetroMessages?
    submitMessagesUpdate(updates, defaultDecision) {
      if (defaultDecision === retro.decisions.DELETE) {
        this.$emit('update:messages', { keep: updates.putInSortedOutList, delete: updates.putInMainList });
      } else if (defaultDecision === retro.decisions.KEEP) {
        this.$emit('update:messages', { keep: updates.putInMainList, delete: updates.putInSortedOutList });
      }
    },

    submitTrashCart() {
      this.$emit('submit:trash-cart', {
        kept: this.categories.kept.action,
        keptFolder: '', // TODO: flesh this out?
        deleted: this.categories.deleted.action,
        deletedFolder: '', // TODO: flesh this out?
      });
    },
  },
};
</script>

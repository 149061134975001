<template>
  <div class="search-results-table-header flex flex-wrap align-items-center justify-content-between">
    <div class="left">
      <span class="table-title">Inbox</span>
      <AisRefreshButton />
    </div>
    <div class="center">
      <InlineMessage severity="warn" v-show="!isClassifyFullEnabled" @click="handleUpsellClick" class="upsell-banner"
        >Your plan has limited access to emails and actions. <span class="bold">Upgrade</span> to unlock your full inbox
        email history, plus all premium features.</InlineMessage
      >
    </div>
    <div class="right">
      <SelectAllButton :createBatchTask="createBatchTask" v-if="totalHits" v-show="showSelectAllButton" />
      <div class="vertical-divider" v-show="showSelectAllButton"></div>
      <div class="action-status-container" v-if="showBatchActionList">
        <div class="selected-count">{{ numSelectedEmails }} selected</div>
        <!-- <div class="divider"></div> -->
        <BatchActionList :createBatchTask="createBatchTask" />
        <!-- <div class="divider"></div> -->
      </div>
      <div class="vertical-divider" v-if="showBatchActionList"></div>
      <AisPaginator />
    </div>
  </div>
</template>
<script>
import AisPaginator from '@/components/Ais/AisPaginator.vue';
import BatchActionList from '@/components/Search/BatchActionList.vue';
import AisRefreshButton from '@/components/Ais/AisRefreshButton.vue';
import SelectAllButton from '@/components/Search/SelectAllButton.vue';
import InlineMessage from 'primevue/inlinemessage';
import { useDialog } from 'primevue/usedialog';
import UpsellModal from '@/components/Search/UpsellModal.vue';
import flags from '@/constants/FeatureFlags';

export default {
  name: 'SearchResultsTableHeader',
  components: {
    AisPaginator,
    BatchActionList,
    InlineMessage,
    SelectAllButton,
    AisRefreshButton,
  },
  props: {
    createBatchTask: {
      type: Function,
      default: () => null,
    },
  },
  setup() {
    const dialog = useDialog();
    return { dialog };
  },
  computed: {
    totalHits() {
      return this.$store.state.search.totalHits;
    },
    numSelectedEmails() {
      return this.$store.state.search.userHasSelectedAll
        ? this.$store.state.search.totalHits
        : this.$store.state.search.selectedCurrPageEmails.length;
    },
    isClassifyFullEnabled() {
      return this.$store.getters.isFlagSet(flags.SearchClassifyFull) === true;
    },
    showSelectAllButton() {
      return (
        this.$store.state.search.allCurrPageEmails.length === this.$store.state.search.selectedCurrPageEmails.length
      );
    },
    showBatchActionList() {
      return this.numSelectedEmails > 0;
    },
  },
  methods: {
    handleUpsellClick() {
      this.dialog.open(UpsellModal, {
        data: {
          upsellType: 'default',
        },
        props: {
          dismissableMask: true,
          draggable: false,
          modal: true,
          style: {
            width: '60vw',
          },
        },
      });
    },
  },
};
</script>

<style scoped>
.search-results-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  color: #495057;
}

.search-results-table-header .table-title {
  color: #343a40;
}

.center .upsell-link {
  font-weight: bold;
}

.search-results-table-header .right,
.search-results-table-header .right .action-status-container {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.search-results-table-header .right .action-status-container {
  gap: 1rem;
}

.vertical-divider {
  height: 1rem;
  border-left: 1px solid rgb(222, 226, 230);
}

.left {
  display: flex;
  align-items: center;
}

.upsell-banner {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}
</style>

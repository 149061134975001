<template>
  <Panel class="suggested-contacts-panel">
    <template #header>
      <div class="header">
        <div class="relative">
          <span class="font-bold title">Suggested Contacts</span>
          <HelpTooltip
            position="top"
            message="Here is a list of email addresses that InMoat has identified as possible Trusted Contacts based on your historical email interactions. Any address you approve will be added as a Trusted Contact moving forward."
          />
          <AisRefreshButton class="ais-refresh-button" />
        </div>
        <div>
          <AisPaginator :itemsPerPage="6" />
        </div>
      </div>
    </template>

    <DataView :value="contacts" v-if="contacts.length > 0">
      <template #list="{ data }">
        <div
          class="suggested-contact-card"
          :class="{
            confirmed: confirmedContacts.includes(data.contact_id),
            dismissed: dismissedContacts.includes(data.contact_id),
          }"
        >
          <div class="contact-details">
            <div class="contact-name">
              {{ data.name || data.email_address }}
            </div>
            <div class="contact-email">
              {{ data.email_address }}
            </div>
          </div>
          <div class="actions-list">
            <Button icon="fas fa-user-plus" class="action primary-button" @click="handleConfirmClick(data)" />
            <Button
              icon="fas fa-times"
              class="action danger-button"
              severity="danger"
              @click="handleDismissClick(data)"
            />
          </div>
        </div>
      </template>
    </DataView>

    <div class="empty-state-message" v-if="contacts.length === 0">
      <GenericMessage
        icon="fas fa-user-plus"
        title="You don't have any suggested contacts"
        subtitle="Check back later for more"
      />
    </div>
  </Panel>
</template>

<script>
import Panel from 'primevue/panel';
import Button from 'primevue/button';
import DataView from 'primevue/dataview';
import AisPaginator from '@/components/Ais/AisPaginator';
// import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import GenericMessage from '@/components/Messages/GenericMessage';
import HelpTooltip from '@/components/Icons/HelpTooltip';
import { createWidgetMixin } from 'vue-instantsearch/vue3/es';
import { REFRESHING_AIS } from '@/store/actions/search';
import AisRefreshButton from '@/components/Ais/AisRefreshButton.vue';
import { REFRESH_TRUSTED_CONTACTS_TABLE_AIS } from '@/store/actions/trustedContacts';

const connectRefresh = (renderFn, unmountFn) => () => ({
  init() {
    renderFn({ refresh: {} }, true);
  },

  render({ instantSearchInstance }) {
    const refresh = instantSearchInstance.refresh.bind(instantSearchInstance);
    renderFn({ refresh }, false);
  },

  dispose() {
    unmountFn();
  },
});

export default {
  name: 'SuggestedContactsList',
  components: {
    Panel,
    Button,
    DataView,
    AisPaginator,
    AisRefreshButton,
    GenericMessage,
    HelpTooltip,
  },
  mixins: [createWidgetMixin({ connector: connectRefresh })],
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      confirmedContacts: [],
      dismissedContacts: [],
    };
  },
  methods: {
    refreshAis() {
      this.$store.dispatch(REFRESHING_AIS, true);
      this.$store.dispatch(REFRESH_TRUSTED_CONTACTS_TABLE_AIS);
      this.state.refresh();
    },

    async handleConfirmClick(contact) {
      this.confirmedContacts.push(contact.contact_id);
      await this.confirmSuggested(contact);
      this.refreshAis();
    },

    async handleDismissClick(contact) {
      this.dismissedContacts.push(contact.contact_id);
      await this.dismissSuggested(contact);
      this.refreshAis();
    },

    async confirmSuggested(contact) {
      const confirmedContact = {
        ...contact,
        confirmed: true,
        id: contact.contact_id,
      };

      return this.axios.patch('/dashboard/v2/user-trusted-sources/single', JSON.stringify(confirmedContact));
    },

    async dismissSuggested(contact) {
      const transformedContact = {
        ...contact,
        id: contact.contact_id,
      };

      return this.axios.delete('/dashboard/v2/user-trusted-sources/single', {
        data: JSON.stringify(transformedContact),
      });
    },
  },
};
</script>

<style scoped>
.ais-refresh-button {
  font-size: 0.85rem;
  margin-left: 0.75rem;
}

.suggested-contact-card:last-of-type {
  border: none !important;
}

:deep(.p-panel-content) {
  padding-bottom: 0.5rem !important;
  padding: 0 !important;
}

.suggested-contact-card {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  opacity: 1;
  visibility: visible;
}

.suggested-contact-card.confirmed,
.suggested-contact-card.dismissed {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s linear;
}

.suggested-contact-card.confirmed {
  background-color: #10b981;
  color: #10b981 !important;
}

.suggested-contact-card.confirmed .contact-email {
  color: #10b981 !important;
}

.suggested-contact-card.dismissed {
  background-color: #ef4444;
  color: #ef4444 !important;
}

.suggested-contact-card.dismissed .contact-email {
  color: #ef4444 !important;
}

.suggested-contact-card:first-of-type {
}

.suggested-contact-card .contact-email {
  font-size: 0.85rem;
  color: #6c757d;
}

/* TODO: create component for new buttons */
.p-button.standard-button,
.p-button.danger-button,
.p-button.primary-button {
  background-color: #f8fafc;
  border: 1px solid #dee2e6;
  color: #475569;
}

.p-button.add-contact-button {
  transition-duration: 0s;
}

.p-button.standard-button:hover {
  background-color: #f8fafc;
  color: #475569;
  border: 1px solid #475569;
}

.p-button.danger-button:hover {
  color: #ef4444 !important;
  border-color: #ef4444;
  background-color: #f8fafc;
  background: #f8fafc !important;
}

.p-button.primary-button:hover {
  color: #10b981 !important;
  border-color: #10b981;
  background-color: #f8fafc;
  background: #f8fafc;
}

.p-button.p-button-icon-only {
  width: 2.5rem;
  padding: 0.5rem 0;
}

.actions-list .action:not(:first-of-type) {
  margin-left: 1rem;
}

.suggested-contacts-panel .header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.suggested-contacts-panel .header .title {
}

.suggested-contacts-panel .header .info-icon {
  top: 0;
  position: absolute;
  color: #6c757d;
}

:deep(.generic-message-container .title-container) {
  font-size: 1.5rem;
}

:deep(.generic-message-container .subtitle-container) {
  font-size: 1.25rem;
}

:deep(.generic-message-container .icon-container) {
  font-size: 5rem;
}

.empty-state-message {
  padding-top: 2rem;
  padding-bottom: 4rem;
}
</style>

<style>
.p-tooltip-text {
  width: 300px;
}
</style>

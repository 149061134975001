import { REFRESH_TRUSTED_CONTACTS_TABLE_AIS, SET_TRUSTED_CONTACTS_AIS_REFRESH_FN } from '../actions/trustedContacts';

const state = {
  aisRefreshFn: () => null,
};

const actions = {
  [REFRESH_TRUSTED_CONTACTS_TABLE_AIS]: ({ state }) => {
    state.aisRefreshFn();
  },
};

const mutations = {
  [SET_TRUSTED_CONTACTS_AIS_REFRESH_FN]: (state, aisRefreshFn) => {
    state.aisRefreshFn = aisRefreshFn;
  },
};

export default { state, mutations, actions };

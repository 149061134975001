import { PROVIDER_REQUEST, PROVIDER_ERROR, PROVIDER_SUCCESS } from '../actions/provider';
import { AUTH_LOGOUT } from '../actions/auth';

import axios from 'axios';
const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
});
api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

const state = { status: '', profile: {} };

const getters = {
  getProvider: (state) => state.profile,
  isProviderLoaded: (state) => !!state.profile.name,
};

const actions = {
  [PROVIDER_REQUEST]: ({ commit, dispatch }) => {
    commit(PROVIDER_REQUEST);
    api
      .get('dashboard/provider')
      .then((resp) => {
        commit(PROVIDER_SUCCESS, resp.data);
      })
      .catch(() => {
        commit(PROVIDER_ERROR);
        // if resp is unauthorized, logout, to
        dispatch(AUTH_LOGOUT);
      });
  },
};

const mutations = {
  [PROVIDER_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [PROVIDER_SUCCESS]: (state, resp) => {
    state.status = 'success';
    state['setProvider'] = resp;
  },
  [PROVIDER_ERROR]: (state) => {
    state.status = 'error';
  },
  [AUTH_LOGOUT]: (state) => {
    state.profile = {};
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

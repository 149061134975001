<script lang="jsx">
import Tag from 'primevue/tag';

export default {
  name: 'RefinementList',
  components: Tag,
  props: {
    refinements: {
      type: Array,
      default: () => [],
    },
  },
  render() {
    return this.refinements ? (
      <div class="refinement-list">
        {this.refinements.map(({ name, key }) => (
          <Tag value={name} key={key} severity="success" class="tag" />
        ))}
      </div>
    ) : null;
  },
};
</script>

<style scoped>
.refinement-list > .refinement:not(:last-child) {
  margin-right: 0.5rem;
}

.refinement-list > .refinement {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>

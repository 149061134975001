<template>
  <div class="search-results-table-filters">
    <div class="search-input-row flex h-20 mb-4 pt-8">
      <div class="left">
        <ais-refinement-list
          attribute="tags.id"
          searchable
          :limit="10000"
          :transform-items="transformItems"
          class="mr-2 ais-refinement-list"
        >
          <template v-slot="{ items, refine }">
            <SearchResultsTableDropdown :refine="refine" :options="items" class="search-results-table-dropdown" />
          </template>
        </ais-refinement-list>

        <ais-search-box class="flex-grow" show-loading-indicator>
          <AisDebouncedSearchBox
            :delay="0"
            placeholder="Search for email, tag, or subject"
            class="search-input w-full"
          />
        </ais-search-box>
      </div>

      <div class="right">
        <!-- <TaskStatusPoller :tasks="tasks" /> -->
        <TaskProgressWidget />
      </div>
    </div>
  </div>
</template>

<script>
import { AisSearchBox, AisRefinementList } from 'vue-instantsearch/vue3/es';
import AisDebouncedSearchBox from '@/components/Ais/AisDebouncedSearchBox.vue';
import SearchResultsTableDropdown from '@/components/Search/SearchResultsTableDropdown.vue';
import TaskProgressWidget from '@/components/Search/TaskProgressWidget.vue';
import actions from '@/constants/Actions';

export default {
  name: 'SearchResultsTableFilters',
  components: {
    TaskProgressWidget,
    SearchResultsTableDropdown,
    AisSearchBox,
    AisDebouncedSearchBox,
    AisRefinementList,
  },

  data() {
    return {
      actions: actions,
      tasks: [],
    };
  },
  computed: {
    console() {
      return console;
    },
  },
  methods: {
    transformItems(items) {
      const filterList = this.$store.state.app.filters;
      if (!filterList) return items;

      return items
        .map((item) => {
          return {
            ...item,
            label: filterList[item.value]?.Name,
          };
        })
        .filter((item) => item.label)
        .sort((a, b) => {
          return a.label?.localeCompare(b.label);
        });
    },
  },
};
</script>

<style scoped>
.left {
  width: calc(90% - 375px);
  max-width: calc(90% - 375px);
  display: flex;
}

.left .ais-refinement-list {
  width: 65%;
}

.right {
  display: flex;
  flex-grow: 1;
  justify-content: right;
  align-items: center;
  font-size: 0.75rem;
  position: relative;
}
</style>

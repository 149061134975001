<template>
  <div class="grid grid-cols-4 grid-rows-8 gap-x-4 w-full px-4">
    <retro-banner class="col-span-4 row-span-1 flex flex-row justify-between items-center">
      <div v-if="retroUsesAvailable">
        You can use Retro {{ retroUsesAvailable }} more times before your subscription renews.
      </div>
      <div v-else class="text-xl">Next Retro available on {{ nextRetroAvailableOn }}</div>
      <div>
        <button
          v-if="retroUsesAvailable"
          class="bg-brand_light text-white active:bg-sky-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
          @click="startRetro"
        >
          Use Retro again
        </button>
      </div>
    </retro-banner>

    <retro-banner class="col-span-2 row-span-6">
      <div>
        <div class="text-xl">Your Retro Summary</div>
        <div>
          <div>You started with {{ emailsInInboxStart }} emails in your inbox.</div>
          <div>You cleaned out {{ emailsDeleted }} emails.</div>
          <div>You kept {{ emailsKept }} emails.</div>
          <div>You ended with {{ emailsInInboxEnd }} emails left in your inbox.</div>
          <div>You saved yourself approximately {{ timeSaved }} cleaning out emails!</div>
        </div>
      </div>
    </retro-banner>

    <retro-banner class="col-span-2 row-span-6 col-start-3">
      <div class="text-xl">Want more Retro?</div>
      <div>
        Out of uses, but need to use Retro again before your subscription renews? Just want to stock up for later? Not a
        problem. We also sell Retro uses by themselves, so you can use Retro any time you need a quick tidy-up. Shoot us
        a message—we'd be happy to help.
      </div>
      <div class="flex flex-row justify-center">
        <a
          class="mt-4 bg-brand_light text-white active:bg-sky-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
          href="mailto:support@inmoat.com"
        >
          Get even more Retro!
        </a>
      </div>
    </retro-banner>
  </div>
</template>

<script>
import RetroBanner from '@/components/Retro/RetroBanner.vue';
import { addMinutes, formatDuration, intervalToDuration, intlFormat, parseISO } from 'date-fns';

export default {
  name: 'RetroComplete',
  components: {
    RetroBanner,
  },
  props: {
    color: {
      default: 'light',
      validator: function (value) {
        // The value must match one of these strings
        return ['light', 'dark'].indexOf(value) !== -1;
      },
    },
  },
  emits: ['submit:retro-start'],
  computed: {
    retroUsesAvailable() {
      return this.$store.state.user.profile.RetroUsesAvailable;
    },

    nextRetroAvailableOn() {
      if (!this.$store.state.user.profile.NextRetroUseAvailableOn) {
        return null;
      }

      return intlFormat(parseISO(this.$store.state.user.profile.NextRetroUseAvailableOn), {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },

    emailsInInboxStart() {
      return this.$store.state.user.attributes.retroEmailsTotal;
    },

    emailsInInboxEnd() {
      return this.$store.state.user.attributes.retroKeepAction === 'LEAVE_IN_INBOX'
        ? this.$store.state.user.attributes.retroEmailsKept
        : 0;
    },

    emailsKept() {
      return this.$store.state.user.attributes.retroEmailsKept;
    },

    emailsDeleted() {
      return this.$store.state.user.attributes.retroEmailsDeleted;
    },

    timeSaved() {
      if (!this.emailsInInboxStart) {
        return 0;
      }

      let minutes = this.emailsInInboxStart * 1.17;
      return formatDuration(this.minutesToDuration(minutes), { format: ['days', 'hours', 'minutes'] });
    },
  },
  methods: {
    minutesToDuration(minutes) {
      const epoch = new Date(0);
      const minutesAfterEpoch = addMinutes(epoch, minutes);
      return intervalToDuration({
        start: epoch,
        end: minutesAfterEpoch,
      });
    },

    startRetro() {
      this.$emit('submit:retro-start');
    },
  },
};
</script>

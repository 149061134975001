<template>
  <div class="generic-message-container">
    <div class="icon-container"><i :class="icon"></i></div>
    <div class="title-container">{{ title }}</div>
    <div class="subtitle-container">{{ subtitle }}</div>
  </div>
</template>

<script>
export default {
  name: 'GenericMessage',
  props: {
    icon: { type: [Object, String], default: '' },
    title: { type: [Object, String], default: '' },
    subtitle: { type: [Object, String], default: '' },
  },
};
</script>

<style scoped>
.generic-message-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.icon-container {
  font-size: 8rem;
  color: rgb(16, 185, 129);
  line-height: 1;
  margin-bottom: 1rem;
}

.title-container {
  font-size: 3rem;
  font-weight: bold;
  color: #343a40;
}

.subtitle-container {
  color: #6c757d;
  font-size: 1.5rem;
}
</style>

<template>
  <div class="relative flex flex-col w-full min-w-0">
    <div class="px-4 pb-3 mb-0 border-0 rounded-t">
      <div class="flex flex-row flex-wrap items-center">
        <div class="relative flex-1 flex-grow w-1/2 max-w-full px-4">
          <h3 class="flex-row text-xl font-semibold" :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">
            <slot name="header"></slot>
          </h3>
        </div>
        <div class="flex flex-row flex-wrap items-center space-x-4">
          <label class="flex items-center">
            <input
              type="checkbox"
              class="checked:bg-brand_light checked:hover:bg-brand_light focus:ring-brand_light focus:checked:bg-brand_light"
              v-model="onlyActive"
            />
            <span class="ml-2">Only show active</span>
          </label>

          <button
            @click="expandAll"
            class="px-4 py-2 transition-all duration-150 ease-linear border border-black rounded shadow text-center hover:shadow-md relative"
          >
            <i class="fas fa-expand-alt" />
            Expand all
          </button>
          <button
            @click="collapseAll"
            class="px-4 py-2 transition-all duration-150 ease-linear border border-black rounded shadow text-center hover:shadow-md relative"
          >
            <i class="fas fa-compress-alt" />
            Collapse all
          </button>
        </div>
        <div class="relative flex-none w-full max-w-full px-4 my-3 lg:w-72 lg:my-0">
          <span
            class="absolute z-10 items-center justify-center w-8 h-full py-3 pl-3 text-base font-normal leading-snug text-center bg-transparent rounded text-blueGray-300"
          >
            <i class="fas fa-search"></i>
          </span>
          <input
            type="text"
            v-model="filterText"
            placeholder="Filter by name or category"
            class="relative w-full px-3 py-3 pl-10 text-sm bg-white rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:shadow-outline"
          />
        </div>
      </div>
    </div>
    <div class="px-4">
      <div
        class="relative w-full max-w-full px-4 py-4 mb-2 break-words rounded shadow-lg"
        :class="[color === 'light' ? 'bg-white' : 'bg-brand_dark text-white']"
        v-for="category in categoriesFiltered"
        :key="category.name"
      >
        <!-- category name and accordion button -->
        <h4 class="flex-row w-1/2 text-xl cursor-pointer" @click="category.isExpanded = !category.isExpanded">
          <i class="mr-3 fas fa-chevron-down" v-if="category.isExpanded" />
          <i class="mr-2 fas fa-chevron-right" v-else />
          {{ category.name }}
        </h4>

        <!-- category contents -->
        <div class="flex flex-col divide-y mt-4" v-show="category.isExpanded">
          <div class="flex flex-row py-3" v-for="item in getItemsInCategory(category)" :key="item.ID">
            <!-- filter name -->
            <div class="w-1/6 flex-none flex flex-row items-center" @click="toggleItem(item)">
              <label :for="item.ID + 'toggle'">
                {{ item.Name }}
              </label>
            </div>

            <!-- toggle switch -->
            <div class="w-1/6 flex-none flex flex-row justify-center items-center">
              <toggle-switch
                :inputId="item.ID + 'toggle'"
                :modelValue="item.IsActive"
                @update:modelValue="toggleItem(item)"
              />
            </div>

            <!-- description -->
            <div class="w-5/12 pr-4 flex-none flex flex-row items-center">
              <p
                :class="item.DescriptionExpanded ? 'min-h-[3em]' : 'h-[3em] line-clamp-2'"
                @click="item.DescriptionExpanded = !item.DescriptionExpanded"
              >
                {{ item.Description }}
              </p>
            </div>

            <!-- automations button -->
            <div class="w-3/12 flex-none flex flex-row justify-center items-center" v-if="isAutomationsEnabled">
              <actions-dropdown
                :item="{
                  id: item.ID,
                  name: item.Name,
                  folder: item.UserFolderID,
                  tag: item.TagID,
                  action: item.Action,
                }"
                :disabled="!item.IsActive"
                @select-action="updateFilterAction"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToggleSwitch from '@/components/Buttons/ToggleSwitch.vue';
import ActionsDropdown from '@/components/Dropdowns/ActionsDropdown.vue';
import flags from '@/constants/FeatureFlags';

export default {
  name: 'CardToggleFilters',
  components: {
    ActionsDropdown,
    ToggleSwitch,
  },
  props: {
    color: {
      default: 'light',
      validator: function (value) {
        // The value must match one of these strings
        return ['light', 'dark'].indexOf(value) !== -1;
      },
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    expandByDefault: {
      type: Boolean,
      default: true, // TODO: don't
    },
  },
  emits: ['toggle', 'update-filter-action'],
  data() {
    return {
      filterText: '',
      categories: this.getCategoriesFromItems([], this.items),
      onlyActive: false,
    };
  },
  computed: {
    itemsFiltered() {
      return this.items.filter((item) => {
        return (
          (item.Name.toUpperCase().includes(this.filterText.toUpperCase()) ||
            item.Category.toUpperCase().includes(this.filterText.toUpperCase()) ||
            this.filterText === '') &&
          (item.IsActive || !this.onlyActive)
        );
      });
    },
    categoriesFiltered() {
      return this.getCategoriesFromItems(this.categories, this.itemsFiltered);
    },
    isFilteringEnabled() {
      return this.$store.state.user.profile.IsFilteringOn === true;
    },
    isAutomationsEnabled() {
      return this.$store.getters.isFlagSet(flags.OngoingActions) === true;
    },
  },
  watch: {
    items(newItems) {
      this.categories = this.getCategoriesFromItems(this.categories, newItems);
    },
    onlyActive(newOnlyActive) {
      for (let category of this.categories) {
        category.isExpanded = newOnlyActive || this.expandByDefault;
      }
    },
    filterText(newFilterText) {
      for (let category of this.categories) {
        category.isExpanded = newFilterText !== '' || this.expandByDefault;
      }
    },
  },
  methods: {
    getCategoriesFromItems(oldCategories, items) {
      let newCategories = [];

      for (let item of items) {
        const oldEntry = oldCategories.find(({ name }) => name === item.Category);
        const newEntry = newCategories.find(({ name }) => name === item.Category);
        if (oldEntry && !newEntry) {
          newCategories.push(oldEntry);
        } else if (!oldEntry && !newEntry) {
          newCategories.push({
            name: item.Category,
            displayOrder: item.CategoryDisplayOrder,
            isExpanded: this.expandByDefault,
          });
        }
      }

      return newCategories.sort(this.sortCategories);
    },
    getItemsInCategory(category) {
      return this.itemsFiltered.filter((item) => {
        return item.Category === category.name;
      });
    },
    sortCategories(a, b) {
      let orderA, orderB;

      switch (Math.sign(a.displayOrder)) {
        case 1:
          orderA = Number.MAX_SAFE_INTEGER - a.displayOrder;
          break;
        case -1:
          orderA = Number.MIN_SAFE_INTEGER - a.displayOrder;
          break;
        default:
          orderA = 0;
          break;
      }

      switch (Math.sign(b.displayOrder)) {
        case 1:
          orderB = Number.MAX_SAFE_INTEGER - b.displayOrder;
          break;
        case -1:
          orderB = Number.MIN_SAFE_INTEGER - b.displayOrder;
          break;
        default:
          orderB = 0;
          break;
      }

      if (orderA > orderB) {
        return -1;
      }

      if (orderA < orderB) {
        return 1;
      }

      return 0;
    },
    expandAll() {
      this.categories = this.categories.map((category) => {
        return {
          ...category,
          isExpanded: true,
        };
      });
    },
    collapseAll() {
      this.categories = this.categories.map((category) => {
        return {
          ...category,
          isExpanded: false,
        };
      });
    },
    toggleItem(item) {
      this.$emit('toggle', item);
    },
    updateFilterAction(filterId, action) {
      this.$emit('update-filter-action', filterId, action);
    },
  },
};
</script>

<style scoped>
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<template>
  <div
    class="relative flex flex-col min-w-0 w-full h-5/6 my-3 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-brand_dark text-white']"
  >
    <div class="rounded-t px-4 py-3 border-0 my-auto">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3
            class="font-semibold text-2xl text-center my-2"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
          >
            <slot name="header" />
          </h3>
        </div>
      </div>
      <h3
        class="font-semibold text-5xl text-center my-2"
        :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
      >
        <slot name="description" />
      </h3>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      default: 'light',
      validator: function (value) {
        // The value must match one of these strings
        return ['light', 'dark'].indexOf(value) !== -1;
      },
    },
  },
};
</script>

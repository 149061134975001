<template>
  <span class="p-float-label w-full">
    <MultiSelect
      v-model="selectedOptions"
      :options="options"
      :maxSelectedLabels="3"
      scroll-height="400px"
      optionLabel="label"
      optionValue="value"
      class="w-full"
      id="tag-select"
      display="chip"
      filter
    >
      <!-- TODO: add pt's to match pill style https://primevue.org/multiselect/ -->
      <!-- <template #chip="slotProps">{{ slotProps.value }}</template> -->
    </MultiSelect>
    <label for="tag-select">Filter by tags</label>
  </span>
</template>

<script>
import MultiSelect from 'primevue/multiselect';
import { UPDATE_SELECTED_TAG_IDS } from '@/store/actions/search';

export default {
  name: 'SearchResultsTableDropdown',
  components: { MultiSelect },
  props: {
    onChange: {
      type: Function,
      default: () => null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    refine: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      selectedOptions: [],
    };
  },
  computed: {
    console: () => console, // TODO: deleteme
  },
  watch: {
    selectedOptions: {
      handler: function (newVal, oldVal) {
        this.$store.commit(UPDATE_SELECTED_TAG_IDS, newVal);
        this.onChange(newVal);
        // MultiSelect onChange only gives us an array of selected options,
        // so we have to do this to find the difference
        if (newVal.length > oldVal.length) {
          this.refine(newVal[newVal.length - 1]);
        }

        if (newVal.length < oldVal.length) {
          const removedOption = oldVal
            .filter((x) => !newVal.includes(x))
            .concat(newVal.filter((x) => !oldVal.includes(x)));
          this.refine(removedOption);
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
/* primevue overrides */
.search-results-table-dropdown .p-multiselect.p-focus .p-multiselect-trigger {
  color: #a7f3d0;
}

.search-results-table-dropdown .p-multiselect.p-inputwrapper-filled .p-multiselect-trigger {
  color: #0a7350;
}

.search-results-table-dropdown .p-multiselect.p-inputwrapper-filled:not(:focus) {
  border-color: #909498;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  color: #495057 !important; /* sorry */
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #0a7350 !important; /* sorry */
}

.p-multiselect-panel .p-multiselect-header .p-checkbox {
  pointer-events: none; /* prevent the "select all" checkbox from being clickable */
  cursor: default;
  display: none;
}
</style>

<template>
  <div class="card-inmoat-subapp-container flex w-full" @click="$router.push(actionUrl)">
    <div class="left md:w-1/3">
      <!-- <img alt="subapp header" class="icon-url" :src="iconUrl" /> -->
      <i :class="iconClass"></i>
    </div>

    <div class="right md:w-2/3">
      <div class="text">
        <div class="title">{{ title }}</div>
        <div class="description">{{ description }}</div>
      </div>

      <div class="actions" @click.stop>
        <router-link :to="helpUrl" target="_blank" rel="noopener">
          <Button class="help-link" label="External Link" icon="fas fa-external-link-alt" iconPos="right" link text />
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import Button from 'primevue/button';

export default {
  name: 'CardInmoatSubapp',
  components: { Button },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    actionUrl: {
      type: String,
      default: '',
    },
    helpUrl: {
      type: String,
      default: '',
    },
    iconUrl: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
  },
  methods: {},
};
</script>

<style scoped>
.card-inmoat-subapp-container {
  border-radius: 6px;
  border: 2px solid #ced4da;
  cursor: pointer;
  background-color: #fff;
  padding: 2rem;
}

.card-inmoat-subapp-container:hover {
  border-color: #10b981;
  box-shadow: 0 0 1rem #a7f3d0;
  /* box-shadow: 20px 20px 41px #a7f3d0; */
  transform: scale(1.019);
  transition-duration: 0.2s;
}

.card-inmoat-subapp-container i {
  color: #254275;
  font-size: 7.5rem;
}

.actions {
  justify-content: flex-end;
  display: flex;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left {
  padding-right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 1.75rem;
  font-weight: bold;
}

.description {
  font-size: 1.25rem;
}

.text,
.title {
  margin-bottom: 1rem;
}

.icon-url {
}

.help-link.p-button.p-button-link:enabled:focus {
  border: none;
  box-shadow: none;
}

.help-link.p-button.p-button-link,
.help-link.p-button.p-button-link:hover {
  color: #1b75d0;
  padding: 0;
}

.help-link.p-button.p-button-link:visited {
  color: #551a8b;
}
</style>

import { createStore } from 'vuex';
import user from './modules/user';
import auth from './modules/auth';
import search from './modules/search';
import trustedContacts from './modules/trustedContacts';
import provider from './modules/provider';
import app from './modules/app';

const debug = process.env.NODE_ENV !== 'production';

const store = createStore({
  modules: {
    user,
    auth,
    search,
    trustedContacts,
    provider,
    app,
  },
  strict: debug,
});

export default store;

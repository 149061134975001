<template>
  <div
    class="relative flex flex-col w-full min-w-0 mb-6 break-words rounded shadow-lg"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <div class="px-4 py-3 mb-0 border-0 rounded-t">
      <div class="flex flex-wrap items-center">
        <div class="relative flex-1 flex-grow w-full max-w-full px-4">
          <h3 class="text-2xl font-semibold my-2" :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">
            <slot name="header"></slot>
          </h3>
          <div class="flex-col lg:flex-row">
            <div class="my-3">
              <span
                class="absolute z-10 items-center justify-center w-8 py-3 pl-3 text-base font-normal leading-snug text-center bg-transparent rounded text-blueGray-300"
              >
                <i class="fas fa-search"></i>
              </span>
              <input
                type="text"
                v-model="filterText"
                placeholder="Filter folders by name"
                class="relative w-full px-3 py-3 pl-10 text-sm bg-white rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <div class="hidden md:flex flex-wrap">
        <div
          class="w-1/6 py-3 px-6 text-xs font-semibold text-left uppercase whitespace-normal break-normal add-hyphen-on-break"
        >
          Folder name
        </div>
        <div
          class="w-2/6 py-3 px-6 text-xs font-semibold text-left uppercase whitespace-normal break-normal add-hyphen-on-break"
        >
          Smart Filters
        </div>
        <div
          class="w-2/6 py-3 px-6 text-xs font-semibold text-left uppercase whitespace-normal break-normal add-hyphen-on-break"
        >
          Trusted Contacts
        </div>
        <div
          class="w-1/6 py-3 px-6 text-xs font-semibold text-left uppercase whitespace-normal break-normal add-hyphen-on-break"
        ></div>
      </div>
      <div
        class="flex flex-wrap pb-4 md:pb-0"
        v-for="(folder, index) in foldersFiltered"
        :key="folder.userFolderId"
        :class="index % 2 === 0 ? ' bg-blueGray-50' : ''"
      >
        <div
          class="order-1 w-5/6 md:w-1/6 font-bold py-4 px-6 text-left whitespace-normal break-normal add-hyphen-on-break"
        >
          {{ folder.name }}
        </div>
        <div class="order-3 md:order-2 w-full md:w-2/6 md:p-4 px-6 text-s">
          <div class="flex flex-wrap">
            <div
              v-for="(filter, index) in folder.smartFilters"
              :key="folder.userFolderId + 'filter' + index"
              class="rounded-2xl px-3 py-1 ml-2 text-sm font-bold text-white uppercase"
              :style="'background-color: ' + (filter.Color ? filter.Color : '#0ea4e9')"
            >
              {{ filter.Name }}
            </div>
          </div>
        </div>
        <div class="order-4 md:order-3 w-full md:w-2/6 md:p-4 px-6 text-s">
          <div class="flex flex-wrap">
            <div
              v-for="(contact, index) in folder.trustedContacts"
              :key="folder.userFolderId + 'contact' + index"
              class="rounded-2xl px-3 py-1 ml-2 text-sm font-bold text-white uppercase"
              :style="'background-color: ' + '#0ea4e9'"
            >
              {{ contact.name ? contact.name : contact.username + '@' + contact.domain }}
            </div>
          </div>
        </div>
        <div class="order-2 md:order-4 w-1/6 p-4 px-6 text-right">
          <folder-dropdown @dropdown-edit-folder="showEditModal(folder)" />
        </div>
      </div>
    </div>
    <modal-folder-rule
      v-model="editModalVisible"
      :folders="folders"
      :selectedFolderId="selectedFolderId"
      :smartFilters="smartFilters"
      :trustedContacts="trustedContacts"
      v-bind="$attrs"
    >
      <template v-slot:header>Edit folder rules</template>
    </modal-folder-rule>
  </div>
</template>

<script>
import FolderDropdown from '@/components/Dropdowns/FolderDropdown.vue';
import ModalFolderRule from '@/components/Modals/ModalFolderRule.vue';

export default {
  components: {
    FolderDropdown,
    ModalFolderRule,
  },
  data() {
    return {
      filterText: '',
      editModalVisible: false,
      selectedFolderId: null,
    };
  },
  props: {
    color: {
      default: 'light',
      validator: function (value) {
        // The value must match one of these strings
        return ['light', 'dark'].indexOf(value) !== -1;
      },
    },
    folders: {
      type: Array,
      required: true,
      default: () => [],
    },
    smartFilters: {
      type: Array,
      required: true,
      default: () => [],
    },
    trustedContacts: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    foldersFiltered() {
      return this.folders.filter((folder) => {
        return folder.name.toUpperCase().includes(this.filterText.toUpperCase()) || this.filterText === '';
      });
    },
  },
  methods: {
    showEditModal(folder) {
      this.selectedFolderId = folder.userFolderId;
      this.editModalVisible = true;
    },
  },
};
</script>

<style scoped>
.add-hyphen-on-break {
  hyphens: auto;
}
</style>

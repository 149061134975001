<template>
  <div>
    <retro-banner v-if="isUpgradeNeeded">
      <div class="flex flex-row justify-center text-xl">
        <div>This is a paid feature. Upgrade to use Retro now.</div>
      </div>
    </retro-banner>

    <retro-banner v-else-if="inboxCount > 0">
      <div class="flex flex-row justify-center text-xl">
        <div>You have {{ inboxCount }} emails clogging your inbox. Clean them out.</div>
      </div>
    </retro-banner>

    <div class="flex flex-wrap">
      <div class="w-full px-4 mb-12">
        <div
          class="relative flex flex-col w-full min-w-0 py-6 mb-6 break-words rounded shadow-lg"
          :class="[color === 'light' ? 'bg-white' : 'bg-brand_dark text-white']"
        >
          <div>
            <div class="flex flex-row justify-center">
              <div class="w-1/2 relative">
                <div class="mb-12">
                  <div class="font-bold text-3xl mb-6">Clean your inbox & clear old emails.</div>
                  <div class="text-2xl mb-4">
                    InMoat scans retrospectively and organizes your emails into categories and sub-categories, so it's
                    easily&nbsp;digestible.
                  </div>
                  <div class="text-2xl mb-4">
                    Review and keep only what you need. And clear out everything&nbsp;else.
                  </div>
                  <a class="text-2xl font-bold" href="https://inmoat.com/knowledge-base-article/retro">
                    Learn more about&nbsp;Retro.
                  </a>
                </div>

                <div class="text-center mb-12">
                  <div v-if="isSubscriptionNeeded">
                    <router-link
                      type="button"
                      class="py-2 px-4 bg-brand_success font-bold text-xl text-white"
                      to="/pricing"
                    >
                      Upgrade to use Retro now
                    </router-link>
                  </div>
                  <div v-else-if="isUpgradeNeeded">
                    <button class="py-2 px-4 bg-brand_success font-bold text-xl text-white" @click="showUpgradeModal">
                      Upgrade to use Retro now
                    </button>
                    <div>Or wait till your trial ends.</div>
                  </div>
                  <div v-else>
                    <button class="py-2 px-4 bg-brand_success font-bold text-xl text-white" @click="showConsentModal">
                      Run Retro-scan
                    </button>
                  </div>
                </div>

                <modal-upgrade-subscription v-model="upgradeModalVisible" @refresh:user="refreshUser">
                  <template #intro-text>This is a premium feature.</template>
                  <template #copy>
                    <p class="mb-4">
                      By beginning your subscription, you will end your free trial and upgrade to InMoat's premium plan.
                      Once you've upgraded, you'll be able to start your Retro scan.
                    </p>
                    <p class="mb-4">Upgrade to use Retro now, or wait until your free trial is over.</p>
                    <p>
                      If you'd like to try InMoat's free plan instead, you can also do that anytime before your trial
                      ends.
                      <router-link :to="{ name: 'free' }" class="text-brand_light underline"
                        >Read more about our free plan, then try it out yourself.</router-link
                      >
                    </p>
                  </template>
                </modal-upgrade-subscription>

                <modal-retro-consent v-model="consentModalVisible" @submit:consent="navigateToNextPage">
                  <template #intro-text>Provide consent</template>
                  <template #copy>
                    <p class="mb-4">You're about to start a Retro scan.</p>

                    <p class="mb-4">
                      While your Retro session is in progress, the emails scanned will be stored on our servers. This
                      allows us to sort and present each email with the category it matches best, so you can pick out
                      the email(s) you want and easily clean out the rest.
                    </p>

                    <p class="mb-4">
                      As soon as you complete Retro and your emails are moved out of your inbox, all the emails scanned
                      during this Retro session will immediately be deleted from our servers in their entirety, so the
                      only person who will be able to read your emails is you.
                    </p>

                    <p class="mb-4">
                      By clicking start, you give your permission for InMoat to scan and store your emails until your
                      Retro session is complete.
                    </p>
                  </template>
                </modal-retro-consent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RetroBanner from '@/components/Retro/RetroBanner.vue';
import ModalUpgradeSubscription from '@/components/Modals/ModalUpgradeSubscription.vue';
import ModalRetroConsent from '@/components/Modals/ModalRetroConsent.vue';

export default {
  name: 'RetroLandingPage',
  components: {
    RetroBanner,
    ModalUpgradeSubscription,
    ModalRetroConsent,
  },
  emits: ['navigate:next', 'refresh:user'],
  data() {
    return {
      upgradeModalVisible: false,
      consentModalVisible: false,
    };
  },
  props: {
    inboxCount: {
      type: Number,
      default: 0,
    },
    isSubscriptionNeeded: {
      type: Boolean,
      default: false,
    },
    isUpgradeNeeded: {
      type: Boolean,
      default: false,
    },
    color: {
      default: 'light',
      validator: function (value) {
        // The value must match one of these strings
        return ['light', 'dark'].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    showUpgradeModal() {
      this.upgradeModalVisible = true;
    },

    async refreshUser() {
      this.$emit('refresh:user');
    },

    showConsentModal() {
      this.consentModalVisible = true;
    },

    navigateToNextPage() {
      this.$emit('navigate:next');
    },
  },
};
</script>

<style scoped></style>

<template>
  <Button
    icon="fas fa-user-plus"
    class="add-contact-button"
    label="Add Trusted Contact"
    @click="showAddContactModal"
  ></Button>
</template>

<script>
import ModalAddContact from '@/components/Modals/ModalAddContact';
import { createWidgetMixin } from 'vue-instantsearch/vue3/es';
import { REFRESHING_AIS } from '@/store/actions/search';
import { useDialog } from 'primevue/usedialog';
import Button from 'primevue/button';

const connectRefresh = (renderFn, unmountFn) => () => ({
  init() {
    renderFn({ refresh: {} }, true);
  },

  render({ instantSearchInstance }) {
    const refresh = instantSearchInstance.refresh.bind(instantSearchInstance);
    renderFn({ refresh }, false);
  },

  dispose() {
    unmountFn();
  },
});

export default {
  name: 'AddContactButton',
  components: {
    Button,
  },
  mixins: [createWidgetMixin({ connector: connectRefresh })],
  setup() {
    const dialog = useDialog();
    return { dialog };
  },
  methods: {
    async addContact(contact) {
      const splitEmailAddress = contact.email_address.split('@');

      const transformedContact = {
        ...contact,
        domain: contact.domain || splitEmailAddress[1],
        username: contact.email_address ? splitEmailAddress[0] : null,
      };

      return this.axios.post('/dashboard/v2/user-trusted-sources/single', JSON.stringify(transformedContact));
    },

    refreshAis() {
      this.$store.dispatch(REFRESHING_AIS, true);
      setTimeout(() => {
        this.state.refresh();
      }, 2500);
    },

    showAddContactModal() {
      this.dialog.open(ModalAddContact, {
        props: {
          dismissableMask: true,
          modal: true,
          draggable: false,
          header: 'Add Trusted Contact',
          style: {
            width: '45vw',
            // height: '70vh',
          },
          breakpoints: {
            '960px': '75vw',
            '640px': '90vw',
          },
        },
        onClose: ({ data }) => this.onAddContactModalClose(data),
      });
    },

    async onAddContactModalClose(contact) {
      if (!contact) {
        return;
      }

      await this.addContact(contact);
      this.refreshAis();
    },
  },
};
</script>

<style scoped></style>

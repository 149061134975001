<template>
  <div id="app">
    <router-view />
    <DynamicDialog />
    <ConfirmDialog :draggable="false" style="width: 27vw" />
  </div>
</template>

<script>
import { AUTH_LOGOUT } from '@/store/actions/auth';
import axios from 'axios';
import store from './store';
import DynamicDialog from 'primevue/dynamicdialog';
import ConfirmDialog from 'primevue/confirmdialog';

export default {
  name: 'app',
  components: {
    DynamicDialog,
    ConfirmDialog,
  },
  mounted() {
    axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          // if you ever get an unauthorized, logout the user
          store.dispatch(AUTH_LOGOUT);
          // you can also redirect to /login if needed !
        }
        throw err;
      });
    });
  },
};
</script>

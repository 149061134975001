<template>
  <span class="ais-debounced-search-box p-input-icon-left w-full p-float-label">
    <InputText v-model="query" id="search-input" class="w-full" />
    <i class="fa fa-search" />
    <label for="search-input">{{ placeholder }}</label>
  </span>
</template>

<script>
import { connectSearchBox } from 'instantsearch.js/es/connectors';
import { createWidgetMixin } from 'vue-instantsearch/vue3/es';
import InputText from 'primevue/inputtext';
import { UPDATE_CURRENT_QUERY } from '@/store/actions/search';

export default {
  mixins: [createWidgetMixin({ connector: connectSearchBox })],
  components: { InputText },
  props: {
    delay: {
      type: Number,
      default: 200,
      required: false,
    },
    placeholder: {
      type: String,
      default: 'Search...',
    },
  },
  data() {
    return {
      timerId: null,
      localQuery: '',
    };
  },
  unmounted() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  },
  computed: {
    query: {
      get() {
        return this.localQuery;
      },
      set(val) {
        this.localQuery = val;
        if (this.timerId) {
          clearTimeout(this.timerId);
        }
        this.timerId = setTimeout(() => {
          this.state.refine(this.localQuery);
        }, this.delay);

        this.$store.commit(UPDATE_CURRENT_QUERY, this.localQuery);
      },
    },
  },
};
</script>

<style>
/* primevue overrides */
.ais-debounced-search-box input:focus + i {
  color: #a7f3d0;
}

.ais-debounced-search-box input.p-filled + i {
  color: #0a7350;
}

.ais-debounced-search-box input.p-filled:not(:focus) {
  border-color: #909498;
}

.ais-debounced-search-box.p-float-label > input.p-filled + i + label,
.ais-debounced-search-box.p-float-label > input:focus + i + label {
  left: 0.75rem !important;
}
</style>

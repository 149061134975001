<template>
  <div class="flex flex-wrap">
    <div data-v-step="0" class="w-full px-4 mb-12">
      <card-toggle-filters :items="filtersSorted" @toggle="updateFilter" @update-filter-action="updateFilterAction">
        <template v-slot:header>Select Smart Filters</template>
      </card-toggle-filters>
    </div>
  </div>
</template>
<script>
import CardToggleFilters from '@/components/Cards/CardToggleFilters.vue';

export default {
  name: 'SmartFilters',
  components: {
    CardToggleFilters,
  },
  data() {
    return {
      smartFilters: [],
    };
  },
  computed: {
    filtersSorted() {
      return [...this.smartFilters].sort((a, b) => {
        let aWithoutArticles = a.Name.replace(/The |A |An |\*\*/gi, '');
        let bWithoutArticles = b.Name.replace(/The |A |An |\*\*/gi, '');
        return aWithoutArticles.localeCompare(bWithoutArticles);
      });
    },
  },
  mounted() {
    this.axios.get('/dashboard/user-smart-filters').then((response) => {
      if (response.status !== 200) return;
      this.smartFilters = response.data;
    });
  },
  methods: {
    async updateItem(item, url) {
      const options = {
        method: 'patch',
        data: item,
        transformRequest: [
          (data) => {
            data.IsActive = data.IsActive ? false : true;
            return JSON.stringify(data);
          },
        ],
      };
      this.axios(`/dashboard/${url}`, options)
        .then((response) => {
          if (response.config.url.includes('user-smart-filter')) {
            this.smartFilters = response.data;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data === 'non-free filter selected') {
            alert('Upgrade your account to select this filter.');
          }
        });
    },
    async updateFilter(filter) {
      await this.updateItem(filter, 'user-smart-filter');
    },
    async updateFilterAction(filterId, action) {
      const response = await this.axios.patch(
        '/dashboard/user-smart-filter/' + filterId + '/action',
        JSON.stringify(action),
      );
      this.smartFilters = response.data;
    },
  },
};
</script>

<template>
  <div class="w-full">
    <div class="w-full flex flex-row items-start relative" @mouseleave="dropdownVisible = false">
      <!-- button -->
      <div class="w-full h-full" ref="buttonRef">
        <slot :toggleDropdownFunction="toggleDropdown">
          <button
            class="border border-solid border-blueGray-500 rounded-md px-4 py-2 flex flex-row items-center text-left text-blueGray-500"
            :class="buttonExtraClasses"
            :disabled="disabled"
            @click="toggleDropdown"
          >
            <i class="fas fa-magic mr-3" />
            <span class="line-clamp-2 max-h-[3em]">{{ buttonText }}</span>
          </button>
        </slot>
      </div>

      <!-- options dropdown -->
      <div
        ref="dropdownRef"
        class="z-50 float-left py-2 text-base text-left list-none bg-white rounded shadow-lg min-w-48 absolute"
        :class="{
          hidden: !dropdownVisible,
          block: dropdownVisible,
        }"
      >
        <!-- tag -->
        <button
          class="block w-full px-4 py-2 text-sm font-normal bg-transparent hover:bg-blueGray-200 whitespace-nowrap text-blueGray-700"
          @click="modalsVisible.applyTag = true"
          v-if="isApplyTagActionEnabled"
        >
          Tag
        </button>

        <!-- mark as read -->
        <button
          class="block w-full px-4 py-2 text-sm font-normal bg-transparent hover:bg-blueGray-200 whitespace-nowrap text-blueGray-700"
          @click="markAsRead"
        >
          Mark as read
        </button>

        <!-- move to folder -->
        <button
          class="block w-full px-4 py-2 text-sm font-normal bg-transparent hover:bg-blueGray-200 whitespace-nowrap text-blueGray-700"
          @click="modalsVisible.moveToFolder = true"
        >
          Move to folder...
        </button>

        <!-- archive -->
        <button
          class="block w-full px-4 py-2 text-sm font-normal bg-transparent hover:bg-blueGray-200 whitespace-nowrap text-blueGray-700"
          @click="archive"
        >
          Archive
        </button>

        <!-- move to trash -->
        <button
          class="block w-full px-4 py-2 text-sm font-normal bg-transparent hover:bg-blueGray-200 whitespace-nowrap text-blueGray-700"
          @click="moveToTrash"
        >
          Move to trash
        </button>

        <!-- clear action -->
        <button
          class="block w-full px-4 py-2 text-sm font-normal bg-transparent hover:bg-blueGray-200 whitespace-nowrap text-blueGray-700"
          @click="clearAction"
        >
          No action
        </button>
      </div>
    </div>

    <modal-apply-tag v-model="modalsVisible.applyTag" :item="item" :tags="tags" @apply-tag="applyTag" />

    <modal-move-to-folder
      v-model="modalsVisible.moveToFolder"
      :item="item"
      :folders="folders"
      @move-to-folder="moveToFolder"
    />
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
import ModalApplyTag from '@/components/Modals/ModalApplyTag.vue';
import ModalMoveToFolder from '@/components/Modals/ModalMoveToFolder.vue';
import actions from '@/constants/Actions';
import flags from '@/constants/FeatureFlags';

export default {
  name: 'ActionsDropdown',
  components: { ModalApplyTag, ModalMoveToFolder },
  emits: ['select-action'],
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {
        return {
          id: null,
          name: null,
          folder: null,
          tag: null,
          action: null,
        };
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dropdownVisible: false,
      modalsVisible: {
        applyTag: false,
        moveToFolder: false,
      },
    };
  },
  computed: {
    folders() {
      return this.$store.state.user.folders;
    },

    tags() {
      return this.$store.state.user.tags;
    },

    currentAction() {
      let actionsList = Object.values(actions);
      if (!actionsList.includes(this.item.action)) return '';

      return this.item.action;
    },

    buttonText() {
      switch (this.currentAction) {
        case actions.APPLY_TAG:
          return this.buttonTextApplyTag;
        case actions.MARK_AS_READ:
          return this.buttonTextMarkAsRead;
        case actions.MOVE_TO_FOLDER:
          return this.buttonTextMoveToFolder;
        case actions.ARCHIVE:
          return this.buttonTextArchive;
        case actions.MOVE_TO_TRASH:
          return this.buttonTextMoveToTrash;
        default:
          return this.buttonTextDefault;
      }
    },

    buttonTextDefault() {
      return 'Set up actions...';
    },

    buttonTextApplyTag() {
      let tag = this.tags.find((tag) => tag.id === this.item.tag);
      if (!tag || !tag.name) {
        return 'Applying tag';
      }

      return 'Apply tag ' + tag.name;
    },

    buttonTextMarkAsRead() {
      return 'Mark as read';
    },

    buttonTextMoveToFolder() {
      let folder = this.folders.find((folder) => folder.userFolderId === this.item.folder);
      if (!folder || !folder.name) {
        return 'Move to folder';
      }

      return 'Move to ' + folder.name;
    },

    buttonTextArchive() {
      return 'Archive';
    },

    buttonTextMoveToTrash() {
      return 'Move to trash';
    },

    buttonExtraClasses() {
      let classes = [];

      if (this.disabled) {
        classes.push('cursor-default filter grayscale opacity-50');
      }

      if (this.currentAction) {
        classes.push('font-bold');
      }

      return classes.join(' ');
    },

    isApplyTagActionEnabled() {
      return this.$store.getters.isFlagSet(flags.ActionsApplyTag) === true;
    },
  },
  methods: {
    toggleDropdown() {
      if (this.dropdownVisible) {
        this.dropdownVisible = false;
      } else {
        this.dropdownVisible = true;
        createPopper(this.$refs.buttonRef, this.$refs.dropdownRef, {
          placement: 'bottom-start',
        });
      }
    },

    applyTag(tagId) {
      this.$emit('select-action', this.item.id, { action: actions.APPLY_TAG, tagId: tagId });
    },

    markAsRead() {
      this.$emit('select-action', this.item.id, { action: actions.MARK_AS_READ });
    },

    moveToFolder(folder) {
      this.$emit('select-action', this.item.id, {
        action: actions.MOVE_TO_FOLDER,
        providerFolderId: folder.providerFolderId,
        userFolderId: folder.userFolderId,
      });
    },

    archive() {
      this.$emit('select-action', this.item.id, { action: actions.ARCHIVE });
    },

    moveToTrash() {
      this.$emit('select-action', this.item.id, { action: actions.MOVE_TO_TRASH });
    },

    clearAction() {
      this.$emit('select-action', this.item.id, { action: null });
    },
  },
};
</script>

<style scoped>
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<template>
  <div>
    <retro-category-tile
      class="h-36 md:h-48"
      :themeColor="themeColor"
      :isLoading="isLoading"
      @select:category="modalToggle"
    >
      <template #category-name>{{ category.name }}</template>
      <template #message-count>{{ mainListLength }}</template>
    </retro-category-tile>

    <div
      class="fixed z-50 inset-0 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
      v-show="isModalVisible"
    >
      <div class="relative z-50 w-screen h-screen mx-auto my-auto">
        <!--content-->
        <div
          class="relative flex flex-col w-full h-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none"
        >
          <!--header-->
          <div class="flex flex-col p-5 border-b border-solid rounded-t border-blueGray-200">
            <div class="flex flex-row items-center">
              <div class="flex-grow grid grid-cols-4 grid-rows-1 gap-x-2">
                <!-- category name -->
                <div class="flex flex-row col-span-2">
                  <h3 v-if="!showMainList" class="text-3xl font-semibold text-center">
                    <slot name="header" />—<slot name="sorted-out-list" /> ({{ sortedOutListLength }})
                  </h3>
                  <h3 v-else-if="filterBarOptions.selectedFilterId" class="text-3xl font-semibold text-center">
                    <slot name="header" />—{{ filterBarOptions.selectedFilterName }} ({{ selectedFilterLength }})
                  </h3>
                  <h3 v-else class="text-3xl font-semibold text-center">
                    <slot name="header" /> ({{ mainListLength }})
                  </h3>
                </div>

                <!-- save button -->
                <button
                  class="col-span-1 col-start-3 px-2 py-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none hover:shadow-lg focus:outline-none"
                  :class="unsavedChangesExist ? 'bg-amber-600 active:bg-amber-700' : 'bg-gray-400 cursor-not-allowed'"
                  @click="submitPartialUpdate"
                  v-show="unsavedChangesExist || hasEverSaved"
                  :disabled="!unsavedChangesExist"
                >
                  <span v-if="isSaving">
                    <i class="fas fa-spinner animate-spin" />
                  </span>
                  <span v-else-if="unsavedChangesExist"> <i class="fas fa-exclamation-triangle" /> Save changes </span>
                  <span v-else> <i class="fas fa-save" /> Changes saved </span>
                </button>

                <!-- show other list button -->
                <button
                  class="col-span-1 col-start-4 px-2 py-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-brand_dark active:bg-brand_dark-dark hover:shadow-lg focus:outline-none"
                  @click="showMainList = !showMainList"
                  v-if="!showMainList || (!disableSortedOutList && sortedOutListLength > 0)"
                >
                  <div v-if="showMainList"><slot name="show-sorted-out-list" /> ({{ sortedOutListLength }})</div>
                  <div v-else><slot name="show-main-list" /></div>
                </button>
              </div>

              <!-- close button -->
              <div class="place-items-center ml-4 cursor-pointer" @click="cancelUpdate">
                <i class="fas fa-times w-full text-center" />
              </div>
            </div>

            <!-- category description -->
            <div class="mt-4">
              <slot name="description" />
            </div>
          </div>

          <!--body-->
          <div class="p-6 flex-grow flex flex-col w-full overflow-y-scroll">
            <div class="flex flex-row">
              <!-- move to other list button -->
              <button
                v-if="selectedAtLeastOne"
                class="px-2 py-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-gray-400 active:bg-gray-500 hover:shadow-lg focus:outline-none"
                @click="moveAllCheckedToOtherList"
              >
                <div v-if="isMovingSelected">
                  <i class="fas fa-spinner animate-spin" />
                </div>
                <div v-else-if="showMainList"><slot name="move-to-sorted-out-list" /> ({{ checkedListLength }})</div>
                <div v-else><slot name="move-to-main-list" /> ({{ checkedListLength }})</div>
              </button>

              <div class="flex-grow" />

              <!-- filter bar -->

              <div v-if="showExtendedFilterBar" class="flex flex-row items-center">
                <label for="startDate">Start</label>
                <input
                  id="startDate"
                  type="date"
                  class="leading-4 px-2 py-1 focus-ring-category-color mx-2"
                  :style="cssVariables"
                  @input="updateDateStart"
                />

                <label for="endDate">End</label>
                <input
                  id="endDate"
                  type="date"
                  v-if="showExtendedFilterBar"
                  class="leading-4 px-2 py-1 focus-ring-category-color mx-2"
                  :style="cssVariables"
                  @input="updateDateEnd"
                />
              </div>

              <div class="flex flex-row">
                <input
                  type="text"
                  class="leading-4 px-2 py-1 focus-ring-category-color"
                  :style="cssVariables"
                  v-model="filterBarOptions.text"
                  placeholder="Search by keywords"
                  :size="40"
                />

                <button
                  v-if="showExtendedFilterBar"
                  class="ml-2 px-2 py-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-gray-400 active:bg-gray-500 hover:shadow-lg focus:outline-none"
                  @click="clearFilterOptions"
                  title="Clear filter options"
                >
                  <i class="fas fa-times mr-2" />
                  Clear all filters
                </button>
              </div>

              <button
                class="ml-2 px-2 py-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-gray-400 active:bg-gray-500 hover:shadow-lg focus:outline-none"
                @click="toggleExtendedFilterBar"
                title="More filter options"
              >
                <i class="fas fa-filter mr-2" />
                {{ showExtendedFilterBar ? 'Hide more filter options' : 'Show more filter options' }}
              </button>
            </div>

            <!-- tag bar -->
            <div class="flex flex-row justify-end mt-2" v-if="!disableTags && showExtendedFilterBar">
              <retro-filter-tags
                :filters="filters"
                v-model:filter-id="filterBarOptions.selectedFilterId"
                v-model:filter-name="filterBarOptions.selectedFilterName"
              />
            </div>

            <!-- message table -->
            <div class="w-full overflow-y-scroll mt-2">
              <table class="table-fixed w-full border-collapse">
                <thead class="sticky top-0 bg-white">
                  <tr>
                    <th class="w-1/12"><input type="checkbox" :checked="selectedAll" @change="toggleAllShown" /></th>
                    <th class="w-1/6">
                      <button class="font-bold" @click="setSort(sortableColumns.tag)">
                        Tag
                        <i :class="sortIconClass(sortableColumns.tag)" />
                      </button>
                    </th>
                    <th class="w-1/6">
                      <button class="font-bold" @click="setSort(sortableColumns.sender)">
                        Sender
                        <i :class="sortIconClass(sortableColumns.sender)" />
                      </button>
                    </th>
                    <th>
                      <button class="font-bold" @click="setSort(sortableColumns.subject)">
                        Subject
                        <i :class="sortIconClass(sortableColumns.subject)" />
                      </button>
                    </th>
                    <th class="w-28">
                      <button class="font-bold" @click="setSort(sortableColumns.date)">
                        Date
                        <i :class="sortIconClass(sortableColumns.date)" />
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border-b-2 border-gray-300" colspan="5" />
                  </tr>
                  <tr
                    v-for="(message, index) in messagesShown"
                    :key="'sort-modal-message' + message.id"
                    :class="message.isChecked ? 'message-category-color' : index % 2 ? 'bg-white' : 'bg-gray-100'"
                    :style="cssVariables"
                  >
                    <!-- select -->
                    <td class="text-center">
                      <input
                        type="checkbox"
                        :id="'sort-modal-message' + message.id + '-checkbox'"
                        :checked="message.isChecked"
                        @change="toggleChecked(message.id)"
                      />
                    </td>

                    <!-- tag -->
                    <td class="px-1 flex flex-col items-center">
                      <div
                        class="w-min max-w-full whitespace-nowrap truncate border-solid border-2 border-gray-300 rounded-full py-1 px-2 text-sm"
                        :title="message.filterName"
                      >
                        {{ message.filterName }}
                      </div>
                    </td>

                    <!-- sender -->
                    <td class="truncate px-1" :title="message.sender">
                      {{ message.sender }}
                    </td>

                    <!-- subject -->
                    <td class="truncate px-1" :title="formatMessageContent(message.subject, message.body)">
                      {{ message.subject }}
                    </td>

                    <!-- date -->
                    <td class="whitespace-nowrap text-center px-1" :title="formatDateLong(message.date)">
                      {{ formatDateShort(message.date) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!--footer-->
          <div class="flex flex-col p-6 border-t border-solid rounded-b border-blueGray-200">
            <div class="flex justify-end mb-2 text-lg">
              You're <slot name="sorted-out-verb" /> {{ sortedOutListLength }} of {{ totalLength }} emails.
            </div>
            <div class="flex items-stretch justify-end">
              <!-- submit button -->
              <button
                class="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                type="button"
                @click="submitAllUpdates"
              >
                <i v-if="isClearing" class="fas fa-spinner animate-spin" />
                <slot v-else name="submit-button" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="fixed inset-0 z-40 bg-black opacity-75" v-show="isModalVisible" @click="cancelUpdate"></div>
    </div>
  </div>
</template>

<script>
import { compareDesc, format, isBefore, isAfter, isThisYear, isToday, isValid, parseISO } from 'date-fns'; // TODO: does isValid work for this?
import RetroFilterTags from '@/components/Retro/RetroFilterTags.vue';
import compareAsc from 'date-fns/esm/compareAsc/index.js';
import RetroCategoryTile from '@/components/Retro/RetroCategoryTile.vue';

export default {
  name: 'RetroSortModal',
  emits: ['update:messages'],
  components: {
    RetroCategoryTile,
    RetroFilterTags,
  },
  props: {
    themeColor: {
      type: String,
      required: false,
      default: null,
    },

    category: {
      type: Object,
      default: () => {},
    },

    filters: {
      type: Array,
      required: false,
      default: () => [],
    },

    messages: {
      type: Array,
      required: true,
      default: () => [],
    },

    disableTags: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableSortedOutList: {
      type: Boolean,
      required: false,
      default: false,
    },

    onlyRefreshMessagesWhenVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      isModalVisible: false,
      messagesLocal: this.messages,
      messagesChecked: {},
      messagesToUpdate: {},
      showMainList: true,
      showExtendedFilterBar: false,
      sortColumn: null,
      sortDirection: null,
      hasEverMadeChanges: false,
      hasEverSaved: false,
      isLoading: false,
      isMovingSelected: false,
      isSaving: false,
      isClearing: false,
      filterBarOptions: {
        selectedFilterId: null,
        selectedFilterName: null,
        text: '',
        dateStart: null,
        dateEnd: null,
      },
      sortableColumns: Object.freeze({
        tag: 'TAG',
        sender: 'SENDER',
        subject: 'SUBJECT',
        date: 'DATE',
      }),
      sortDirections: Object.freeze({
        ascending: 'ASCENDING',
        descending: 'DESCENDING',
      }),
    };
  },
  computed: {
    messagesWithUpdates() {
      return this.messagesLocal
        .map((message) => {
          return {
            ...message,
            isChecked: message.id in this.messagesChecked,
            isInMainList: message.id in this.messagesToUpdate ? !message.isInMainList : message.isInMainList,
          };
        })
        .sort((a, b) => this.sortMessages(a, b));
    },

    messagesShown() {
      return this.messagesWithUpdates.filter((message) => {
        // show only the current list
        if (message.isInMainList !== this.showMainList) {
          return false;
        }

        // show only messages with the selected tag
        if (this.filterBarOptions.selectedFilterId && message.filterId !== this.filterBarOptions.selectedFilterId) {
          return false;
        }

        // show only messages matching the input text
        if (
          this.filterBarOptions.text &&
          !message.subject.toLowerCase().includes(this.filterBarOptions.text.toLowerCase()) &&
          !message.sender.toLowerCase().includes(this.filterBarOptions.text.toLowerCase()) &&
          !message.body.toLowerCase().includes(this.filterBarOptions.text.toLowerCase())
        ) {
          return false;
        }

        // show only messages after the start date
        if (isValid(this.filterBarOptions.dateStart) && isBefore(message.date, this.filterBarOptions.dateStart)) {
          return false;
        }

        // show only messages before the end date
        if (isValid(this.filterBarOptions.dateEnd) && isAfter(message.date, this.filterBarOptions.dateEnd)) {
          return false;
        }

        return true;
      });
    },

    totalLength() {
      return this.messagesLocal.length;
    },

    mainListLength() {
      return this.messagesWithUpdates.filter((message) => message.isInMainList).length;
    },

    sortedOutListLength() {
      return this.messagesWithUpdates.filter((message) => !message.isInMainList).length;
    },

    checkedListLength() {
      return Object.keys(this.messagesChecked).length;
    },

    selectedFilterLength() {
      return this.messagesWithUpdates.filter(
        (message) => message.isInMainList && message.filterId === this.filterBarOptions.selectedFilterId,
      ).length;
    },

    selectedAtLeastOne() {
      return this.messagesShown.some((message) => message.id in this.messagesChecked);
    },

    selectedAll() {
      return (
        this.messagesShown.length > 0 &&
        !this.messagesShown.some((message) => message.id in this.messagesChecked === false)
      );
    },

    sortedOutAtLeastOne() {
      return this.sortedOutListLength > 0;
    },

    unsavedChangesCount() {
      return Object.keys(this.messagesToUpdate).length;
    },

    unsavedChangesExist() {
      return this.unsavedChangesCount > 0;
    },

    cssVariables() {
      return {
        '--color': this.themeColor ? this.themeColor : '#0ea4e9',
      };
    },
  },
  watch: {
    messages: {
      handler(newValue) {
        if (!this.isModalVisible && this.onlyRefreshMessagesWhenVisible) return;
        this.refreshMessages(newValue);

        if (this.isSaving) {
          this.clearUpdates();
          this.isSaving = false;
          return;
        }

        if (this.isClearing) {
          this.modalHide();
          this.clearAllChanges();
          this.isClearing = false;
          return;
        }
      },
      deep: true,
    },
  },
  methods: {
    modalToggle() {
      if (this.isModalVisible) {
        this.modalHide();
      } else {
        this.modalShow();
      }
    },

    modalShow() {
      this.isLoading = true;
      this.isModalVisible = true;
      this.refreshMessages(this.messages);
    },

    modalHide() {
      this.isLoading = false;
      this.clearFilterOptions();
      this.showMainList = true;
      this.hasEverMadeChanges = false;
      this.isModalVisible = false;
    },

    refreshMessages(newValue) {
      this.messagesLocal = newValue;
    },

    sortMessages(a, b) {
      if (!this.sortColumn) {
        return compareDesc(a.date, b.date);
      }

      if (this.sortColumn === this.sortableColumns.tag && this.sortDirection === this.sortDirections.ascending) {
        return a.filterName.localeCompare(b.filterName);
      } else if (
        this.sortColumn === this.sortableColumns.tag &&
        this.sortDirection === this.sortDirections.descending
      ) {
        return b.filterName.localeCompare(a.filterName);
      } else if (
        this.sortColumn === this.sortableColumns.sender &&
        this.sortDirection === this.sortDirections.ascending
      ) {
        return a.sender.localeCompare(b.sender);
      } else if (
        this.sortColumn === this.sortableColumns.sender &&
        this.sortDirection === this.sortDirections.descending
      ) {
        return b.sender.localeCompare(a.sender);
      } else if (
        this.sortColumn === this.sortableColumns.subject &&
        this.sortDirection === this.sortDirections.ascending
      ) {
        return a.subject.localeCompare(b.subject);
      } else if (
        this.sortColumn === this.sortableColumns.subject &&
        this.sortDirection === this.sortDirections.descending
      ) {
        return b.subject.localeCompare(a.subject);
      } else if (
        this.sortColumn === this.sortableColumns.date &&
        this.sortDirection === this.sortDirections.ascending
      ) {
        return compareAsc(a.date, b.date);
      } else if (
        this.sortColumn === this.sortableColumns.date &&
        this.sortDirection === this.sortDirections.descending
      ) {
        return compareDesc(a.date, b.date);
      } else {
        return compareDesc(a.date, b.date);
      }
    },

    setSort(column) {
      if (column !== this.sortColumn) {
        this.sortColumn = column;
        this.sortDirection = this.sortDirections.ascending;
        return;
      }

      if (!this.sortDirection) {
        this.sortDirection = this.sortDirections.ascending;
        return;
      }

      if (this.sortDirection === this.sortDirections.ascending) {
        this.sortDirection = this.sortDirections.descending;
        return;
      }

      if (this.sortDirection === this.sortDirections.descending) {
        this.sortColumn = null;
        this.sortDirection = null;
        return;
      }
    },

    sortIconClass(column) {
      if (this.sortColumn === column && this.sortDirection === this.sortDirections.ascending) {
        return 'fas fa-sort-up';
      } else if (this.sortColumn === column && this.sortDirection === this.sortDirections.descending) {
        return 'fas fa-sort-down';
      } else {
        return '';
      }
    },

    clearUpdates() {
      this.messagesToUpdate = {};
    },

    clearAllChanges() {
      this.messagesChecked = {};
      this.messagesToUpdate = {};
    },

    clearFilterOptions() {
      this.filterBarOptions = {
        text: '',
        dateStart: null,
        dateEnd: null,
      };
    },

    toggleExtendedFilterBar() {
      this.showExtendedFilterBar = !this.showExtendedFilterBar;
    },

    updateDateStart(event) {
      this.filterBarOptions.dateStart = parseISO(event.target.value);
    },

    updateDateEnd(event) {
      this.filterBarOptions.dateEnd = parseISO(event.target.value);
    },

    formatDateShort(date) {
      const formatString = isToday(date) ? 'p' : isThisYear(date) ? 'MMM d' : 'P';
      return format(date, formatString);
    },

    formatDateLong(date) {
      return format(date, 'PPPP');
    },

    formatMessageContent(subject, body) {
      return subject + '\n\n' + body;
    },

    toggleChecked(id) {
      if (id in this.messagesChecked) {
        delete this.messagesChecked[id];
      } else {
        this.messagesChecked[id] = null;
      }
    },

    toggleToUpdate(id) {
      if (id in this.messagesToUpdate) {
        delete this.messagesToUpdate[id];
      } else {
        this.messagesToUpdate[id] = null;
      }
    },

    checkAllShown() {
      for (const message of this.messagesShown) {
        this.messagesChecked[message.id] = null;
      }
    },

    uncheckAllShown() {
      for (const message of this.messagesShown) {
        delete this.messagesChecked[message.id];
      }
    },

    toggleAllShown() {
      if (!this.selectedAll) {
        this.checkAllShown();
      } else {
        this.uncheckAllShown();
      }
    },

    moveAllCheckedToOtherList() {
      this.isMovingSelected = true;
      for (const message of this.messagesShown) {
        if (message.id in this.messagesChecked) {
          this.toggleToUpdate(message.id);
          delete this.messagesChecked[message.id];
        }
      }

      this.hasEverMadeChanges = true;
      this.isMovingSelected = false;
    },

    cancelUpdate() {
      if (
        this.unsavedChangesExist &&
        !window.confirm(`You have some unsaved changes, which will be lost if you close this window. Close it anyway?`)
      ) {
        return;
      }

      this.modalHide();
      this.clearAllChanges();
    },

    submitPartialUpdate() {
      if (this.unsavedChangesCount <= 0) {
        return;
      }

      if (this.sortedOutListLength >= this.messagesLocal.length) {
        return this.submitAllUpdates();
      }

      this.isSaving = true;

      let updates = {
        putInMainList: [],
        putInSortedOutList: [],
        markSeen: false,
      };

      const editedMessages = this.messagesWithUpdates.filter((message) => message.id in this.messagesToUpdate);

      for (const message of editedMessages) {
        if (message.isInMainList) {
          updates.putInMainList.push(message.id);
        } else {
          updates.putInSortedOutList.push(message.id);
        }
      }

      this.$emit('update:messages', updates);
      this.hasEverSaved = true;
    },

    submitAllUpdates() {
      if (
        !window.confirm(
          "You're keeping " +
            this.sortedOutListLength +
            ' of ' +
            this.totalLength +
            ' emails from ' +
            this.category.name +
            '. Everything else will be moved to your trash cart. (You can still make changes in your trash cart later.) Proceed?',
        )
      ) {
        return;
      }

      this.isClearing = true;

      let updates = {
        putInMainList: [],
        putInSortedOutList: [],
        markSeen: true,
      };

      const editedMessages = this.messagesWithUpdates.filter(
        (message) => !message.isProcessed || message.id in this.messagesToUpdate,
      );

      for (const message of editedMessages) {
        if (message.isInMainList) {
          updates.putInMainList.push(message.id);
        } else {
          updates.putInSortedOutList.push(message.id);
        }
      }

      this.$emit('update:messages', updates);
    },
  },
};
</script>

<style scoped>
.message-category-color {
  background-color: var(--color);
}

.focus-ring-category-color:focus {
  border-color: var(--color);
  --tw-ring-color: var(--color);
}
</style>

<template>
  <Tag
    :key="key"
    :value="value"
    :style="{ background: color }"
    class="tag-pill"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click="
      {
        $event.stopPropagation();
        handleClick();
      }
    "
  >
    <i class="fa fa-tag"></i>
    <!-- <i :class="hover ? 'hover fa fa-plus' : 'fa fa-tag'"></i> -->
    <span
      class="ml-2"
      :title="name"
      style="max-width: inherit; overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
      >{{ name }}</span
    >
  </Tag>
</template>

<script>
import Tag from 'primevue/tag';

export default {
  name: 'TagPill',
  components: { Tag },
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    key: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'success',
    },
    // currently unused
    handleClick: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>

<style>
.tag-pill {
  /* z-index: 1; must be more than SearchResultsTable for onClick to work */
}

.tag-pill:hover {
  cursor: pointer;
  filter: brightness(90%);
}
</style>

<template>
  <ais-instant-search :search-client="searchClient" :index-name="indexName" class="h-full w-full flex flex-col">
    <SearchResultsTableFilters />
    <div
      class="table-and-stats-container flex flex-1 break-words rounded-md shadow-lg"
      :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
      style="height: calc(100% - 6rem)"
    >
      <div class="mb-0 border-0 rounded-md w-full h-full">
        <div class="relative flex-1 flex-grow w-full max-w-full h-full">
          <ais-state-results class="results-table-wrapper h-full w-full">
            <template v-slot="{ results: { hits, found } }">
              <ais-stats class="h-full">
                <template v-slot="{ nbHits }">
                  <!-- surely vue has a more elegant way to conditionally show components -->
                  <!-- <SearchResultsTableStateMessage type="UPGRADE" v-else-if="!isSearchEnabled" />
              <SearchResultsTableStateMessage type="LOADING" v-if="status === 'stalled'" />
              <SearchResultsTableStateMessage type="ERROR" v-else-if="status === 'error'" />
              <SearchResultsTableStateMessage type="EMPTY" v-else-if="!hits.length" /> -->
                  <SearchResultsTable
                    class="search-results-table h-full"
                    :results="hits"
                    :totalHits="found || nbHits"
                    :getPillColor="getPillColor"
                    :handleTagClick="manuallySelectTag"
                    :createBatchTask="createBatchTask"
                  />
                </template>
              </ais-stats>
            </template>
          </ais-state-results>
        </div>
      </div>
    </div>
  </ais-instant-search>
</template>

<script>
/* eslint-disable no-unused-vars */ // TODO: remove
/* eslint-disable vue/no-unused-components */ // TODO: remove

import TypesenseInstantsearchAdapter from 'typesense-instantsearch-adapter';
import {
  AisInstantSearch,
  AisStats,
  AisHits,
  AisHighlight,
  AisHitsPerPage,
  AisCurrentRefinements,
  AisStateResults,
} from 'vue-instantsearch/vue3/es';
import 'instantsearch.css/themes/reset-min.css';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import TagPillList from '@/components/Search/TagPillList.vue';
import RefinementList from '@/components/Search/RefinementList.vue';
import SearchResultsTable from '@/components/Search/SearchResultsTable.vue';
import SearchResultsTableFilters from '@/components/Search/SearchResultsTableFilters.vue';
import SearchResultsTableStateMessage from '@/components/Search/SearchResultsTableStateMessage.vue';
import flags from '@/constants/FeatureFlags';

export default {
  name: 'CardSearch',
  components: {
    AisInstantSearch,
    AisStats,
    AisHitsPerPage,
    AisHits,
    AisCurrentRefinements,
    AisStateResults,
    AisHighlight,
    DataTable,
    Column,
    Button,
    InputText,
    MultiSelect,
    TagPillList,
    RefinementList,
    SearchResultsTable,
    SearchResultsTableFilters,
    SearchResultsTableStateMessage,
  },
  props: {
    color: {
      default: 'light',
      validator: function (value) {
        // The value must match one of these strings
        return ['light', 'dark'].indexOf(value) !== -1;
      },
    },
    searchApiKey: {
      type: String,
      required: true,
      default: '',
    },
    createBatchTask: {
      type: Function,
      required: true,
      default: () => null,
    },
  },
  data() {
    return {
      typesenseInstantsearchAdapter: null,
      searchClient: null,
      indexName: process.env.VUE_APP_SEARCH_COLLECTION_MESSAGES,
      selectedTags: [],
      pillColorStore: {},
      // 14
      /*
        TODO:
          Account Notices = Red
          Payment Notifications = InMoat Blue (like a light blue)
          Calendar Notices = Orange
          Personal = Light Green
          Appointments = Yellow
          Banking = Bold Green
          Collaboration = Purple
          Email Types = Mustard
          Social Media = Linkedin blue
          Linkedin = Linkedin Blue (Same as Social Media^)
          Five = Pink/Pastel
          Marketing = Light Gray
          Google Notices = Dark Gray
          Work & Professional = Dark Navy
      */
      colorBank: [
        '#b4b4b4',
        '#a9b9b4',
        '#d5bcc0',
        '#d1d12e',
        '#8dccdb',
        '#aec6b3',
        '#b5c3bc',
        '#87cdcd',
        '#ec5353',
        '#93899a',
        '#4ba1d8',
        '#e98339',
        '#f289b3',
        '#aea28e',
      ],
    };
  },
  created() {
    this.typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter({
      server: {
        apiKey: this.searchApiKey,
        nodes: [
          {
            host: process.env.VUE_APP_SEARCH_SERVER_HOST,
            port: process.env.VUE_APP_SEARCH_SERVER_PORT,
            protocol: process.env.VUE_APP_SEARCH_SERVER_PROTOCOL,
          },
        ],
      },
      additionalSearchParameters: {
        preset: 'prioritize_matching_tags',
        filters: 'status="NONE"',
        per_page: 50,
      },
    });

    this.searchClient = this.typesenseInstantsearchAdapter.searchClient;
  },
  computed: {
    isSearchEnabled() {
      return this.$store.getters.isFlagSet(flags.Search) === true;
    },

    console: () => console, // TODO: deleteme
  },
  methods: {
    reshapeRefinements(refinements) {
      return refinements.map(({ label, attribute, type, value, operator }) => ({
        name: label,
        key: [attribute, type, value, operator].join(':'),
      }));
    },
    getPillColor(name) {
      if (!this.pillColorStore[name]) {
        this.pillColorStore[name] = this.colorBank.pop() ?? 'success'; // TODO: fallback color
      }

      return this.pillColorStore[name];
    },
    makeDropdownOptions(items) {
      return items.filter((i) => i.count);
    },
    manuallySelectTag(name) {
      this.selectedTags.push(name);
    },
    onChange(e) {
      this.console.log(e);
    },
    onDropdownChange(selectedOptions) {
      this.selectedTags = selectedOptions;
    },
    performActionOnMessage(messageId, actionParams) {
      this.$emit('perform-action', messageId, actionParams);
    },
  },
};
</script>

<style scoped>
.padded-cells th,
.padded-cells td {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hanging-indent {
  padding-left: 1em;
  text-indent: -1em;
}

.search-results-table {
  /* max-height: 56vh; TODO: FIX HACK */
}

.search-input::placeholder {
  color: #708da9;
}

.search-results-table-dropdown {
  min-width: 240px; /* pegged to primevue's modal width */
}
</style>

<style>
/* these primevue overrides need to be unscoped */
.search-input:focus + i,
.tag-select.p-overlay-open > .p-multiselect-trigger {
  color: #7dcfb6 !important; /* secondary */
}
</style>

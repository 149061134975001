<template>
  <form novalidate>
    <div class="content">
      <Panel class="form-col" :class="{ disabled: isIndividualFormDisabled }">
        <template #header>
          <div class="panel-header">Add an individual</div>
          <div class="horizontal-divider"><div /></div>
        </template>

        <div class="form-content">
          <span class="contact-search-box p-float-label">
            <InputText id="search-input-name" v-model="name" class="w-full" />
            <label for="search-input-name">Name</label>
          </span>
        </div>

        <div class="form-content">
          <span class="contact-search-box p-float-label">
            <InputText
              id="search-input-email"
              v-model="email_address"
              class="w-full"
              :class="{ invalid: !isEmailValid && !isIndividualFormDisabled && email_address !== '' }"
            />
            <label for="search-input-email">Email</label>
          </span>
        </div>
      </Panel>

      <Panel class="form-divider">
        <div class="vertical-divider"></div>
        <div class="centered-text">OR</div>
        <div class="vertical-divider"></div>
      </Panel>

      <Panel class="form-col" :class="{ disabled: isDomainFormDisabled }">
        <template #header>
          <div class="panel-header">
            Add a domain
            <HelpTooltip
              message="This will automatically mark every incoming email from a domain as a trusted contact."
              class="help-tooltip"
            />
          </div>
          <div class="horizontal-divider"><div /></div>
        </template>

        <div class="form-content">
          <span class="contact-search-box p-float-label">
            <InputText
              id="search-input-domain"
              v-model="domain"
              class="w-full"
              :class="{ invalid: !isDomainValid && !isDomainFormDisabled && domain !== '' }"
            />
            <label for="search-input-domain">Domain</label>
          </span>
          <small class="domain-help">eg: inmoat.com</small>
        </div>
      </Panel>
    </div>

    <div class="flex justify-end pt-4">
      <Button
        icon="fas fa-user-plus"
        class="action primary-button"
        label="Add Trusted Contact"
        @click="handleSubmit"
        :disabled="!isFormValid"
      />
    </div>
  </form>
</template>

<script>
import Panel from 'primevue/panel';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { inject } from 'vue';
import HelpTooltip from '@/components/Icons/HelpTooltip';

export default {
  name: 'ModalAddContact',
  components: {
    Panel,
    InputText,
    Button,
    HelpTooltip,
  },
  setup() {
    const ref = inject('dialogRef');
    return {
      ref,
    };
  },
  data() {
    return {
      name: '',
      email_address: '',
      domain: '',
    };
  },
  methods: {
    handleSubmit() {
      this.ref.close(this.$data);
    },
  },
  computed: {
    isFormDirty() {
      return false;
    },

    isIndividualFormDisabled() {
      if (this.domain) {
        return true;
      }

      return false;
    },

    isDomainFormDisabled() {
      if (this.name || this.email_address) {
        return true;
      }

      return false;
    },

    isFormValid() {
      if (!this.isDomainFormDisabled && !this.isIndividualFormDisabled) {
        return false;
      }

      if (this.isDomainFormDisabled) {
        if (!this.isEmailValid || this.email_address === '') {
          return false;
        }
      }

      if (this.isIndividualFormDisabled) {
        if (!this.isDomainValid || this.domain === '') {
          return false;
        }
      }

      return true;
    },

    isEmailValid() {
      const regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/; // eslint-disable-line
      return regex.test(this.email_address);
    },

    isDomainValid() {
      if (this.domain.includes('@')) {
        return false;
      }

      const regex = /([a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z])/; // eslint-disable-line
      return regex.test(this.domain);
    },
  },
};
</script>

<style scoped>
.form-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.form-col {
  width: 40%;
}

.domain-help {
  padding-top: 0.5rem;
  color: #6c757d;
}

.form-col > div:not(:last-of-type) {
  margin-bottom: 2rem;
}

.form-content > .input-action {
  text-transform: capitalize;
}

.contact-search-box {
  width: 100%;
}

.contact-search-box input:focus + i {
  color: #a7f3d0;
}

.contact-search-box input.p-filled + i {
  color: #0a7350;
}

.contact-search-box input.p-filled:not(:focus) {
  border-color: #909498;
}

.form-col.p-panel.disabled {
  opacity: 0.4;
  pointer-events: none;
  transition: opacity 0.2s;
}

.p-inputtext.disabled,
.p-inputtext:disabled {
  opacity: 0.6;
  background-color: #e2e8f0;
  color: #64748b;
  pointer-events: none;
}

.p-inputtext.disabled + label {
  opacity: 0.6;
}

.p-inputtext.invalid {
  border-color: #f87171 !important;
  box-shadow: none !important;
}

.contact-search-box {
  display: block;
}

.contact-search-box.p-float-label > input.p-filled + i + label,
.contact-search-box.p-float-label > input:focus + i + label {
  left: 0.75rem !important;
}

.content {
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.panel-header {
  font-weight: bold;
  margin-right: 1rem;
  text-wrap: nowrap;
  font-size: 1.25rem;
}

.horizontal-divider {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 1px;
}

.horizontal-divider > div {
  width: 100%;
  border-bottom: 1px solid #10b981;
}

.p-panel :deep(.p-panel-header),
.p-panel :deep(.p-panel-content) {
  border: none !important;
  background-color: #fff;
  padding-left: 0;
  padding-right: 0;
}

.p-panel :deep(.p-panel-header) {
  width: 100%;
  padding-bottom: 2.5rem;
}

.p-panel :deep(.p-panel-content) {
  padding-top: 0;
  padding-bottom: 0;
}

.form-divider {
  position: relative;
  color: #6c757d;
}

.form-divider :deep(.p-panel-content) {
  height: 100%;
  color: #6c757d;
}

.form-divider .centered-text {
  margin: 0.5rem 0;
  line-height: 1;
}

.form-divider :deep(.p-toggleable-content) {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.vertical-divider {
  width: calc(50% - 1px);
  border-right: 1px solid rgb(222, 226, 230);
  height: 30%;
}
/* .p-button.primary-button:hover {
  color: #10b981 !important;
  border-color: #10b981;
  background-color: #f8fafc;
  background: #f8fafc;
}

.p-button.primary-button {
  background-color: #f8fafc;
  border: 1px solid #dee2e6;
  color: #475569;
  transition-duration: 0s;
} */

.primary-button.p-button.disabled {
  opacity: 0.4;
}
</style>

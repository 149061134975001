<template>
  <div class="flex flex-wrap">
    <div class="w-full px-4 mb-12">
      <h6 class="mt-0 text-base font-semibold leading-normal underline text-blueGray-800">
        What is the @InMoat folder?
      </h6>
      <p class="mt-0 text-base font-normal text-blueGray-800">
        Your @InMoat folder is where all of your lesser important emails are neatly filed away. To change your important
        email criteria, go to your
        <router-link to="/smart-filters">
          <strong>Smart Filters</strong>
        </router-link>
        and change your selection.
      </p>
    </div>

    <div class="w-full px-4 mb-12">
      <h6 class="mt-0 text-base font-semibold leading-normal underline text-blueGray-800">
        Where is my @InMoat folder?
      </h6>
      <p class="mt-0 text-base font-normal text-blueGray-800">
        The @InMoat folder can be located directly in your inbox. To view your @InMoat folder, go to the sidebar menu of
        your inbox or
        <a :href="links[this.$store.state.provider.setProvider]" rel="noopener noreferrer" target="_blank">
          <strong>click here</strong> </a
        >.
      </p>
    </div>

    <div class="w-full px-4 mb-12">
      <img
        :src="gifs[this.$store.state.provider.setProvider]"
        class="w-1/2"
        alt="InMoat loading symbol"
        v-show="this.$store.state.provider.setProvider"
      />
    </div>
  </div>
</template>
<script>
import gmailGif from '@/assets/img/inmoat_folder_gmail.gif';
import outlookGif from '@/assets/img/inmoat_folder_outlook.gif';

export default {
  data() {
    return {
      links: {
        GOOGLE: 'https://mail.google.com/mail/#label/@InMoat',
        MICROSOFT: 'https://outlook.live.com/mail/0',
      },
      gifs: {
        GOOGLE: gmailGif,
        MICROSOFT: outlookGif,
      },
    };
  },
};
</script>

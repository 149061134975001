<template>
  <div class="flex flex-wrap">
    <div class="w-full px-4 mb-12">
      <div
        class="relative flex flex-col w-full min-w-0 py-24 mb-6 break-words rounded shadow-lg"
        :class="[color === 'light' ? 'bg-white' : 'bg-brand_dark text-white']"
      >
        <div class="flex flex-col items-center justify-center">
          <img src="@/assets/img/inmoat.gif" class="mx-auto" alt="InMoat loading symbol" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RetroLoading',
  props: {
    color: {
      default: 'light',
      validator: function (value) {
        // The value must match one of these strings
        return ['light', 'dark'].indexOf(value) !== -1;
      },
    },
  },
};
</script>

<style scoped></style>

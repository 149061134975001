<template>
  <div class="flex flex-wrap">
    <div class="w-full px-4 mb-12 flex flex-row">
      <div class="flex-grow">
        <p class="mt-0 text-base font-normal text-blueGray-800">Here's what's clogging your inbox.</p>
        <p class="mt-0 text-base font-normal text-blueGray-800">
          Review and keep what you want. We'll clear the rest for you.
        </p>
      </div>
      <div class="w-1/5 flex flex-col items-end">
        <button class="mb-2" @click="$emit('navigate:trash-cart')">
          <img :src="trashCartIconGrayscale" alt="trash cart" />
        </button>
        <div>Keeping: {{ keptList.length }}</div>
        <div>Cleaning out: {{ deletedList.length }}</div>
      </div>
    </div>
    <div class="w-full px-4 mb-12">
      <div
        class="relative flex flex-col w-full min-w-0 py-6 mb-6 break-words rounded shadow-lg"
        :class="[color === 'light' ? 'bg-white' : 'bg-brand_dark text-white']"
      >
        <div>
          <div v-if="!allCategoriesComplete" class="flex flex-col">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-2 px-6">
              <div v-for="category in categoriesShown" :key="'messages-category' + category.id">
                <retro-sort-modal
                  :category="category"
                  :filters="category.filters"
                  :messages="category.messages"
                  :themeColor="category.color"
                  @update:messages="submitMessagesUpdate($event, retro.decisions.DELETE)"
                >
                  <template v-slot:header>{{ category.name }}</template>
                  <template v-slot:sorted-out-list>Kept messages</template>
                  <template v-slot:description>{{ category.description }}</template>
                  <template v-slot:show-sorted-out-list>Show kept messages</template>
                  <template v-slot:show-main-list>Back</template>
                  <template v-slot:move-to-sorted-out-list>Keep selected</template>
                  <template v-slot:move-to-main-list>Unkeep selected</template>
                  <template v-slot:sorted-out-verb>keeping</template>
                  <template v-slot:cancel-button> Cancel </template>
                  <template v-slot:submit-button>
                    <div class="flex flex-row items-center">
                      <img :src="trashCartIconWhite" class="w-6 mr-2" alt="trash cart" />
                      Clear everything else
                    </div>
                  </template>
                </retro-sort-modal>
              </div>
            </div>

            <div class="self-center mt-12 flex flex-col justify-center">
              <div>Have an idea for a new category?</div>
              <a
                class="text-center px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                type="button"
                href="mailto:retro@inmoat.com"
              >
                Tell us!
              </a>
            </div>
          </div>

          <div v-else class="flex flex-col items-center mt-4">
            <div>You're all set.</div>
            <div>Review your trash cart.</div>
            <div class="mt-4">
              <button @click="$emit('navigate:trash-cart')">
                <img :src="trashCartIconGrayscale" alt="trash cart" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import retro from '@/constants/Retro.js';
import RetroSortModal from '@/components/Retro/RetroSortModal.vue';
import trashCartIconWhite from '@/assets/img/trash_cart_white.svg';
import trashCartIconGrayscale from '@/assets/img/trash_cart_grayscale.svg';

export default {
  name: 'RetroMessages',
  emits: ['update:messages', 'navigate:trash-cart'],
  components: {
    RetroSortModal,
  },
  props: {
    categories: {
      type: Array,
      default: () => [],
    },

    messages: {
      type: Array,
      default: () => [],
    },

    color: {
      default: 'light',
      validator: function (value) {
        // The value must match one of these strings
        return ['light', 'dark'].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      retro,
      trashCartIconWhite: trashCartIconWhite,
      trashCartIconGrayscale: trashCartIconGrayscale,
      categoriesWithMessages: [],
    };
  },
  computed: {
    keptList() {
      return this.messages.filter((message) => message.isProcessed && message.decision === retro.decisions.KEEP);
    },

    deletedList() {
      return this.messages.filter((message) => message.isProcessed && message.decision === retro.decisions.DELETE);
    },

    categoriesShown() {
      return this.categoriesWithMessages.filter((category) => !category.isProcessed);
    },

    allCategoriesComplete() {
      return this.categoriesShown.length <= 0;
    },
  },
  watch: {
    messages: {
      handler(newValue) {
        if (newValue.length <= 0) {
          return;
        }

        this.mapMessagesToCategories();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    mapMessagesToCategories() {
      if (!this.categories || this.categories.length <= 0) {
        return;
      }

      let categoryMap = Object.fromEntries(
        this.categories.map((category) => {
          const filterMessageCounts = category.filters
            ? Object.fromEntries(category.filters.map((filter) => [filter.ID, 0]))
            : null;

          return [
            category.id,
            {
              ...category,
              messages: [],
              isProcessed: true,
              filterMessageCounts: filterMessageCounts,
            },
          ];
        }),
      );

      for (const message of this.messages) {
        let category = categoryMap[message.categoryId];
        if (!category) {
          category = categoryMap[0];
        }

        if (!category.filterMessageCounts || !Object.hasOwn(category.filterMessageCounts, message.filterId)) {
          category = categoryMap[0];
        }

        category.messages.push({
          ...message,
          isInMainList: message.decision === retro.decisions.DELETE,
        });

        if (category.filterMessageCounts) {
          category.filterMessageCounts[message.filterId]++;
        }

        if (category.isProcessed && !message.isProcessed) {
          category.isProcessed = false;
        }
      }

      this.categoriesWithMessages = Object.values(categoryMap)
        .map(({ filters, filterMessageCounts, ...category }) => {
          const filtersWithMessages = filters ? filters.filter((filter) => filterMessageCounts[filter.ID] > 0) : null;

          return {
            ...category,
            filters: filtersWithMessages,
          };
        })
        .sort((a, b) => {
          if (a.id === 0) return 1;
          if (b.id === 0) return -1;
          return b.messages.length - a.messages.length;
        });
    },

    submitMessagesUpdate(updates, defaultDecision) {
      if (defaultDecision === retro.decisions.DELETE) {
        this.$emit('update:messages', {
          keep: updates.putInSortedOutList,
          delete: updates.putInMainList,
          ...updates,
        });
      } else if (defaultDecision === retro.decisions.KEEP) {
        this.$emit('update:messages', {
          keep: updates.putInMainList,
          delete: updates.putInSortedOutList,
          ...updates,
        });
      }
    },
  },
};
</script>

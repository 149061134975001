<template>
  <div :class="getContainerClass()" v-show="batchTasks.length">
    <div class="header" @click="toggleVisible" v-click-outside="onClickOutside">
      <div class="title left">Tasks</div>
      <div class="right">
        <div class="progress-bar-container total" v-show="batchTasksTotal > 0">
          <ProgressBar
            :value="computeProgress(batchTasksProgress)"
            :style="{ height: '5px' }"
            :show-value="false"
            class="progress-bar"
            :pt="{
              value: {
                style: {
                  background: getTaskStatusDisplayConfig(batchTasksProgress).color,
                },
              },
            }"
          />
          <i
            :class="getTaskStatusDisplayConfig(batchTasksProgress).icon"
            :style="{ color: getTaskStatusDisplayConfig(batchTasksProgress).color }"
          />
        </div>
        <div class="clickable-icon-with-background">
          <span class="fa-stack fa-1x" style="vertical-align: top">
            <i class="fas fa-circle fa-stack-2x background"></i>
            <i :class="getVisibleIcon()"></i>
          </span>
        </div>
      </div>
    </div>

    <div class="task-progress-list-container" v-show="visible">
      <ul class="task-progress-list">
        <li v-for="task in batchTasks" :key="task.id" class="task-progress-list-item">
          <div class="task-name">{{ makeActionDisplayName(task) }}</div>
          <div class="task-total-emails">{{ task.total }} emails</div>
          <div class="progress-bar-container">
            <ProgressBar
              :value="computeProgress(task)"
              :style="{ height: '5px' }"
              :show-value="false"
              class="progress-bar"
              :pt="{
                value: {
                  style: {
                    background: getTaskStatusDisplayConfig(task).color,
                  },
                },
              }"
            />
            <i
              :class="getTaskStatusDisplayConfig(task).icon"
              :style="{ color: getTaskStatusDisplayConfig(task).color }"
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ProgressBar from 'primevue/progressbar';
import { REQUEST_GET_BATCH_TASKS } from '@/store/actions/search';
import vClickOutside from 'click-outside-vue3';

export default {
  name: 'TaskProgressWidget',
  components: {
    ProgressBar,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      tasks: [],
      maxTasks: 25,
      pollerIntervalMs: 30000,
      isPollerRunning: true,
      pollerId: null,
      visible: false,
      // TODO: get from server or env config
      batchTaskStatusDisplayConfig: {
        IN_PROGRESS: {
          icon: 'fas fa-spinner fa-spin',
          color: 'rgb(16, 185, 129)',
        },
        COMPLETE: {
          icon: 'fas fa-check-circle',
          color: '#0a7350',
        },
        ERROR: {
          icon: 'fas fa-exclamation-circle',
          color: '#e24c4c',
        },
        UNKNOWN: {
          icon: 'fas fa-question',
          color: '#e5e7eb',
        },
      },
    };
  },
  mounted() {
    this.startPoller();
  },
  computed: {
    batchTasks: {
      get() {
        return this.$store.state.search.batchTasks;
      },
    },
    // better way to compute batch tasks total progress?
    batchTasksTotal() {
      return this.batchTasks.reduce((acc, task) => {
        acc += task.total;
        return acc;
      }, 0);
    },
    batchTasksCompleted() {
      return this.batchTasks.reduce((acc, task) => {
        acc += task.completed;
        return acc;
      }, 0);
    },
    batchTasksProgress() {
      if (this.batchTasks.length === 0) {
        return {};
      }

      const progress = { total: this.batchTasksTotal, completed: this.batchTasksCompleted };
      const computedProgress = this.computeProgress(progress);

      return {
        ...progress,
        status: computedProgress === 100 ? 'COMPLETE' : 'IN_PROGRESS',
      };
    },
    console() {
      return console;
    },
  },
  beforeUnmount() {
    clearInterval(this.pollerId);
  },
  methods: {
    onClickOutside() {
      this.visible = false;
    },
    computeProgress(task) {
      return Math.ceil(((task.completed + 1) / (task.total + 1)) * 100);
    },
    makeActionDisplayName(task) {
      switch (task.action) {
        case 'APPLY_TAG':
          return 'Apply Tag';
        case 'MOVE_TO_FOLDER':
          return 'Move to Folder';
        case 'MARK_AS_READ':
          return 'Mark as Read';
        case 'ARCHIVE':
          return 'Move to Archive';
        case 'MOVE_TO_TRASH':
          return 'Move to Trash';
        default:
          return null;
      }
    },
    getTaskStatusDisplayConfig(task) {
      return this.batchTaskStatusDisplayConfig[task.status] || this.batchTaskStatusDisplayConfig.UNKNOWN;
    },
    toggleVisible() {
      this.visible = !this.visible;
    },
    getVisibleIcon() {
      return 'fas fa-stack-1x ' + (this.visible ? 'fas fa-chevron-up' : 'fas fa-chevron-down');
    },
    getContainerClass() {
      return 'task-progress-widget-container ' + (this.visible ? 'open' : 'closed');
    },
    async startPoller() {
      this.pollerId = setInterval(async () => {
        this.$store.dispatch(REQUEST_GET_BATCH_TASKS);
      }, this.pollerIntervalMs);
    },
  },
};
</script>

<style scoped>
.task-progress-widget-container {
  position: absolute;
  top: 0;
  right: 0;
  min-height: 36px; /* table filter input box height */
  width: 375px;
  padding: 0rem 1rem;
  background-color: white;
  z-index: 3;
  border-radius: 6px;
  border: 1px solid #ced4da;
  cursor: pointer;
}

.task-progress-widget-container:hover {
  border-color: #10b981;
}

.task-progress-widget-container.open:hover {
  box-shadow: 0 0 0 0.2rem #a7f3d0;
}

.task-progress-widget-container.open {
  padding-bottom: 1rem;
}

.task-progress-widget-container.open .header {
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 1rem;
}

.task-progress-list-container {
  max-height: 400px;
  overflow-y: scroll;

  /* get the scrollbar where we want it */
  margin-left: -1rem;
  margin-right: -1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.task-progress-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.task-progress-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  min-height: 36px; /* table filter input box height */
  /* border-bottom: 1px solid #e5e7eb; */
}
.title {
  font-size: 12px;
  font-weight: bold;
}

.task-name {
  width: 30%;
  font-size: 10px;
}

.task-total-emails {
  width: 30%;
  font-size: 10px;
  padding-left: 0.5rem;
}

.progress-bar-container {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.progress-bar-container.total {
  width: 75%;
}

.progress-bar {
  width: 100%;
  margin-right: 5px;
}

.clickable-icon-with-background {
  cursor: pointer;
  margin-right: -0.7rem; /* line up icons */
}
.clickable-icon-with-background i {
  transition: all 0.1s ease;
}

.clickable-icon-with-background .background {
  color: transparent;
}

.clickable-icon-with-background:hover .background {
  color: #f0fdfa;
}

.clickable-icon-with-background:hover .fa-times {
  color: #0a7350;
}
</style>

import { FILTERS_REQUEST, FILTERS_REFRESH, FILTERS_SUCCESS, FILTERS_ERROR } from '../actions/app';
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
});

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

const state = { status: { filters: null }, filters: null };

const actions = {
  [FILTERS_REQUEST]: ({ commit }) => {
    commit(FILTERS_REQUEST);

    const userPromise = api
      .get('dashboard/filters')
      .then((resp) => {
        commit(FILTERS_SUCCESS, resp.data);
      })
      .catch(() => {
        commit(FILTERS_ERROR);
      });

    return Promise.allSettled([userPromise]);
  },
  [FILTERS_REFRESH]: ({ dispatch }) => {
    if (!state.status.filters || state.status.filters === 'error') return dispatch(FILTERS_REQUEST);
    if (!state.filters) return dispatch(FILTERS_REQUEST);
  },
};

const mutations = {
  [FILTERS_REQUEST]: (state) => {
    state.status.filters = 'loading';
  },
  [FILTERS_SUCCESS]: (state, resp) => {
    state.status.filters = 'success';
    state.filters = resp;
    // In Vue 2 the below .set() was needed now, the above is preferred method
    // Vue.set(state, "profile", resp);
  },
  [FILTERS_ERROR]: (state) => {
    state.status.filters = 'error';
  },
};

export default {
  state,
  actions,
  mutations,
};

import { createWebHistory, createRouter } from 'vue-router';
import store from '../store';

// layouts

import Admin from '@/layouts/Admin.vue';
import Auth from '@/layouts/Auth.vue';
import Pricing from '@/layouts/Pricing.vue';

// views for Admin layout

import Insights from '@/views/admin/Insights.vue';
import Search from '@/views/admin/Search.vue';
import Settings from '@/views/admin/Settings.vue';
import TrustedContacts from '@/views/admin/TrustedContacts.vue';
import SmartFilters from '@/views/admin/SmartFilters.vue';
import Rules from '@/views/admin/Rules.vue';
import Retro from '@/views/admin/Retro.vue';
import InMoatFolder from '@/views/admin/InMoatFolder.vue';
import MoatSpace from '@/views/admin/MoatSpace.vue';
// views for Auth layout

import Login from '@/views/auth/Login.vue';

// views for Pricing layout

import PricingTable from '@/views/pricing/PricingTable.vue';

import { AUTH_LOGOUT, AUTH_REFRESH, FETCH_TOKEN } from '@/store/actions/auth';
import { USER_REQUEST, USER_REFRESH } from '@/store/actions/user';
import { FILTERS_REFRESH } from '@/store/actions/app';
import { PROVIDER_REQUEST } from '@/store/actions/provider';

import flags from '@/constants/FeatureFlags';

const routes = [
  {
    path: '/',
    redirect: '/',
    component: Admin,
    children: [
      {
        path: '/',
        name: 'Home',
        component: MoatSpace,
        meta: { requiresAuth: true, requiresAnySubscription: true },
      },
      {
        path: '/insights',
        name: 'Insights',
        component: Insights,
        meta: { requiresAuth: true, requiresAnySubscription: true },
      },
      {
        path: '/search',
        name: 'Search',
        component: Search,
        meta: { requiresAuth: true, requiresAnySubscription: true, requiresFlag: flags.Search },
      },
      {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: { requiresAuth: true, requiresAnySubscription: true },
      },
      {
        path: '/trusted-contacts',
        name: 'Trusted Contacts',
        component: TrustedContacts,
        meta: { requiresAuth: true, requiresAnySubscription: true },
      },
      {
        path: '/smart-filters',
        name: 'Smart Filters',
        component: SmartFilters,
        meta: { requiresAuth: true, requiresAnySubscription: true },
      },
      {
        path: '/rules',
        name: 'Rules',
        component: Rules,
        meta: { requiresAuth: true, requiresAnySubscription: true, requiresFlag: flags.Rules },
      },
      {
        path: '/retro',
        name: 'Retro',
        component: Retro,
        meta: { requiresAuth: true, requiresAnySubscription: true, requiresFlag: flags.Retro },
      },
      {
        path: '/my-@inmoat-folder',
        name: 'InMoat Folder',
        component: InMoatFolder,
        meta: { requiresAuth: true, requiresAnySubscription: true },
      },
    ],
  },
  {
    path: '/auth',
    redirect: '/auth/login',
    component: Auth,
    children: [
      {
        path: '/auth/login',
        component: Login,
        meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing,
    children: [
      {
        path: '',
        component: PricingTable,
        meta: {
          requiresAuth: true,
          requiresNoSubscription: true,
        },
      },
    ],
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from) => {
  await store.dispatch(AUTH_REFRESH);

  if (to.matched.some((record) => record.meta.requiresAuth) && !store.getters.isAuthenticated) {
    const authenticationWorked = await authenticateUser();

    if (!authenticationWorked) {
      await store.dispatch(AUTH_LOGOUT);

      let query = getQueryParams();
      query.redirect = to.path;

      return {
        path: '/auth/login',
        query: query,
      };
    }
  }

  await store.dispatch(USER_REFRESH);
  await store.dispatch(FILTERS_REFRESH);
  const userHasActiveSubscription = hasActiveSubscription(store.state.user);

  if (to.matched.some((record) => record.meta.requiresFlag) && !store.getters.isFlagSet(to.meta.requiresFlag)) {
    return {
      path: from.path,
    };
  }

  if (to.matched.some((record) => record.meta.requiresNoSubscription) && userHasActiveSubscription) {
    return {
      path: from.path,
    };
  }

  if (to.matched.some((record) => record.meta.requiresAnySubscription && !userHasActiveSubscription)) {
    return {
      path: '/pricing',
    };
  }
});

router.afterEach((to, from, failure) => {
  if (failure) {
    return;
  }

  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    return;
  }

  if (!store.getters.isAuthenticated) {
    return;
  }

  const user = store.state.user.profile;
  const attributes = store.state.user.attributes;

  window.FS('setIdentity', {
    uid: user.ID,
    properties: {
      displayName: user.Name,
      email: user.Email,
      environment: process.env.VUE_APP_ENVIRONMENT,
      createdAt: user.CreatedOnUnix,
      lastLoggedInAt: user.LastLoginDateUnix,
      isFilteringOn: user.IsFilteringPaused == null ? user.IsFilteringPaused : !user.IsFilteringPaused,
      isAdmin: user.IsAdmin,
      isDev: user.IsDev,
      isBeta: user.IsBeta,
      hasSubscription: attributes.hasSubscription,
      subscriptionIsActive: attributes.subscriptionIsActive,
      subscriptionTier: attributes.subscriptionTier,
      filtersSelectedCount: attributes.filtersSelectedCount,
    },
  });
});

function getQueryParams() {
  const params = new URLSearchParams(window.location.search);

  let query = {};

  for (const [key, value] of params) {
    query[key] = value;
  }

  return query;
}

async function authenticateUser() {
  const sessionId = sessionStorage.getItem('id');

  if (!sessionId) {
    return false;
  }

  const params = new URLSearchParams();
  params.set('session', sessionId);

  try {
    await store.dispatch(FETCH_TOKEN, params);
  } catch (e) {
    return false;
  }

  try {
    await Promise.allSettled([store.dispatch(USER_REQUEST), store.dispatch(PROVIDER_REQUEST)]);
  } catch (e) {
    return false;
  }

  return true;
}

function hasActiveSubscription(user) {
  return user.attributes.hasSubscription && user.attributes.subscriptionIsActive;
}

export default router;

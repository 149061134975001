<template>
  <div class="flex">
    <!-- <sidebar v-if="showSidebar" /> -->
    <SidebarCollapsible v-if="showSidebar" />
    <div class="h-screen flex flex-col w-full bg-blueGray-100">
      <admin-navbar class="flex h-20 pr-4 pl-4" />
      <router-view class="flex overflow-auto px-8" style="height: calc(100vh - 10rem)" />
      <footer-admin class="flex h-20 pr-4 pl-4" />
    </div>
  </div>
</template>
<script>
import AdminNavbar from '@/components/Navbars/AdminNavbar.vue';
// import Sidebar from '@/components/Sidebar/Sidebar.vue';
import SidebarCollapsible from '@/components/Sidebar/SidebarCollapsible.vue';
import FooterAdmin from '@/components/Footers/FooterAdmin.vue';
import store from '@/store';
import { USER_REQUEST } from '@/store/actions/user';
import { PROVIDER_REQUEST } from '@/store/actions/provider';

export default {
  name: 'admin-layout',
  components: {
    AdminNavbar,
    // Sidebar,
    SidebarCollapsible,
    FooterAdmin,
  },
  data() {
    return {
      enableAlertFilteringAlert: true,
    };
  },
  computed: {
    openFilteringAlert() {
      return this.enableAlertFilteringAlert && this.$store.state.user.profile.IsFilteringOn === false;
    },
    showSidebar() {
      return this.$route.name !== 'Home';
    },
  },
  methods: {
    closeFilteringAlert() {
      this.enableAlertFilteringAlert = false;
    },
  },
  mounted() {
    store.dispatch(USER_REQUEST);
    store.dispatch(PROVIDER_REQUEST);
  },
};
</script>

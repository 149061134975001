<template>
  <ais-instant-search
    :search-client="trustedContactsSearchClient"
    :index-name="indexName"
    class="trusted-contacts-container"
  >
    <ais-configure :facetFilters="['confirmed:true']" />
    <div class="search-wrapper row">
      <span class="contact-search-box p-input-icon-left p-float-label">
        <ais-search-box class="flex-grow" show-loading-indicator>
          <AisDebouncedSearchBox :delay="0" class="search-input w-full" placeholder="Search for a name or email" />
        </ais-search-box>
      </span>

      <AddContactButton />
    </div>

    <div class="row h-full main-content">
      <ais-state-results class="h-full w-full flex">
        <template v-slot="{ results: { hits: trustedContacts } }">
          <div class="trusted-contacts-table-container">
            <TrustedContactsTable :contacts="trustedContacts" />
          </div>
          <!-- <Panel class="help-panel" header="What are Trusted Contacts?"
        >Your Trusted Contacts are VIP contacts & organizations that you typically engage with, such as your colleagues,
        clients, partners, associates, and more. You can always add/remove a Trusted Contact.
        <b>Any incoming emails from Trusted Contacts are never subject to your Smart Filters selection.</b>
        <div class="text-right">Learn More</div></Panel
      > -->

          <ais-instant-search
            :search-client="suggestedContactsSearchClient"
            :index-name="indexName"
            class="suggested-contacts-list-container"
          >
            <ais-configure :facetFilters="['confirmed:false']" />
            <ais-state-results>
              <template v-slot="{ results: { hits: suggestedContacts } }">
                <SuggestedContactsList :contacts="suggestedContacts" />
              </template>
            </ais-state-results>
          </ais-instant-search>
        </template>
      </ais-state-results>
    </div>
  </ais-instant-search>
</template>

<script>
import TypesenseInstantsearchAdapter from 'typesense-instantsearch-adapter';
import { AisInstantSearch, AisStateResults, AisSearchBox, AisConfigure } from 'vue-instantsearch/vue3/es';
import TrustedContactsTable from '@/components/TrustedContacts/TrustedContactsTable';
import AisDebouncedSearchBox from '@/components/Ais/AisDebouncedSearchBox';
import SuggestedContactsList from '@/components/TrustedContacts/SuggestedContactsList';
import AddContactButton from '@/components/TrustedContacts/AddContactButton';

export default {
  name: 'TrustedContacts',
  components: {
    AisInstantSearch,
    AisStateResults,
    AisConfigure,
    AisSearchBox,
    AddContactButton,
    // ClickableIconWithBackground,
    // DataViewLayoutOptions,
    TrustedContactsTable,
    AisDebouncedSearchBox,
    SuggestedContactsList,
  },
  data() {
    return {
      trustedContacts: [],
      selectedTrustedContacts: [],
      suggestedContacts: [],
      visibleSuggestedContacts: [],
      selectedSuggestedContacts: [],
      trustedContactsSearchClient: null,
      suggestedContactsSearchClient: null,
      indexName: process.env.VUE_APP_SEARCH_COLLECTION_CONTACTS,
    };
  },

  watch: {
    suggestedContacts: function (curr) {
      this.visibleSuggestedContacts = curr.slice(0, 5);
    },
  },
  created() {
    this.trustedContactsSearchClient = this.getTypesenseAdapter(50).searchClient;
    this.suggestedContactsSearchClient = this.getTypesenseAdapter(6).searchClient;
  },

  methods: {
    getTypesenseAdapter(pageSize) {
      return new TypesenseInstantsearchAdapter({
        server: {
          apiKey: this.$store.state.user.attributes?.searchApiKey,
          nodes: [
            {
              host: process.env.VUE_APP_SEARCH_SERVER_HOST,
              port: process.env.VUE_APP_SEARCH_SERVER_PORT,
              protocol: process.env.VUE_APP_SEARCH_SERVER_PROTOCOL,
            },
          ],
        },
        additionalSearchParameters: {
          preset: 'contacts_default',
          per_page: pageSize,
        },
      });
    },

    async confirmAllSuggested() {
      const contacts = this.suggestedContacts.map((contact) => {
        return {
          ...contact,
          confirmed: true,
        };
      });

      const response = await this.axios.patch('/dashboard/v2/user-trusted-sources/multiple', JSON.stringify(contacts));
      this.trustedContacts = response.data;
    },

    async dismissAllSuggested() {
      const response = await this.axios.delete('/dashboard/v2/user-trusted-sources/multiple', {
        data: JSON.stringify(this.suggestedContacts),
      });
      this.trustedContacts = response.data;
    },

    async updateContactAction(contactId, action) {
      const response = await this.axios.patch(
        '/dashboard/v2/user-trusted-sources/' + contactId + '/action',
        JSON.stringify(action),
      );
      this.trustedContacts = response.data;
    },
  },
};
</script>

<style scoped>
.left {
  display: flex;
  align-items: center;
}

.upsell-banner {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.dataset-dropdown {
  margin-right: 1rem;
}

.info-icon {
  cursor: pointer;
  color: #6c757d;
  font-size: 0.85rem;
}

.row {
  display: flex;
  width: 100%;
}

.text-right {
  text-align: right;
}

.trusted-contacts-container {
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.trusted-contacts-container > div {
  padding-bottom: 1rem;
}

.trusted-contacts-table-container {
  width: 66%;
  height: 100%;
}

.action-list > .clickable-icon-with-background:not(:first-of-type) {
  margin-left: 1rem;
}

.search-wrapper {
  padding-top: 2rem;
  /* display: flex; */
  /* justify-content: space-between; */
  /* width: 75%; */
}

.contact-search-box {
  min-width: 25%;
  margin-right: 1rem;
}

.contact-search-box input {
  color: #0a7350;
}

.contact-search-box input:focus + i {
  color: #a7f3d0;
}

.contact-search-box input.p-filled + i {
  color: #0a7350;
}

.contact-search-box input.p-filled:not(:focus) {
  border-color: #909498;
}

.contact-search-box.p-float-label > input.p-filled + i + label,
.contact-search-box.p-float-label > input:focus + i + label {
  left: 0.75rem !important;
}

.table-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-thead > tr > th {
  padding: 0.5rem 1rem !important;
  text-wrap: nowrap;
}

.p-checkbox,
.p-checkbox-box,
.p-checkbox-box .p-component {
  width: 16px !important;
  height: 16px !important;
  border-radius: 4px !important;
}

.p-checkbox {
  margin-bottom: 1px !important;
}

.clickable-icon-with-background i {
  transition: all 0.1s ease;
  cursor: pointer;
}

.clickable-icon-with-background .background {
  color: transparent;
}

.clickable-icon-with-background:hover .background {
  color: #f0fdfa;
}

.clickable-icon-with-background:hover .fa-external-link-alt {
  color: #0a7350;
}

.p-selectable-row.p-highlight .clickable-icon-with-background:hover .background {
  color: #c3dfd8;
}

.vertical-divider {
  height: 1rem;
  border-left: 1px solid rgb(222, 226, 230);
}

.suggested-contacts-list-container {
  width: 33%;
  margin-left: 1rem;
}

.main-content {
  /* 5rem = size of header row */
  height: calc(100% - 5rem);
}
</style>

<template>
  <div
    class="sidebar-collapsible-container"
    :class="{ expanded: isExpanded }"
    :style="{ width: isExpanded ? '20rem' : '5rem' }"
  >
    <div>
      <a @click="$router.push('/')" class="brand"
        ><img :src="logo" alt="logo" /><span v-if="isExpanded">InMoat</span></a
      >

      <div class="divider-wrap">
        <div class="horizontal-divider"></div>
      </div>

      <div class="subapp-link-list">
        <!-- search -->
        <div
          @click="$router.push('/search')"
          class="subapp-link"
          :class="{ 'curr-page': currPageName === 'Search' }"
          title="Search"
        >
          <i class="fas fa-search"></i>
          <div class="subapp-name" v-if="isExpanded">Search</div>
        </div>

        <!-- smart filters -->
        <div
          @click="$router.push('/smart-filters')"
          class="subapp-link"
          :class="{ 'curr-page': currPageName === 'Smart Filters' }"
          title="Smart Filters"
        >
          <i class="fas fa-filter"></i>
          <div class="subapp-name" v-if="isExpanded">Smart Filters</div>
        </div>

        <!-- insights -->
        <div
          @click="$router.push('/insights')"
          class="subapp-link"
          :class="{ 'curr-page': currPageName === 'Insights' }"
          title="Insights"
        >
          <i class="fas fa-chart-line"></i>
          <div class="subapp-name" v-if="isExpanded">Insights</div>
        </div>

        <!-- trusted contacts -->
        <div
          @click="$router.push('/trusted-contacts')"
          class="subapp-link"
          :class="{ 'curr-page': currPageName === 'Trusted Contacts' }"
          title="Trusted Contacts"
        >
          <i class="fas fa-address-book"></i>
          <div class="subapp-name" v-if="isExpanded">Trusted Contacts</div>
        </div>
      </div>

      <div class="divider-wrap bottom-divider">
        <div class="horizontal-divider"></div>
      </div>
    </div>

    <div>
      <!-- help -->
      <div class="bottom-menu-container">
        <a href="https://www.inmoat.com/resources/knowledge-base" target="_blank" class="subapp-link" title="Help">
          <i class="fas fa-question"></i>
          <div class="subapp-name" v-if="isExpanded">Help</div>
        </a>
      </div>

      <!-- settings -->
      <div class="bottom-menu-container">
        <div class="subapp-link" @click="showAccountSettingsModal" title="Settings">
          <i class="fas fa-cog"></i>
          <div class="subapp-name" v-if="isExpanded">Settings</div>
        </div>
      </div>

      <!-- expander chevron -->
      <div class="bottom-menu-container">
        <div @click="isExpanded = !isExpanded" class="subapp-link expander">
          <i :class="{ 'fas fa-chevron-left': isExpanded, 'fas fa-chevron-right': !isExpanded }"></i>
          <div class="subapp-name" v-if="isExpanded">Collapse</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/img/logo_brand_white.svg';
import { useDialog } from 'primevue/usedialog';
import ModalAccountSettings from '@/components/Modals/ModalAccountSettings.vue';

export default {
  name: 'SidebarCollapsible',
  data() {
    return {
      logo,
      isExpanded: false,
    };
  },
  setup() {
    const dialog = useDialog();
    return { dialog };
  },
  computed: {
    currPageName() {
      return this.$router.currentRoute.value.name;
    },
  },
  methods: {
    showAccountSettingsModal: function () {
      this.dialog.open(ModalAccountSettings, {
        props: {
          dismissableMask: true,
          modal: true,
          draggable: false,
          header: 'Account Settings',
          style: {
            width: '25vw',
            // height: '70vh',
          },
          breakpoints: {
            '960px': '75vw',
            '640px': '90vw',
          },
        },
      });
    },
  },
};
</script>

<style scoped>
.divider-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bottom-divider {
  margin-top: 3rem;
}

.horizontal-divider {
  width: 50%;
  padding-top: 2px;
  /* border-top: 2px solid rgba(167, 243, 208); */
  border-top: 2px solid #f8f9fa;
  opacity: 0.2;
}

.sidebar-collapsible-container {
  background-color: #254275;
  height: 100vh;
  width: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.1s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px; */
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  z-index: 999;
  position: relative;
}

.brand {
  height: 5rem;
  margin-bottom: 2.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.subapp-link-list {
  margin-top: 3.375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brand > img {
  width: 3rem;
}

.brand > span {
  font-weight: bold;
  font-size: 2rem;
  color: #fff;
  margin-left: 5px;
}

.subapp-link {
  font-size: 1.5rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  margin-bottom: 1rem;
  border: 2px solid transparent;
  transition: border 0.15s ease-in-out, opacity 0.15s ease-in-out, color 0.15s ease-in-out;
}

.sidebar-collapsible-container:not(.expanded) .subapp-link:hover,
.sidebar-collapsible-container:not(.expanded) .subapp-link.curr-page {
  /* background-color: rgba(167, 243, 208); */
  border: 2px solid rgba(167, 243, 208);
}

.bottom-menu-container {
  display: flex;
  justify-content: center;
}

.subapp-name {
  white-space: nowrap;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.bottom-menu-container .subapp-link {
  margin-bottom: 0.5rem;
  width: 35px;
  height: 35px;
}

.bottom-menu-container i,
.bottom-menu-container .subapp-name {
  font-size: 1rem;
}

.sidebar-collapsible-container.expanded .subapp-link:hover,
.sidebar-collapsible-container.expanded .subapp-link.curr-page {
  color: rgba(167, 243, 208);
}

.sidebar-collapsible-container.expanded .subapp-link {
  transition-duration: 0s;
}

.sidebar-collapsible-container.expanded .subapp-link,
.sidebar-collapsible-container.expanded .brand {
  justify-content: normal;
  margin-left: 3rem;
  width: 100%;
}

.sidebar-collapsible-container.expanded .subapp-name {
  font-weight: bold;
}

.sidebar-collapsible-container.expanded .bottom-menu-container .subapp-link {
  margin-left: 2rem;
}
</style>

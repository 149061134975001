<template>
  <div class="relative flex flex-col w-full min-w-0 mb-6 break-words bg-white rounded shadow-lg">
    <div class="px-4 py-3 mb-0 bg-transparent rounded-t">
      <div class="flex flex-wrap items-center">
        <div class="relative flex-1 flex-grow w-full max-w-full">
          <h6 class="mb-1 text-xs font-semibold uppercase text-blueGray-400">Breakdown</h6>
          <h2 class="text-xl font-semibold text-blueGray-700">Incoming Emails</h2>
        </div>
      </div>
    </div>
    <div class="flex-auto p-4">
      <div class="relative">
        <canvas id="pie-chart"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js';

export default {
  data() {
    return {
      chart: null,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({
        InboxTotal: 0,
        InmoatTotal: 0,
        PriorityOneTotal: 0,
        TrustedSourceTotal: 0,
      }),
    },
  },
  computed: {
    trustedSourceTotal() {
      if (!this.data) {
        return 0;
      }

      return this.data.TrustedSourceTotal + this.data.PriorityOneTotal;
    },
    smartFilterTotal() {
      if (!this.data) {
        return 0;
      }

      return this.data.SmartFilterTotal;
    },
    inmoatTotal() {
      if (!this.data) {
        return 0;
      }

      return this.data.InmoatTotal;
    },
  },
  watch: {
    data() {
      this.refreshChartData();
    },
  },
  methods: {
    refreshChartData() {
      (this.chart.data = {
        labels: ['Trusted Sources', 'Smart Filters', 'Filtered to @InMoat'],
        datasets: [
          {
            backgroundColor: ['#5ACEA9', '#63B3ED', '#E1341D'],
            data: [this.trustedSourceTotal, this.smartFilterTotal, this.inmoatTotal],
          },
        ],
      }),
        this.chart.update();
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      let config = {
        type: 'pie',
        options: {
          cutoutPercentage: 50,
          legend: {
            display: true,
          },

          tooltips: {
            backgroundColor: '#f5f5f5',
            titleFontColor: '#333',
            bodyFontColor: '#666',
            bodySpacing: 4,
            xPadding: 12,
            mode: 'nearest',
            intersect: 0,
            position: 'nearest',
          },

          scales: {
            yAxes: [
              {
                display: 0,
                ticks: {
                  display: false,
                },
                gridLines: {
                  drawBorder: false,
                  zeroLineColor: 'transparent',
                  color: 'rgba(255,255,255,0.05)',
                },
              },
            ],

            xAxes: [
              {
                display: 0,
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(255,255,255,0.1)',
                  zeroLineColor: 'transparent',
                },
                ticks: {
                  display: false,
                },
              },
            ],
          },
        },
      };
      let ctx = document.getElementById('pie-chart').getContext('2d');
      this.chart = new Chart(ctx, config);
    });
  },
};
</script>

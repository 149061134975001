<template>
  <div>
    <div
      class="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
      v-show="modelValue"
    >
      <div class="relative min-h-[30vh] min-w-[30vw] max-h-[75vh] max-w-[75vw] mx-auto my-6">
        <!--content-->
        <div
          class="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none"
        >
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
            <h3 class="text-3xl font-semibold text-center">Choose a folder</h3>
            <button
              class="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
              @click="modalHide"
            >
              <span class="block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none">
                ×
              </span>
            </button>
          </div>

          <!--body-->
          <div class="relative flex-auto p-6">
            <div class="relative w-full mb-3">
              <input
                type="text"
                v-model="filterText"
                placeholder="Search folders"
                class="relative w-full px-3 py-1 mb-3 text-sm bg-white rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:shadow-outline"
              />
              <div class="overflow-y-scroll max-h-48">
                <div class="flex flex-row" v-for="folder in foldersFiltered" :key="'folder' + folder.userFolderId">
                  <label class="w-full h-full">
                    <input type="radio" v-model="selectedFolderId" :value="folder.userFolderId" class="sr-only peer" />
                    <span
                      class="inline-block w-full h-full p-2 peer-hover:bg-blueGray-100 peer-checked:bg-blueGray-200"
                    >
                      {{ folder.name }}
                    </span>
                  </label>
                </div>
                <!--                <div v-for="folder in foldersFiltered" :key="'folder' + folder.userFolderId">-->
                <!--                  <label>-->
                <!--                    {{ folder.name }}-->
                <!--                    <input-->
                <!--                      type="radio"-->
                <!--                      v-model="selectedFolderId"-->
                <!--                      :value="folder.userFolderId"-->
                <!--                      class="h-full w-full py-2 px-2 text-left hover:bg-blueGray-100 focus:bg-blueGray-100 focus:outline-none checked:bg-blueGray-200 checked:hover:bg-blueGray-300 checked:focus:bg-blueGray-300 checked:focus:outline-none"-->
                <!--                    />-->
                <!--                  </label>-->
                <!--                </div>-->
              </div>
            </div>
          </div>

          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
            <button
              class="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
              type="button"
              @click="modalDone"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="fixed inset-0 z-40 bg-black opacity-75" v-show="modelValue"></div>
  </div>
</template>

<script>
export default {
  name: 'ModalMoveToFolder',
  emits: ['update:modelValue', 'move-to-folder'],
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
      default: () => {
        return {
          id: null,
          name: null,
          folder: null,
        };
      },
    },
    folders: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      filterText: '',
      selectedFolderId: this.item.folder,
    };
  },
  watch: {
    item(newItem) {
      this.selectedFolderId = newItem.folder;
    },
  },
  computed: {
    foldersFiltered() {
      let allFolders = [
        {
          name: 'No folder',
          userFolderId: null,
        },
      ];

      return allFolders.concat(
        this.folders.filter((folder) => {
          return folder.name.toUpperCase().includes(this.filterText.toUpperCase()) || this.filterText === '';
        }),
      );
    },

    selectedFolder() {
      return this.folders.find((folder) => {
        return folder.userFolderId === this.selectedFolderId;
      });
    },
  },
  methods: {
    modalShow() {
      this.$emit('update:modelValue', true);
    },

    modalHide() {
      this.$emit('update:modelValue', false);
    },

    modalDone() {
      if (this.selectedFolderId) this.moveToSelectedFolder();
      else this.clearSelectedFolder();

      this.modalHide();
    },

    moveToSelectedFolder() {
      this.$emit('move-to-folder', this.selectedFolder);
    },

    clearSelectedFolder() {
      this.$emit('move-to-folder', null);
    },
  },
};
</script>

<style scoped></style>

//? The below rule was in original file, delete if unneeded
//? /* eslint-disable promise/param-names */
import { AUTH_REQUEST, AUTH_REFRESH, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT, FETCH_TOKEN } from '../actions/auth';

import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
});

const state = {
  token: localStorage.getItem('token') || '',
  status: '',
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  authStatus: (state) => state.status,
};

const actions = {
  [AUTH_REQUEST]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      // The Promise used for router redirect in login
      commit(AUTH_REQUEST);
      fetch(process.env.VUE_APP_API_URL + '/session-start?' + params.toString(), {
        params: params,
        redirect: 'follow',
      })
        .then((resp) => {
          if (resp.status === 303) {
            resp.text().then((text) => {
              // commit(AUTH_SUCCESS, resp);
              window.location.href = text;
            });
          }
        })
        .catch((err) => {
          commit(AUTH_ERROR, err);
          localStorage.removeItem('token'); // if the request fails, remove any possible user token if possible
          reject(err);
        });
    });
  },
  [AUTH_REFRESH]: ({ commit }) => {
    if (!localStorage.getItem('token')) {
      commit(AUTH_LOGOUT);
    }
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise((resolve) => {
      commit(AUTH_LOGOUT);
      localStorage.removeItem('token');
      resolve();
    });
  },
  [FETCH_TOKEN]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      // The Promise used for router redirect in login
      api
        .get('session-start?', { params: params })
        .then((resp) => {
          const token = resp.data;
          localStorage.setItem('token', token); // store the token in localstorage
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          sessionStorage.removeItem('id');
          commit(AUTH_SUCCESS, token);
          resolve(resp);
        })
        .catch((err) => {
          commit(AUTH_ERROR, err);
          localStorage.removeItem('token'); // if the request fails, remove any possible user token if possible
          reject(err);
        });
    });
  },
};

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [AUTH_SUCCESS]: (state, token) => {
    state.status = 'success';
    state.token = token;
  },
  [AUTH_ERROR]: (state) => {
    state.status = 'error';
  },
  [AUTH_LOGOUT]: (state) => {
    state.token = '';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="page-loading-container">
    <div class="icon">
      <!-- <img src="@/assets/img/inmoat.gif" class="mx-auto" alt="InMoat loading symbol" /> -->
      <i class="fas fa-spinner fa-spin" />
    </div>
    <div class="title">Please wait while we classify your emails</div>
    <div class="progress">{{ completed }} of {{ total }} emails classified</div>
  </div>
</template>

<script>
export default {
  name: 'InboxClassifyingMessage',
  components: {},
  computed: {
    status() {
      return this.$store.state.search.inboxClassifyStatus.status;
    },
    completed() {
      return this.$store.state.search.inboxClassifyStatus.completed;
    },
    total() {
      return this.$store.state.search.inboxClassifyStatus.total;
    },
  },
};
</script>

<style scope>
.page-loading-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-loading-container i {
  color: rgb(16, 185, 129);
  font-size: 8rem;
  margin-bottom: 2rem;
}

.page-loading-container .title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.page-loading-container .progress {
  font-size: 1.25rem;
}
</style>

<template>
  <div class="action-list-container">
    <span class="action-list">
      <!-- eslint-disable vue/no-use-v-if-with-v-for -->
      <ActionButton
        :createBatchTask="createBatchTask"
        class="action"
        v-bind:key="action.name"
        v-for="action in visibleActions"
        v-if="selectedCurrPageEmails.length"
        :action="action"
        :showDisplayName="false"
      />
    </span>
  </div>
</template>
<script>
import ActionButton from '@/components/Search/ActionButton.vue';
import { actionDisplayConfig } from '@/constants/Actions';
import { UPDATE_SELECTED_CURR_PAGE_EMAILS } from '@/store/actions/search';

export default {
  name: 'BatchActionList',
  components: { ActionButton },
  props: {
    createBatchTask: {
      type: Function,
      default: () => null,
    },
  },
  computed: {
    visibleActions() {
      return actionDisplayConfig.filter(({ visible }) => visible);
    },
    selectedCurrPageEmails: {
      get() {
        return this.$store.state.search.selectedCurrPageEmails;
      },
      set(value) {
        this.$store.commit(UPDATE_SELECTED_CURR_PAGE_EMAILS, value);
      },
    },
  },
};
</script>

<style scoped>
.action-list {
  display: flex;
  gap: 0.5rem;
}
</style>
